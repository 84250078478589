import {Entity} from '../../entity.interface';

export class Nouveautes implements Entity {
    idnouveautes: number;
    datecreation: Date;
    contenu: string;
    titre: string;
    espace: string;

    constructor({idnouveautes = null, datecreation = null, contenu = null, titre = null, espace = null} = {}) {
        this.idnouveautes = idnouveautes;
        this.datecreation = datecreation;
        this.contenu = contenu;
        this.titre = titre;
        this.espace = espace;
    }
}
