import * as IssueActions from './issue.action';

export function IssuesTotalReducer(state: number, action: IssueActions.Actions) {
    switch (action.type) {
        case IssueActions.GET_ISSUES_SUCCESS:
            return action.payload.total;
        default:
            return state !== undefined ? state : 0;
    }
}
