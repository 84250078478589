import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionService} from '../../services/local/session.service';

@Component({
    selector: 'cre-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

    @Output() change = new EventEmitter();
    @Input() checked: boolean;
    @Input() right: string[];
    disabled: boolean;

    constructor(private session: SessionService) {
    }

    ngOnInit() {
        let hasDroit = false;
        for (const droit of this.right) {
            if (this.session.user.idprofil.hasOwnProperty(droit) && this.session.user.idprofil[droit] === true) {
                hasDroit = true;
            }
        }
        if (!hasDroit) {
            this.disabled = true;
        }
    }

    emitCheck($event = null) {
        // if ($event !== null) {
        //     $event.preventDefault();
        // }
        this.change.emit($event);
    }
}
