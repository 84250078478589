import {Entity} from '../entity.interface';
import {Moment} from 'moment';
import {Entreprise} from '../entite/entreprise/entreprise.model';
import * as moment from 'moment';
import {Etape} from './etape.model';

export class Pipeline implements Entity {
    idpipeline: number;
    bdefaut: boolean;
    datecreation: Moment;
    identreprise: Entreprise;
    nom: string;
    bactif: boolean;
    etapes: Etape[];

    constructor({idpipeline = null, bdefaut = null, datecreation = null, identreprise = null, nom = null, bactif = null} = {}) {
        this.idpipeline = idpipeline;
        this.bdefaut = bdefaut;
        this.datecreation = datecreation !== null ? moment(new Date(datecreation)) : null;
        this.identreprise = identreprise !== null ? new Entreprise(identreprise) : new Entreprise();
        this.nom = nom;
        this.bactif = bactif;
        this.etapes = [];
    }
}
