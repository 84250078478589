export const shared = {
    firelog: {
        apiKey: "AIzaSyCNe-LjEFGE5glk1GQksebR3hu5In_SYyI",
        authDomain: "mymanci.firebaseapp.com",
        databaseURL: "https://mymanci.firebaseio.com",
        projectId: "mymanci",
        storageBucket: "mymanci.appspot.com",
        messagingSenderId: "43545571498",
        appId: "1:43545571498:web:c5781c4c0c6cd55078e0d3",
        measurementId: "G-0C9LY8WP8F"    },
    firenotif: {
        apiKey: "AIzaSyCNe-LjEFGE5glk1GQksebR3hu5In_SYyI",
        authDomain: "mymanci.firebaseapp.com",
        databaseURL: "https://mymanci.firebaseio.com",
        projectId: "mymanci",
        storageBucket: "mymanci.appspot.com",
        messagingSenderId: "43545571498",
        appId: "1:43545571498:web:c5781c4c0c6cd55078e0d3",
        measurementId: "G-0C9LY8WP8F"    },
    firemonitoring: {
        apiKey: 'AIzaSyCwtm6blTZPWCZ7w4lxRXVVBp9Ab7UNVyQ',
        authDomain: 'monitoring-stats.firebaseapp.com',
        databaseURL: 'https://monitoring-stats.firebaseio.com',
        projectId: 'monitoring-stats',
        storageBucket: 'monitoring-stats.appspot.com',
        messagingSenderId: '779694353919',
        appId: '1:779694353919:web:297d762d1c7be303b80bca'
    }
};
