import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modale-version',
  templateUrl: './modale-version.component.html',
  styleUrls: ['./modale-version.component.sass']
})
export class ModaleVersionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
