import {Entity} from '../../entity.interface';
import {Groupeagences} from '../groupeagences/groupeagences.model';
import {Agence} from '../agence/agence.model';

export class Agencegroupeagences implements Entity {
    idagencegroupeagences: number;
    idagence: Agence;
    idgroupeagences: Groupeagences;

    constructor({idagencegroupeagences = null, idagence = null, idgroupeagences = null}) {
        this.idagencegroupeagences = idagencegroupeagences;
        this.idagence = idagence !== null ? new Agence(idagence) : new Agence();
        this.idgroupeagences = idgroupeagences !== null ? new Groupeagences(idgroupeagences) : new Groupeagences();
    }
}
