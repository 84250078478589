import {Entity} from '../entity.interface';
import {Entreprise} from '../entite/entreprise/entreprise.model';
import {Pays} from '../public/pays/pays.model';
import * as moment from 'moment';
import {Client} from '../entite/client/client.model';

export class Contrat implements Entity {

    idcontrat: number;
    identreprise: Entreprise;
    nomcommercial: string;
    raisonsociale: string;
    type: string;
    capital: number;
    civiliterl: string;
    nomrl: string;
    prenomrl: string;
    fonctionrl: string;
    telephonerl: string;
    emailrl: string;
    adressess: string;
    codepostalss: string;
    villess: string;
    datedevis: moment.Moment;
    nblicence: string;
    prixunitaireht: number;
    typeabonnement: string;
    referencedevis: string;
    dureevalidite: number;
    referencemandat: string;
    rib: string;
    iban: string;
    bic: string;
    adressedifferente: boolean;
    adressemandat: string;
    codepostalmandat: string;
    villemandat: string;
    pathdevis: string;
    pathcontrat: string;
    pathmandat: string;
    pathfusion: string;
    idpaysss: Pays;
    idpaysmandat: Pays;
    siren: string;
    villercs: string;
    pathcontratsigne: string;
    idprocedure: string;
    engagement: string;
    dureeengagement: string;
    idclient: Client;
    designation: string;

    constructor({
                    idcontrat = null, identreprise = null, nomcommercial = null, raisonsociale = null, type = null,
                    capital = null, civiliterl = null, nomrl = null, prenomrl = null, fonctionrl = null,
                    telephonerl = null, emailrl = null, adressess = null, codepostalss = null, villess = null,
                    datedevis = null, nblicence = null, prixunitaireht = null, typeabonnement = null,
                    referencedevis = null, dureevalidite = null, referencemandat = null, rib = null, iban = null,
                    bic = null, adressedifferente = null, adressemandat = null, codepostalmandat = null,
                    villemandat = null, pathdevis = null, pathcontrat = null, pathmandat = null, pathfusion = null,
                    idpaysss = null, idpaysmandat = null, siren = null, villercs = null, pathcontratsigne = null,
                    idprocedure = null, engagement = null, dureeengagement = null, idclient = null , designation = null
                } = {}) {
        this.idcontrat = idcontrat;
        this.identreprise = identreprise !== null ? new Entreprise(identreprise) : new Entreprise();
        this.nomcommercial = nomcommercial;
        this.raisonsociale = raisonsociale;
        this.type = type;
        this.capital = capital;
        this.civiliterl = civiliterl;
        this.nomrl = nomrl;
        this.prenomrl = prenomrl;
        this.fonctionrl = fonctionrl;
        this.telephonerl = telephonerl;
        this.emailrl = emailrl;
        this.adressess = adressess;
        this.codepostalss = codepostalss;
        this.villess = villess;
        this.datedevis = datedevis !== null ? moment(new Date(datedevis)) : null;
        this.nblicence = nblicence;
        this.prixunitaireht = prixunitaireht;
        this.typeabonnement = typeabonnement;
        this.referencedevis = referencedevis;
        this.dureevalidite = dureevalidite;
        this.referencemandat = referencemandat;
        this.rib = rib;
        this.iban = iban;
        this.bic = bic;
        this.adressedifferente = adressedifferente;
        this.adressemandat = adressemandat;
        this.codepostalmandat = codepostalmandat;
        this.villemandat = villemandat;
        this.pathdevis = pathdevis;
        this.pathcontrat = pathcontrat;
        this.pathmandat = pathmandat;
        this.pathfusion = pathfusion;
        this.idpaysss = idpaysss !== null ? new Pays(idpaysss) : new Pays();
        this.idpaysmandat = idpaysmandat !== null ? new Pays(idpaysmandat) : new Pays();
        this.siren = siren;
        this.villercs = villercs;
        this.pathcontratsigne = pathcontratsigne;
        this.idprocedure = idprocedure;
        this.engagement = engagement;
        this.dureeengagement = dureeengagement;
        this.idclient = idclient !== null ? new Client(idclient) : new Client();
        this.designation = designation;
    }
}
