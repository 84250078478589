import {Entity} from './entity.interface';

export class Liste {
    liste: Entity[];
    total: number;

    constructor(liste: Entity[], total: number) {
        this.liste = liste;
        this.total = total;
    }

}
