export class Factureexpediteur {

    idfactureexpediteur: number;
    raisonsociale: string;
    adresse: string;
    complementadresse: string;
    codepostal: string;
    ville: string;
    pays: string;
    logo: string;
    mentionslegales: string;
    ribtitulaire: string;
    ribiban: string;
    ribbic: string;
    couleurfieldset: string;
    couleurfond: string;
    couleurtitletext: string;
    couleurtab: string;
    telephone: string;
    email: string;

    constructor({idfactureexpediteur = null, raisonsociale = null, adresse = null, complementadresse = null,
                    codepostal = null, ville = null, pays = null, logo = null, mentionslegales = null,
                    ribtitulaire = null, ribiban = null, ribbic = null, couleurfieldset = null, couleurfond = null,
                    couleurtitletext = null, couleurtab = null, telephone = null, email = null} = {}) {
        this.idfactureexpediteur = idfactureexpediteur;
        this.raisonsociale = raisonsociale;
        this.adresse = adresse;
        this.complementadresse = complementadresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.pays = pays;
        this.logo = logo;
        this.mentionslegales = mentionslegales;
        this.ribtitulaire = ribtitulaire;
        this.ribiban = ribiban;
        this.ribbic = ribbic;
        this.couleurfieldset = couleurfieldset;
        this.couleurfond = couleurfond;
        this.couleurtitletext = couleurtitletext;
        this.couleurtab = couleurtab;
        this.telephone = telephone;
        this.email = email;
    }
}

