import {Entity} from '../../entity.interface';

export class Option implements Entity {

    idoption: number;
    nom: string;
    idstat: number;

    constructor({idoption = null, nom = null, idstat = null} = {}) {
        this.idoption = idoption;
        this.nom = nom;
        this.idstat = idstat;
    }
}
