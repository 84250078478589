import {Directive, ElementRef, OnDestroy} from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
    selector: '[mask-percent]'
})
export class MaskPercentDirective implements OnDestroy {

    mask = [/[1-9]/, /[1-9]/, ',', /[1-9]/, /[1-9]/]; // 00,00
    maskedInputController;

    constructor(private element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.mask,
            guide: true
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

}
