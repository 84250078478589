export class Pagination {
    pageIndex: number;
    pageSize: number;
    pageSizeOptions: number[];

    constructor({pageIndex = 0, pageSize = 25, pageSizeOptions = [5, 10, 25, 100]} = {}) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.pageSizeOptions = pageSizeOptions;
    }
}
