import {Entity} from '../entity.interface';

export class Familleapporteur implements Entity {
    idfamilleapporteur: number;
    nomfamille: string;

    constructor({
                    idfamilleapporteur = null,
                    nomfamille = null,
                    famille = null,
                    bsupprimable = null,
                    bactive= null,
                    nbapporteur = null
                } = {}) {
        this.idfamilleapporteur = idfamilleapporteur;
        this.nomfamille = nomfamille;
    }
}
