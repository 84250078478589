import {Entity} from '../entity.interface';
import {Entreprise} from '../entite/entreprise/entreprise.model';

export class Repertoire implements Entity {
    idrepertoire: number;
    nom: string;
    etat: string;
    identreprise: Entreprise;
    nbdocspersomodele: number;

    constructor({
                    idrepertoire = null, nom = null, etat = null, identreprise = null, nbdocspersomodele = null
                } = {}) {
        this.idrepertoire = idrepertoire;
        this.nom = nom;
        this.etat = etat;
        this.identreprise = identreprise !== null ? new Entreprise(identreprise) : new Entreprise();
        this.nbdocspersomodele = nbdocspersomodele;
    }

}
