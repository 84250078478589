import {Entity} from '../../entity.interface';

export class Aide implements Entity {
    idaide: string;
    libelle: string;
    description: string;

    constructor({idaide = null, libelle = null, description = null} = {}) {
        this.idaide = idaide;
        this.libelle = libelle;
        this.description = description;
    }
}
