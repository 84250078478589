import {Entity} from '../../entity.interface';

export class Variantemot implements Entity {
    id: number;
    variante: string;

    constructor({id= null, variante = null} = {}) {
        this.id = id;
        this.variante = variante;
    }
}
