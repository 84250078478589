import {Dossier} from '../dossier/dossier/dossier.model';
import {Utilisateur} from '../utilisateur/utilisateur/utilisateur.model';
import {Droit} from './droit.model';
import {Entity} from '../entity.interface';

export class Partagedossier implements Entity {
    idpartagedossier: number;
    // iddossier: any;
    idcourtierproprietaire: Utilisateur;
    idcourtierdestinataire: Utilisateur;
    iddroit: any;
    bproprietaire: boolean;
    bperso: boolean;

    constructor({idpartagedossier = null, iddossier = {}, idcourtierproprietaire = {},
                    idcourtierdestinataire = {}, iddroit = {}, bproprietaire = null, bperso = null} = {}) {
        this.idpartagedossier = idpartagedossier;
        // this.iddossier = new Dossier(iddossier);
        this.idcourtierproprietaire = idcourtierproprietaire !== null ? new Utilisateur(idcourtierproprietaire) : new Utilisateur();
        this.idcourtierdestinataire = idcourtierdestinataire !== null ? new Utilisateur(idcourtierdestinataire) : new Utilisateur();
        this.iddroit = iddroit !== null ? new Droit(iddroit) : new Droit();
        this.bproprietaire = bproprietaire;
        this.bperso = bperso;
    }

}
