import {Entity} from '../../entity.interface';
import {Stat} from '../stat/stat.model';

export class Onglet implements Entity {

    idonglet: number;
    nom: string;
    alias: string;
    position: number;
    stats: Stat[];
    putPosition: string;
    bactif: boolean;
    banonymous: boolean;
    bworldwide: boolean;

    constructor({idonglet = null, nom = null, alias = null, position = null, putPosition = null, bactif = null,
                anonymous = false, worldwide = false} = {}) {
        this.idonglet = idonglet;
        this.nom = nom;
        this.alias = alias;
        this.position = position;
        this.putPosition = putPosition;
        this.stats = [];
        this.bactif = bactif;
        this.banonymous = anonymous;
        this.bworldwide = worldwide;
    }
}
