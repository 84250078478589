
import {Entity} from '../../entity.interface';
import {Agence} from '../../entite/agence/agence.model';

export class Utilisateuragence implements Entity {
    idutilisateuragence: number;
    bagenceprincipale: boolean;
    idagence: Agence;
    idutilisateur: any;

    constructor({idutilisateuragence = null, bagenceprincipale = null, idagence = {}, idutilisateur = null} = {}) {
        this.idutilisateuragence = idutilisateuragence;
        this.bagenceprincipale = bagenceprincipale;
        this.idutilisateur = idutilisateur;
        this.idagence = new Agence(idagence);
    }
}
