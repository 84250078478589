import { Injectable } from '@angular/core';
import {ResourceService} from '../modules/shared/config-module/services/rest/resource.service';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(private resource: ResourceService) { }

    get(id) {
        return this.resource.get(`attachments/${id}`);
    }
}
