import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Authtoken} from '../../entities/authtoken.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.state';
import * as AuthtokenActions from '../../entities/authtoken.action';
import {Utilisateur} from '../../entities/utilisateur/utilisateur/utilisateur.model';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private _authtoken$: Observable<Authtoken>;
    // tslint:disable-next-line:variable-name
    private _user: Utilisateur;
    // tslint:disable-next-line:variable-name
    private _justlogedin: boolean;

    constructor(private store: Store<AppState>) {
        this._authtoken$ = this.store.select('authtoken');
        this._authtoken$.subscribe((authtoken: Authtoken) => {
            this._user = authtoken !== undefined && authtoken.token !== null ? authtoken.idutilisateur : null;
        });
    }

    get justlogedin(): boolean {
        return this._justlogedin;
    }

    set justlogedin(value: boolean) {
        this._justlogedin = value;
    }

    get user(): Utilisateur {
        if (this._user === null) {
            return new Utilisateur();
        }
        return this._user;
    }

    get authtoken$(): Observable<Authtoken> {
        return this._authtoken$;
    }

    logout(message = null) {
        if (message !== null) {
            this.store.dispatch(new AuthtokenActions.KillTokenSession(message));
        } else {
            this.store.dispatch(new AuthtokenActions.KillTokenSession());
        }
    }

}
