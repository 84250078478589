import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'cre-modale-input',
    templateUrl: './modale-input.component.html',
    styleUrls: ['./modale-input.component.scss']
})
export class ModaleInputComponent implements OnInit {

    title: string;
    question: string;
    placeholder: string;
    value: string;

    constructor(public dialog: MatDialogRef<ModaleInputComponent>, @Inject(MAT_DIALOG_DATA) public data) {
        this.title = data.title;
        this.question = data.question;
        this.placeholder = data.placeholder;
        this.value = data.value;
    }

    ngOnInit() {
    }

    save() {
        this.dialog.close(this.value);
    }
}
