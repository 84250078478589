import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import * as AuthtokenActions from '../entities/authtoken.action';
import {Actions, ofType} from '@ngrx/effects';
import {LocalStorageService} from '../services/local/local-storage.service';
import {EnvService} from '../services/rest/env.service';
import {ResourceService} from '../services/rest/resource.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {SessionService} from '../services/local/session.service';

@Injectable()

export class RefreshResolver implements Resolve<boolean> {
    constructor(private store: Store<AppState>, private router: Router, private local: LocalStorageService, private session: SessionService,
                private env: EnvService, private resource: ResourceService, private actions: Actions) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
        const authtoken = this.local.getCEntity(`${this.env.getTypeUtilisateur()}_auth_token_${this.env.getTokenEnvironment()}`);
        return new Promise((resolve, reject) => {
            if (this.session.justlogedin) { // SI L'UTILISATEUR VIENT DE SE CONNECTER, PAS BESOIN DE TESTER
                this.session.justlogedin = false;
                resolve(true);
            } else if (authtoken) {
                if (route.url.length > 0 && route.url[0].path === 'connexion') {
                    // SI UN TOKEN EXISTE MAIS QUE LA ROUTE DE CONNEXION EST APPELÉE, ON REROUTE VERS L'APP.
                    this.router.navigate(['/']);
                    resolve(false);
                } else {
                    this.resource.setToken(authtoken.token); // SI UN TOKEN EXISTE ET QUE L'APP EST APPELÉE, ON TEST LA VALIDITÉ DU TOKEN
                    this.store.dispatch(new AuthtokenActions.RefreshTokenSession(authtoken));
                    const killsubscribe = this.actions.pipe(ofType(AuthtokenActions.REFRESH_TOKENSESSION_SUCCESS)).subscribe(() => { // SI LE TOKEN EST VALIDE
                        killsubscribe.unsubscribe();
                        resolve(true);
                    });
                }
            } else { // S'IL N'EXISTE PAS DE TOKEN, ON APPLIQUE LE COMPORTEMENT DE LA DECONNEXION SANS MESSAGE D'ERREURS, SAUF SI ON EST DEJA SUR LA PAGE DE CONNEXION
                if (route.url.length === 0) {
                    // this.session.logout('');
                    this.router.navigate(['connexion']);
                }
                resolve(false);
            }
        });
    }
}
