import { Injectable } from '@angular/core';
import {ResourceService} from '../modules/shared/config-module/services/rest/resource.service';

@Injectable({
    providedIn: 'root'
})
export class FiltreService {

    constructor(private resource: ResourceService) { }

    getCategories() {
        return this.resource.get('filters', 'categories');
    }

    getEntreprises() {
        return this.resource.get('filters', 'entreprises');
    }

    getAgences(entreprise) {
        return this.resource.getWithParams(entreprise, 'filters', 'agences');
    }

    getUtilisateurs(agence) {
        return this.resource.getWithParams(agence, 'filters', 'utilisateurs');
    }

    getStatus(category) {
        return this.resource.getWithParams(category, 'filters', 'status')
    }
}
