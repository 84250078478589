import {Entity} from '../../modules/shared/config-module/entities/entity.interface';
import {Category} from '../category/category.model';
import {Attachment} from '../attachment/attachment.model';
import {Commentaires} from '../commentaires/commentaires.model';
import {Pipelinestep} from '../pipeline/pipelinestep.model';
import {Customers} from '../customers/customers.model';

export class Issue implements Entity {
    id: number;
    title: string;
    text: string;
    createdat: Date;
    issueRefIndex: string;
    issueRef: any;
    summary: string;
    bclose: boolean;
    dateenattente: Date;

    // Utile pour la création
    useragent: string;
    category: Category;
    section: string;

    customers: Customers[];
    commentaires: Commentaires[];
    attachments: Attachment[];
    pipeline: Pipelinestep[];

    baccepteconnexionsupport: boolean;

    constructor({
                    id = null, title = null, text = null, createdat = null, issueRefIndex = null, issueRef = null,
                    useragent = null, category = null, attachments = [], customers = null, commentaires = [],
                    section = null, summary = null, pipeline = null, bclose = null, dateenattente = null, baccepteconnexionsupport = true
                } = {}) {
        this.id = id;
        this.title = title;
        this.text = text;
        this.createdat = createdat;
        this.issueRefIndex = issueRefIndex;
        this.useragent = useragent;
        this.issueRef = issueRef;
        this.category = (category === null) ? new Category() : new Category(category);
        this.section = section;
        this.summary = summary;
        this.bclose = bclose;
        this.dateenattente = dateenattente;

        this.attachments = attachments;
        this.customers = customers;
        this.commentaires = commentaires;
        this.pipeline = pipeline;
        this.baccepteconnexionsupport = baccepteconnexionsupport;
    }
}
