import {Ristournestatut} from '../ristournestatut/ristournestatut.model';
import {Ristournereglement} from '../ristournereglement/ristournereglement.model';
import {Dossier} from '../../dossier/dossier/dossier.model';
import * as moment from 'moment';
import {Moment} from 'moment';

export class Ristourne {

    idristourne: number;
    idristournestatut: Ristournestatut;
    montant: number;
    reference: string;
    dateenvoi: Moment;
    montanttva: number;
    reglements: Ristournereglement[];
    montantreglements: number;
    dossier: Dossier;

    constructor({idristourne = null, idristournestatut = null, montant = null, reference = null, dateenvoi = null, montanttva = null, montantreglements = null} = {}) {
        this.idristourne = idristourne;
        this.idristournestatut = idristournestatut === null ? new Ristournestatut() : new Ristournestatut(idristournestatut);
        this.montant = montant;
        this.reference = reference;
        this.dateenvoi = dateenvoi !== null ? moment(new Date(dateenvoi)) : null;
        this.montanttva = montanttva;
        this.reglements = [];
        this.montantreglements = montantreglements;
        this.reglements = [];
        this.dossier = null;
    }
}

