import {Component, OnDestroy, OnInit} from '@angular/core';
import {Issue} from '../../entities/issue/issue.model';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Actions, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import * as IssueActions from '../../entities/issue/issue.action';
import {LocalStorageService} from '../../modules/shared/config-module/services/local/local-storage.service';
import {debounceTime, share, startWith, tap} from 'rxjs/operators';
import {IssueService} from '../../services/issue.service';
import * as moment from 'moment';
import {FiltreService} from '../../services/filtre.service';
import {ModaleConfirmationComponent} from '../../modules/shared/config-module/components/modale-confirmation/modale-confirmation.component';
import {MatDialog} from '@angular/material';
import {Pagination} from '../../modules/shared/config-module/entities/pagination.model';

@Component({
    selector: 'cre-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnDestroy {

    filterTicketForm: FormGroup;
    optionsTickets: Issue[]; // Ticket[];

    issues$: Observable<Issue[]>;
    issuestotal$: Observable<number>;

    filterTicketForm2: any;
    pagination: Pagination;

    localFiltres;

    optionsEntreprises = [];
    optionsAgences = [];
    optionsUtilisateurs = [];
    optionsCategories = [];
    optionsStatus = [];

    optionsSort = [
        {
            label: 'Date de création croissante',
            value: {column: 'createdat', direction: 'ASC'}
        },
        {
            label: 'Date de création décroissante',
            value: {column: 'createdat', direction: 'DESC'}
        },
        {
            label: 'Numéro de ticket croissant',
            value: {column: 'id', direction: 'ASC'}
        },
        {
            label: 'Numéro de ticket décroissant',
            value: {column: 'id', direction: 'DESC'}
        },
        {
            label: 'Titre A -> Z',
            value: {column: 'title', direction: 'ASC'}
        },
        {
            label: 'Titre Z -> A',
            value: {column: 'title', direction: 'DESC'}
        }
    ];

    optionsPeriode = [
        'Toutes',
        'L\'année dernière',
        '12 derniers mois',
        '6 derniers mois',
        'Trimestre dernier',
        '30 derniers jours',
        'Mois dernier',
        'Semaine dernière',
        'Cette année',
        'Ce mois',
        'Ce trimestre',
        'De date à date'
    ];

    firstload = true;
    loaded = false;
    bafficherfiltressup = false;

    constructor(private router: Router, private store: Store<AppState>, private actions: Actions,
                private localStorage: LocalStorageService, public issue: IssueService, private route: ActivatedRoute,
                private filtres: FiltreService, private dialog: MatDialog) {
        this.filterTicketForm = new FormGroup({
            autocomplete: new FormControl('')
        });

        this.filterTicketForm2 = new FormGroup({
            category: new FormControl(''),
            statut: new FormControl(''),
            entreprise: new FormControl(''),
            agence: new FormControl(''),
            utilisateur: new FormControl(''),
            sort: new FormControl(''),
            periode: new FormControl(''),
            datemin: new FormControl(null),
            datemax: new FormControl(null),
            etat: new FormControl('')
        });

        this.issues$ = this.store.select('issues');
        this.issuestotal$ = this.store.select('issuestotal');

        this.localFiltres = this.localStorage.getLSItem('filtres');

        // Sauvegarde l'URL de provenance de l'utilisateur
        this.route.params.subscribe((params) => {
            const b64url: string = route.snapshot.params.url;

            if (b64url) {
                const url = JSON.stringify(atob(b64url));
                localStorage.setLSString('lasturl', url);
                router.navigate(['']);
            }
        });
    }

    ngOnInit() {
        this.loadFiltres();
        this.initAutocomplete();
        this.initLoadedWatcher();
        this.initWatcherPaginationErrors();
    }

    ngOnDestroy(): void {
        const filter = this.getFiltres();
        this.localStorage.setLSItem('filtres', filter);
    }

    changePage($event) {
        this.pagination.pageIndex = $event.pageIndex;
        this.pagination.pageSize = $event.pageSize;
        this.dispatchTicket();
    }

    /**
     * Charge les filtres la première fois
     */
    loadFiltres() {
        // Les filtres se chargent en cascades
        this.loadFiltreCategorie().subscribe();
        this.loadFiltreEntreprise().subscribe();

        // filtres locaux
        this.loadFiltreTri();
        this.loadFiltrePeriode();
        this.loadFiltreEtat();
        this.loadPagination();
    }

    loadFiltreCategorie(): Observable<any> {
        const categories$ = this.filtres.getCategories().pipe(share());

        categories$.subscribe((filtres: any) => {
            this.optionsCategories = filtres;

            let localfiltre = !this.localFiltres ? null : JSON.parse(this.localFiltres.category);

            if (!localfiltre) {
                this.filterTicketForm2.controls.category.patchValue([...filtres.map(category => category.id)]);
            } else {
                localfiltre = localfiltre.filter(category => filtres.map(category => category.id).indexOf(category) !== -1);
                this.filterTicketForm2.controls.category.patchValue(localfiltre);
            }
            this.loadFiltreStatut();
        });

        return categories$;
    }

    loadFiltreStatut(selectall = false): Observable<any> {
        const category = {
            category: JSON.stringify(this.filterTicketForm2.controls.category.value)
        };

        const statuts$ = this.filtres.getStatus(category).pipe(share());

        statuts$.subscribe((filtres: any) => {
            this.optionsStatus = filtres;

            let localfiltre = !this.localFiltres ? null : JSON.parse(this.localFiltres.statut);

            if (selectall || !localfiltre) {
                this.filterTicketForm2.controls.statut.patchValue([...filtres.map(statut => statut.title)]);
            } else {
                localfiltre = localfiltre.filter(statut => filtres.map(statut => statut.title).indexOf(statut) !== -1);
                this.filterTicketForm2.controls.statut.patchValue(localfiltre);
            }

            if (!this.firstload) {
                this.dispatchTicket();
            }
        });

        return statuts$;
    }

    loadFiltreEntreprise(): Observable<any> {
        const entreprises$ = this.filtres.getEntreprises().pipe(share());

        entreprises$.subscribe((filtres: any) => {
            this.optionsEntreprises = filtres;

            let localfiltre = !this.localFiltres ? null : JSON.parse(this.localFiltres.entreprise);

            if (!localfiltre) {
                this.filterTicketForm2.controls.entreprise.patchValue([...filtres.map(entreprise => entreprise.identreprise)]);
            } else {
                localfiltre = localfiltre.filter(entreprise => filtres.map(entreprise => entreprise.identreprise).indexOf(entreprise) !== -1);
                this.filterTicketForm2.controls.entreprise.patchValue(localfiltre);
            }
            this.loadFiltreAgence();
        });

        return entreprises$;
    }

    loadFiltreAgence(selectall = false): Observable<any> {
        const entreprise = {
            entreprise: JSON.stringify(this.filterTicketForm2.controls.entreprise.value)
        };

        const agences$ = this.filtres.getAgences(entreprise).pipe(share());

        agences$.subscribe((filtres: any) => {
            this.optionsAgences = filtres;

            let localfiltre = !this.localFiltres ? null : JSON.parse(this.localFiltres.agence);

            if (selectall || !localfiltre) {
                this.filterTicketForm2.controls.agence.patchValue([...filtres.map(agence => agence.idagence)]);
            } else {
                localfiltre = localfiltre.filter(agence => filtres.map(agence => agence.idagence).indexOf(agence) !== -1);
                this.filterTicketForm2.controls.agence.patchValue(localfiltre);
            }
            this.loadFiltreUtilisateur();
        });

        return agences$;
    }

    loadFiltreUtilisateur(selectall = false): Observable<any> {
        const agence = {
            agence: JSON.stringify(this.filterTicketForm2.controls.agence.value)
        };

        const utilisateurs$ = this.filtres.getUtilisateurs(agence).pipe(share());

        utilisateurs$.subscribe((filtres: any) => {
            this.optionsUtilisateurs = filtres;

            let localfiltre = !this.localFiltres ? null : JSON.parse(this.localFiltres.utilisateur);

            if (selectall || !localfiltre) {
                this.filterTicketForm2.controls.utilisateur.patchValue([...filtres.map(utilisateur => utilisateur.idutilisateur)]);
            } else {
                localfiltre = localfiltre.filter(utilisateur => filtres.map(utilisateur => utilisateur.idutilisateur).indexOf(utilisateur) !== -1);
                this.filterTicketForm2.controls.utilisateur.patchValue(localfiltre);
            }

            this.dispatchTicket();
        });

        return utilisateurs$;
    }

    loadFiltreTri() {
        if (!this.localFiltres) {
            // Valeur par défaut
            this.filterTicketForm2.controls.sort.patchValue(
                {
                    label: 'Date de création décroissante',
                    value: {column: 'createdat', direction: 'DESC'}
                }
            );
        } else {
            let localfiltre = JSON.parse(this.localFiltres.sort);

            // Si le tri sauvegardé est vide on le remets à la valeur par défaut
            if (localfiltre === '') {
                localfiltre = {
                    label: 'Date de création décroissante',
                    value: {column: 'createdat', direction: 'DESC'}
                };
            }

            this.filterTicketForm2.controls.sort.patchValue(localfiltre);
        }
    }

    loadFiltrePeriode() {
        this.initFiltrePeriode();

        if (!this.localFiltres) {
            // Valeur par défaut
            this.filterTicketForm2.controls.periode.patchValue('Toutes');
        } else {
            let periode = this.localFiltres.periode ? JSON.parse(this.localFiltres.periode) : 'Toutes';

            // Si le tri sauvegardé est vide on le remets à la valeur par défaut
            if (periode === '') {
                periode = 'Toutes';
            }

            this.filterTicketForm2.controls.periode.patchValue(periode);
        }
    }

    loadFiltreEtat() {
        if (!this.localFiltres) {
            // Valeur par défaut
            this.filterTicketForm2.controls.etat.patchValue(['Ouvert', 'Fermé']);
        } else {
            let etat = JSON.parse(this.localFiltres.etat);

            // Si le tri sauvegardé est vide on le remets à la valeur par défaut
            if (etat === '') {
                etat = ['Ouvert', 'Fermé'];
            }

            this.filterTicketForm2.controls.etat.patchValue(etat);
        }
    }

    loadPagination() {
        if (!this.localFiltres || (this.localFiltres && !this.localFiltres.pagination)) {
            // Valeur par défaut
            this.pagination = new Pagination({pageSize: 15, pageSizeOptions: [5, 10, 15, 25, 100]});
        } else {
            let pagination = JSON.parse(this.localFiltres.pagination);

            if (!pagination) {
                pagination = new Pagination({pageSize: 15, pageSizeOptions: [5, 10, 15, 25, 100]});
            }

            this.pagination = new Pagination(pagination);
        }
    }

    initFiltrePeriode() {
        this.filterTicketForm2.controls.periode.valueChanges.subscribe((periode) => {
            let datemin = new Date();
            let datemax = new Date();
            switch (periode) {
                case 'L\'année dernière':
                    datemin = new Date(new Date().getFullYear() - 1, 0, 1);
                    datemax = new Date(new Date().getFullYear() - 1, 11, 31);
                    break;
                case '12 derniers mois':
                    datemin.setDate(1);
                    datemin.setMonth(datemin.getMonth() - 12);

                    // datemax.setDate(0); // Mois précédent
                    break;
                case '6 derniers mois':
                    datemin.setDate(1);
                    datemin.setMonth(datemin.getMonth() - 6);

                    // datemax.setDate(0); // Mois précédent
                    break;
                case 'Trimestre dernier':
                    const ceTrimestreTemp = this.getQuarterStartAndEnd(new Date()).start;
                    ceTrimestreTemp.setDate(0);

                    const trimetreDernier = this.getQuarterStartAndEnd(ceTrimestreTemp);

                    datemin = trimetreDernier.start;
                    datemax = trimetreDernier.end;
                    break;
                case '30 derniers jours':
                    datemin.setDate(datemin.getDate() - 30);
                    break;
                case 'Mois dernier':
                    datemin.setDate(0); // Mois précédent
                    datemin.setDate(1); // 1er jour

                    datemax.setDate(0); // Mois précédent
                    break;
                case 'Semaine dernière':
                    datemax = this.getMondayOfCurrentWeek(datemax);
                    datemax.setDate(datemax.getDate() - 1);

                    datemin = this.getMondayOfCurrentWeek(datemax);
                    break;
                case 'Cette année':
                    datemin.setDate(1);
                    datemin.setMonth(0); // Janvier

                    // datemax.setMonth(11); // Décembre
                    // datemax.setDate(31); // 31
                    break;
                case 'Ce mois':
                    datemin.setDate(1); // 1er jour
                    break;
                case 'Ce trimestre':
                    const ceTrimestre = this.getQuarterStartAndEnd(new Date());

                    datemin = ceTrimestre.start;
                    datemax = ceTrimestre.end;
                    break;
                case 'De date à date':
                    // afficher les champs
                    datemin = null;
                    datemax = null;
                    break;
                default:
                    datemin = null;
                    datemax = null;
                    break;
            }

            this.filterTicketForm2.controls.datemin.patchValue(datemin);
            this.filterTicketForm2.controls.datemax.patchValue(datemax);

            // Dans le cas de date à date on veut des comportements spéciaux
            const bdateadate = this.estPeriodeDateDate(periode);

            if (this.firstload) {
                // chargemement des dates dans le cas de date à date
                if (bdateadate) {
                    this.filterTicketForm2.controls.datemin.patchValue(JSON.parse(this.localFiltres.datemin));
                    this.filterTicketForm2.controls.datemax.patchValue(JSON.parse(this.localFiltres.datemax));
                }
            } else if (!bdateadate) {
                this.dispatchTicket();
            }

            this.initFiltreDateDate();
        });
    }

    /**
     * Retourne le 1er jour de la semaine en param
     * @param d date
     */
    private getMondayOfCurrentWeek(d) {
        const day = d.getDay();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
    }

    /**
     * Retourne le début et la fin de trimetre de l'année en param
     * @param d date
     */
    private getQuarterStartAndEnd(d) {
        const quarter = Math.floor((d.getMonth() / 3));

        const startDate = new Date(d.getFullYear(), quarter * 3, 1);
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

        return {
            start: startDate,
            end: endDate
        };
    }

    initFiltreDateDate() {
        this.filterTicketForm2.controls.datemin.valueChanges.subscribe(_ => {
            this.checkDateDate();
        });
        this.filterTicketForm2.controls.datemax.valueChanges.subscribe(_ => {
            this.checkDateDate();
        });
    }

    /**
     * Appelé à chaque changement de datemin & datemax, permet de lancer le dispatch dans le cas de ce filtre
     */
    checkDateDate() {
        if (this.estPeriodeDateDate(this.filterTicketForm2.controls.periode.value)) {
            if (this.filterTicketForm2.controls.datemin.valid && this.filterTicketForm2.controls.datemax.valid) {
                // Vérification de la validité de longueur XX/XX/XXXX si c'est un string (entrée utilisateur)
                // Ou si il ne s'agit pas d'un string (donc objet)
                if ((this.filterTicketForm2.controls.datemax.value && (this.filterTicketForm2.controls.datemax.value._i.length === 10 ||
                    typeof(this.filterTicketForm2.controls.datemax.value._i) !== 'string'))
                    && (this.filterTicketForm2.controls.datemin.value && (this.filterTicketForm2.controls.datemin.value._i.length === 10 ||
                        typeof(this.filterTicketForm2.controls.datemin.value._i) !== 'string'))) {
                    const datemin = moment(this.filterTicketForm2.controls.datemin.value);
                    const datemax = moment(this.filterTicketForm2.controls.datemax.value);

                    if (datemin > datemax) {
                        const tempmax = this.filterTicketForm2.controls.datemax.value;
                        this.filterTicketForm2.controls.datemax.patchValue(this.filterTicketForm2.controls.datemin.value);
                        this.filterTicketForm2.controls.datemin.patchValue(tempmax);
                    }
                    this.dispatchTicket();
                }
            }
        }
    }

    estPeriodeDateDate(periode: string) {
        return periode === 'De date à date';
    }

    /**
     * Chargement de la liste des tickets
     */
    dispatchTicket() {
        /* Nous ne voulons pas envoyer entreprise & agence mais uniquement utilisateur */
        const filter = {
            pagination: this.getFiltres().pagination,
            autocomplete: this.getFiltres().autocomplete,
            utilisateur: this.getFiltres().utilisateur,
            category: this.getFiltres().category,
            statut: this.getFiltres().statut,
            sort: JSON.stringify(JSON.parse(this.getFiltres().sort).value),
            datemin: this.getFiltres().datemin,
            datemax: this.getFiltres().datemax,
            etat: this.getFiltres().etat
        };

        this.firstload = false;
        this.loaded = false;

        this.issue.loadIssuesNavigation(filter);

        this.store.dispatch(new IssueActions.GetIssues(filter));
    }

    /**
     * Determine si la fonction associée à l'event sera executé
     * @param $event
     * @param statut
     */
    checkFilterChange($event: boolean, statut: string) {
        const form = this.filterTicketForm2.controls[statut];

        /*
          * $event === false -> select fermé
          * &&
          * formcontrol est dirty (valeur changée)
         */
        const res = $event === false && form.dirty;

        // reset du form
        if (res) {
            form.markAsPristine();
        }

        return res;
    }

    /**
     * Clic sur une option de l'autocomplete
     */
    openTicketAuto() {
        const issue = this.filterTicketForm.value.autocomplete;
        this.openTicket(issue);
    }

    /**
     * Clic sur un ticket, navigation vers celui-ci
     * @param issue
     */
    openTicket(issue: Issue) {
        const id = this.getTicketRef(issue);

        this.router.navigate(['/t', id]);
    }

    displayTri(sort1, sort2) {
        return (sort1.value && sort2.value) && sort1.value.column === sort2.value.column && sort1.value.direction === sort2.value.direction;
    }

    /**
     * Retourne la reference du ticket
     */
    getTicketRef(issue: Issue) {
        return issue.issueRef ? `${issue.issueRef.id}_${issue.issueRefIndex}` : issue.id;
    }

    /**
     * Récupération des valeurs des filtres dans les forms
     */
    private getFiltres() {
        return {
            autocomplete: this.filterTicketForm.controls.autocomplete.value,
            entreprise: JSON.stringify(this.filterTicketForm2.controls.entreprise.value),
            agence: JSON.stringify(this.filterTicketForm2.controls.agence.value),
            utilisateur: JSON.stringify(this.filterTicketForm2.controls.utilisateur.value),
            category: JSON.stringify(this.filterTicketForm2.controls.category.value),
            statut: JSON.stringify(this.filterTicketForm2.controls.statut.value),
            sort: JSON.stringify(this.filterTicketForm2.controls['sort'].value),
            periode: JSON.stringify(this.filterTicketForm2.controls.periode.value),
            datemin: JSON.stringify(this.filterTicketForm2.controls.datemin.value),
            datemax: JSON.stringify(this.filterTicketForm2.controls.datemax.value === null ? null : moment(this.filterTicketForm2.controls.datemax.value).add(1, 'day').add(-1, 'second')), // On passe la seconde date à 23:59:59 car la comparaison se fait avec le temps
            etat: JSON.stringify(this.filterTicketForm2.controls.etat.value),
            pagination: JSON.stringify(this.pagination)
        };
    }

    private initAutocomplete() {
        this.filterTicketForm.controls.autocomplete.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            tap(input => {
                const filter = {
                    autocomplete: this.getFiltres().autocomplete,
                    utilisateur: this.getFiltres().utilisateur,
                    category: this.getFiltres().category,
                    statut: this.getFiltres().statut,
                    sort: JSON.stringify(JSON.parse(this.getFiltres().sort).value),
                    datemin: this.getFiltres().datemin,
                    datemax: this.getFiltres().datemax
                };
                if (typeof input === 'string' && input !== '') {
                    // @ts-ignore
                    this.issue.getTicketsListe(filter).subscribe((tickets: Issue[]) => {
                        this.optionsTickets = tickets as Issue[];
                    });
                } else {
                    this.optionsTickets = [];
                }
            })).subscribe();
    }

    /**
     * Permet au template de savoir si ça a chargé
     * Si ça a chargé & length < 1 on affiche le message
     */
    private initLoadedWatcher() {
        this.actions.pipe(ofType(IssueActions.GET_ISSUES_SUCCESS)).subscribe(() => {
            this.loaded = true;
        });
    }

     private initWatcherPaginationErrors(): void {
        this.issuestotal$.subscribe((total: number) => {
            if (total < this.pagination.pageSize && this.pagination.pageIndex > 0) {
                this.pagination.pageIndex = 0;
                this.dispatchTicket();
            }
        });
    }

    openDialogClose(issue) {
        const ref = this.dialog.open(ModaleConfirmationComponent, {
            width: '650px',
            data: {
                title: `Confirmation`,
                text: `Êtes-vous sûr(e) de vouloir fermer le ticket #${this.issue.getTicketRef(issue)} - "${issue.title}" ?`
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result === true) {
                // Fermeture du ticket
                this.closeTicket(issue);
            }
        });
    }

    closeTicket(issue) {
        this.issue.closeTicket(issue).subscribe(_ => {
            issue.bclose = true;
        });
    }

    updateFilters(bopen: boolean, category: string) {
        const etat = bopen ? 'Ouvert' : 'Fermé';
        this.filterTicketForm2.controls.category.patchValue(
            [this.optionsCategories.find(c => c.label === category).id]
        );
        this.filterTicketForm2.controls.etat.patchValue(
            [etat]
        );
        this.dispatchTicket();
    }
}
