import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CustomInterceptor} from './services/rest/custom.interceptor';
import {LoadingComponent} from './components/loading/loading.component';
import {CookieService} from 'ngx-cookie-service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {shared} from './enrivonment.shared';
import {ErrorTrackerComponent} from './components/error-tracker/error-tracker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as M from '@angular/material';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material';
import {AccountMenuComponent} from './components/account-menu/account-menu.component';
import localeFr from '@angular/common/locales/fr';
import {MaskDateDirective} from './directives/mask-date.directive';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MaskPercentDirective} from './directives/mask-percent.directive';
import {TextMaskModule} from 'angular2-text-mask';
import {TextMaskPipe} from './pipes/text-mask.pipe';
import {ColorPickerModule} from 'ngx-color-picker';
import {InputDatePickerComponent} from './components/input-date-picker/input-date-picker.component';
import {ModaleConfirmationComponent} from './components/modale-confirmation/modale-confirmation.component';
import {ModaleVersionComponent} from './components/modale-version/modale-version.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getFrenchPaginatorIntl} from './intl/french-paginator-intl';
import {UploadButtonComponent} from './components/upload-button/upload-button.component';
import {InputCpVilleComponent} from './components/input-cp-ville/input-cp-ville.component';
import {DroitDirective} from './directives/droit.directive';
import {RefreshResolver} from './resolvers/refresh.resolver';
import {VerifResolver} from './resolvers/verif.resolver';
import {ToggleComponent} from './components/toggle/toggle.component';
import {AdminResolver} from './resolvers/admin.resolver';
import {MatFormFieldRequiredDirective} from './directives/mat-form-field-required.directive';
import {VarDirective} from './directives/var.directive';
import {InputPaysComponent} from './components/input-pays/input-pays.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {InputMotDePasseComponent} from './components/input-mot-de-passe/input-mot-de-passe.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {NouveautesComponent} from './components/nouveautes/nouveautes.component';
import {ModaleNouveautesComponent} from './components/nouveautes/modale-nouveautes/modale-nouveautes.component';
import {SafeHtml} from './pipes/safe-html.pipe';
import {ModaleAvertissementDeploiementComponent} from './components/modale-avertissement-deploiement/modale-avertissement-deploiement.component';
import {ModaleComponent} from './components/modale/modale.component';
import { ModaleInputComponent } from './components/modale-input/modale-input.component';
import { SelectAllOptionComponent } from './components/select-all-option/select-all-option.component';
import { PasteUploadComponent } from './components/paste-upload/paste-upload.component';
import { ModaleConfirmationPasteComponent } from './components/paste-upload/modale-confirmation-paste/modale-confirmation-paste.component';

registerLocaleData(localeFr, 'fr');

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    declarations: [LoadingComponent,
        ErrorTrackerComponent,
        AccountMenuComponent,
        MaskDateDirective,
        MaskPercentDirective,
        TextMaskPipe,
        InputDatePickerComponent,
        ModaleConfirmationComponent,
        ModaleVersionComponent,
        UploadButtonComponent,
        InputCpVilleComponent,
        DroitDirective,
        ToggleComponent,
        MatFormFieldRequiredDirective,
        VarDirective,
        InputPaysComponent,
        InputMotDePasseComponent,
        NouveautesComponent,
        ModaleNouveautesComponent,
        ModaleAvertissementDeploiementComponent,
        SafeHtml,
        ModaleComponent,
        ModaleInputComponent,
        SelectAllOptionComponent,
        PasteUploadComponent,
        ModaleConfirmationPasteComponent
    ],
    imports: [
        /* MODULE MODULE */
        CommonModule,
        /* LAYOUT MODULE */
        FlexLayoutModule,
        // /* FORM INPUT EXTENSION MODULE*/
        TextMaskModule,
        /* ERROR LOG MODULES */
        // FIREBASE : https://github.com/angular/angularfire2/blob/master/docs/install-and-setup.md
        AngularFireModule.initializeApp(shared.firelog),
        AngularFireDatabaseModule,
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        // AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        // AngularFireStorageModule, // imports firebase/storage only needed for storage features
        /* FORM MODULES */
        ReactiveFormsModule,
        FormsModule,
        /* MATERIAL MODULES */
        M.MatSelectModule,
        M.MatFormFieldModule,
        M.MatDatepickerModule,
        M.MatCardModule,
        M.MatDialogModule,
        M.MatInputModule,
        M.MatRadioModule,
        M.MatButtonModule,
        M.MatIconModule,
        M.MatSidenavModule,
        M.MatToolbarModule,
        M.MatListModule,
        M.MatCheckboxModule,
        M.MatChipsModule,
        M.MatExpansionModule,
        M.MatTooltipModule,
        M.MatTabsModule,
        M.MatSnackBarModule,
        M.MatPaginatorModule,
        M.MatTableModule,
        M.MatMenuModule,
        M.MatSortModule,
        M.MatAutocompleteModule,
        M.MatNativeDateModule,
        M.MatGridListModule,
        M.MatSlideToggleModule,
        DragDropModule,
        MatRippleModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true},
        {provide: M.MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 10000}},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()},
        CookieService,
        RefreshResolver,
        VerifResolver,
        AdminResolver
    ],
    exports: [
        /* COMPONENT EXPORT */
        LoadingComponent,
        ErrorTrackerComponent,
        AccountMenuComponent,
        MaskDateDirective,
        TextMaskPipe,
        SafeHtml,
        InputDatePickerComponent,
        ModaleConfirmationComponent,
        ModaleVersionComponent,
        ModaleAvertissementDeploiementComponent,
        UploadButtonComponent,
        /* LAYOUT MODULE */
        FlexLayoutModule,
        /* FORM MODULES */
        ReactiveFormsModule,
        FormsModule,
        // /* FORM INPUT EXTENSION MODULE*/
        TextMaskModule,
        ColorPickerModule,
        /* MATERIAL MODULES */
        M.MatSelectModule,
        M.MatFormFieldModule,
        M.MatDatepickerModule,
        M.MatNativeDateModule,
        M.MatCardModule,
        M.MatDialogModule,
        M.MatInputModule,
        M.MatRadioModule,
        M.MatButtonModule,
        M.MatIconModule,
        M.MatSidenavModule,
        M.MatToolbarModule,
        M.MatListModule,
        M.MatCheckboxModule,
        M.MatChipsModule,
        M.MatExpansionModule,
        M.MatTooltipModule,
        M.MatTabsModule,
        M.MatSnackBarModule,
        M.MatPaginatorModule,
        M.MatTableModule,
        M.MatMenuModule,
        M.MatSortModule,
        M.MatAutocompleteModule,
        M.MatGridListModule,
        M.MatSlideToggleModule,
        InputCpVilleComponent,
        DroitDirective,
        ToggleComponent,
        MatFormFieldRequiredDirective,
        VarDirective,
        InputPaysComponent,
        DragDropModule,
        InputMotDePasseComponent,
        NouveautesComponent,
        ModaleNouveautesComponent,
        SelectAllOptionComponent,
        PasteUploadComponent
    ],
    entryComponents: [ModaleConfirmationComponent, ModaleVersionComponent, ModaleNouveautesComponent,
        ModaleAvertissementDeploiementComponent, ModaleComponent, ModaleConfirmationPasteComponent]
})
export class ConfigModule {
}
