import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LocalStorageService} from '../../modules/shared/config-module/services/local/local-storage.service';
import {EnvService} from '../../modules/shared/config-module/services/rest/env.service';
import {SessionService} from '../../modules/shared/config-module/services/local/session.service';
import {ViewmodeService} from '../../modules/shared/config-module/services/utility/viewmode.service';
import {DomSanitizer} from '@angular/platform-browser';
import {VersionService} from '../../services/version.service';
import {NavigationStart, Router} from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import {SidenavService} from '../../services/sidenav.service';
import {MatSidenav} from '@angular/material';

@Component({
    selector: 'cre-espace-support',
    templateUrl: './espace-support.component.html',
    styleUrls: ['./espace-support.component.scss']
})
export class EspaceSupportComponent implements OnInit, AfterViewInit {
    mobileQuery: MediaQueryList;
    isExpanded: boolean;
    nomapp: string;
    logo: string;
    ispremista: boolean;
    icon: string;

    @ViewChild('nav', {static: false}) public nav: MatSidenav;

    constructor(private local: LocalStorageService, private session: SessionService, public version: VersionService,
                private router: Router, private env: EnvService, media: MediaMatcher, public sidenav: SidenavService) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');

        // Si on est sur mobile on veut que lorsque on change d'URL la saidenav se ferme
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && this.mobileQuery.matches && this.sidenav.isOpen) {
                this.sidenav.toggle();
            }
        });
    }

    ngOnInit() {
        this.isExpanded = this.local.existItem('menu') ? this.local.getLSItem('menu') : true;
        this.ispremista = false;
        if (this.env.getDomain() === 'ymanci.fr') {
            this.nomapp = 'Créditéo';
            this.logo = '/assets/images/logo.png';
            this.icon = '/assets/images/icon.png';
        } else if (this.env.getDomain() === 'premista.tech') {
            this.nomapp = 'MyManci';
            this.logo = '/assets/images/logoP.png';
            this.icon = '/assets/images/iconP.png';
            this.ispremista = true;
        } else {
            this.nomapp = null;
        }
    }

    ngAfterViewInit() {
        this.sidenav.nav = this.nav;
    }

    toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.local.setLSString('menu', `${this.isExpanded}`);
    }

    logout() {
        this.session.logout();
    }


    goTo(destination: string) {
        this.router.navigate([destination]);
    }

    openFAQ() {
        window.open('https://faq.ymanci.fr/', '_blank');
    }
}
