import {Component, OnInit} from '@angular/core';
import {Issue} from '../../../entities/issue/issue.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {Actions, ofType} from '@ngrx/effects';
import * as IssueActions from '../../../entities/issue/issue.action';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackbarService} from '../../../modules/shared/config-module/services/utility/snackbar.service';
import {ResourceService} from '../../../modules/shared/config-module/services/rest/resource.service';
import {DownloadService} from '../../../modules/shared/config-module/services/utility/download.service';
import {Commentaires} from '../../../entities/commentaires/commentaires.model';
import {SessionService} from '../../../modules/shared/config-module/services/local/session.service';
import {IssueService} from '../../../services/issue.service';
import {TRASH_ACTION} from '../../../modules/shared/config-module/entities/trash.action';
import {CommentairesService} from '../../../services/commentaires.service';
import {AttachmentService} from '../../../services/attachment.service';
import {MatDialog} from '@angular/material';
import {ModaleAvertissementDeploiementComponent} from '../../../modules/shared/config-module/components/modale-avertissement-deploiement/modale-avertissement-deploiement.component';
import {ModaleConfirmationComponent} from '../../../modules/shared/config-module/components/modale-confirmation/modale-confirmation.component';
import {EnvService} from "../../../modules/shared/config-module/services/rest/env.service";

@Component({
    selector: 'cre-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

    issue = new Issue();
    loading = true;

    files = []; // Tableau de fichiers commentaire
    commentGroup: FormGroup;

    constructor(private route: ActivatedRoute, private store: Store<AppState>, private actions: Actions,
                private snack: SnackbarService, private commentaires: CommentairesService, private download: DownloadService,
                private session: SessionService, public issues: IssueService, private router: Router, private attachments: AttachmentService,
                private dialog: MatDialog, public env: EnvService) {
        this.commentGroup = new FormGroup({
            comment: new FormControl('', Validators.required)
        });

        // Reuse strategy fait qu'il ne repasse pas par le constructor donc on subscribe au changement de params
        this.route.params.subscribe((params) => {
            const id: number = route.snapshot.params.id;
            this.loadTicket(id);
        });
    }

    ngOnInit() {
        if (!this.issues.issuesNavigationLoaded) {
            this.issues.loadIssuesNavigation();
        }
    }

    /**
     * Retourne la reference du ticket
     */
    getTicketRef(issue) {
        return issue.issueRef ? `${issue.issueRef.id}_${issue.issueRefIndex}` : issue.id;
    }

    /**
     * Charge un ticket depuis un id
     * @param id
     */
    private loadTicket(id: number) {
        this.store.dispatch(new IssueActions.GetIssue(new Issue({id})));

        const killsubscribe = this.actions.pipe(ofType(IssueActions.GET_ISSUE_SUCCESS, TRASH_ACTION)).subscribe((issue: any) => {
            killsubscribe.unsubscribe();
            this.loading = false;

            // Erreur dans la réponse, on redirige à l'accueil
            if (issue.type === TRASH_ACTION) {
                this.router.navigate(['']);
                return;
            }

            this.issue = {...issue.payload};
            this.initVerifs();
        });
    }

    /**
     * Retourne x modulo n positif
     */
    modPositif(x, n) {
        const m = (( x % n) + n) % n;
        return m < 0 ? m + Math.abs(n) : m;
    }

    /**
     * Ticket précédent
     *  (<)  #XXXX  >
     */
    public previousTicket() {
        const index = this.modPositif(this.issues.issuesNavigation.indexOf(this.getTicketRef(this.issue)) - 1, this.issues.issuesNavigation.length);
        this.router.navigate(['/t', this.issues.issuesNavigation[index]]);
    }

    /**
     * Ticket suivant
     *  <   #XXXX (>)
     */
    public nextTicket() {
        const index = this.modPositif(this.issues.issuesNavigation.indexOf(this.getTicketRef(this.issue)) + 1, this.issues.issuesNavigation.length);
        this.router.navigate(['/t', this.issues.issuesNavigation[index]]);
    }

    /**
     * Vérifications pour affichages conditionnel
     */
    private initVerifs() {
        // Si le ticket est fermé, l'utilisateur ne peux plus poster de commentaires
        if (this.isIssueClosed()) {
            this.commentGroup.disable();
        } else {
            this.commentGroup.enable();
        }
    }

    /**
     * Envoi du commentaire
     */
    sendComment() {
        if (this.commentGroup.invalid) {
            this.snack.open('Veuillez remplir les champs obligatoires.');
            return;
        }

        const _commentaire = {
            idissue: this.issue.id,
            text: this.commentGroup.controls.comment.value,
            files: this.files
        };

        // Envoi du commentaire au backend
        this.commentaires.post(_commentaire).subscribe((commentaire: any) => {
            this.issue.commentaires.push(commentaire);
            // Commentaire posté, actualisation et réinitialisation des champs
            this.files = [];
            this.commentGroup.controls.comment.setValue('');
            this.commentGroup.controls.comment.reset();

            /*
             * Si le ticket était en attente de réponse client on mets à nul dateenattente, ce qui va faire
             * disparaître le texte en rouge disant qu'une réponse client est attente (sinon il se fait au refresh)
             */
            if (this.issue.dateenattente) {
                this.issue.dateenattente = null;
            }
        });

    }

    /**
     * Determine le type de commentaire
     * @param commentaire: Commentaires
     * @return 'auto' | 'support' | 'client'
     */
    determineTypeCommentaire(commentaire: Commentaires) {
        if (!commentaire.author) {
            if (commentaire.bauto) {
                return 'auto';
            } else {
                return 'support';
            }
        } else {
            return 'client';
        }
    }

    /**
     * Retourne le nom de l'auteur du commentaire pour l'affichage
     * @param commentaire
     */
    determineAuteurCommentaire(commentaire: Commentaires): string {
        switch (this.determineTypeCommentaire(commentaire)) {
            case 'auto':
                return 'Commentaire généré automatiquement';
            case 'support':
                return 'L\'équipe support ' + this.env.getNomApp();
            case 'client':
                return commentaire.author;
        }
    }

    /**
     * Retourne true si il s'agit de la personne connectée
     * @param commentaire
     */
    determineAuteurConnecteTicket(commentaire: Commentaires): boolean {
        return this.determineAuteurCommentaire(commentaire) === `${this.session.user.idcontact.prenom} ${this.session.user.idcontact.nom}`;
    }

    /**
     * Ajout d'un fichier
     * @param changeEvent
     * @param question
     */
    async onFileChange(changeEvent) {
        for (let i = 0; i < changeEvent.target.files.length; i++) {
            const data = await this.readFile(changeEvent.target.files[i]);
            this.files.push({
                lastModified: changeEvent.target.files[i].lastModified,
                lastModifiedDate: changeEvent.target.files[i].lastModifiedDate,
                name: changeEvent.target.files[i].name,
                size: changeEvent.target.files[i].size,
                type: changeEvent.target.files[i].type,
                data
            });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = (event: any) => {
                resolve(event.target.result);
            };
        });
    }

    removeFile(fileToRemove, files) {
        // On ne retourne rien car on passe par les références
        files = files.filter(file => file !== fileToRemove);
    }

    /**
     * Ouverture d'un Attachment
     * @param _fichier
     */
    getFile(_fichier: any) {
        this.attachments.get(_fichier.id).subscribe((fichier: any) => {
            this.download.openTabDataURI(fichier.data);
        });
    }

    isIssueClosed() {
        return this.issue.bclose === true;
    }

    openDialogClose() {
        const ref = this.dialog.open(ModaleConfirmationComponent, {
            width: '650px',
            data: {
                title: `Confirmation`,
                text: `Êtes-vous sûr(e) de vouloir fermer le ticket #${this.issues.getTicketRef(this.issue)} - "${this.issue.title}" ?`
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result === true) {
                // Fermeture du ticket
                this.closeTicket();
            }
        });
    }

    closeTicket() {
        this.issues.closeTicket(this.issue).subscribe(_ => {
            this.issue.bclose = true;

            // Il faut recharger les commentaires pour que l'utilisateur voit le ticket de cloture
            this.loadTicket(this.issue.id);
        });
    }
}
