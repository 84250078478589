import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModaleConfirmationComponent} from '../modale-confirmation/modale-confirmation.component';

@Component({
  selector: 'cre-modale',
  templateUrl: './modale.component.html',
  styleUrls: ['./modale.component.scss']
})
export class ModaleComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ModaleConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: {title, text}) { }

  ngOnInit() {
  }

}
