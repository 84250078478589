import {Component} from '@angular/core';
import {AppState} from './app.state';
import {Store} from '@ngrx/store';
import {EnvService} from './modules/shared/config-module/services/rest/env.service';
import {ResourceService} from './modules/shared/config-module/services/rest/resource.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'support';

    constructor(public env: EnvService, private store: Store<AppState>, private resource: ResourceService) {
        this.resource.initCallRoot('support');
        this.resource.pingVersion();
    }
}
