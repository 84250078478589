interface Timestamp {
    seconds: number;
    nanoseconds: number;
}

export class Error {
    message: string;
    location: string;
    status: string;
    useragent: string;
    user: string;
    date: Date;

    constructor({message = null, location = null, status = null, useragent = null, date = null, user = null} = {}) {
        this.message = message;
        this.location = location;
        this.status = status;
        this.useragent = useragent;
        this.user = user;
        this.date = new Date(1970,0,1);
        this.date.setSeconds(date.seconds);
    }
}
