import {Entity} from '../../entity.interface';
import {Onglet} from '../onglet/onglet.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DataStat} from './datastat.interface';
import {Option} from '../option/option.model';

export class Stat implements Entity {

    idstat: number;
    nom: string;
    nomaffichage: string;
    type: string;
    data: [DataStat]; // Pas en BDD
    largeur: number;
    hauteur: number;
    refresh: number;
    sql: string;
    periode: string;
    pas: string;
    position: number;
    idonglet: Onglet;
    datedeb: Moment;
    datefin: Moment;
    options: Option[];

    constructor({
                    idonglet = {}, idstat = null, nom = null, nomaffichage = null, type = null,
                    data = null, largeur = null, hauteur = null, refresh = null, sql = null, periode = null, pas = null,
                    position = null, datedeb = null, datefin = null
                } = {}) {
        this.idstat = idstat;
        this.idonglet = idonglet !== null ? new Onglet(idonglet) : null;
        this.nom = nom;
        this.nomaffichage = nomaffichage;
        this.type = type;
        this.largeur = largeur;
        this.hauteur = hauteur;
        this.refresh = refresh;
        this.sql = sql;
        this.periode = periode;
        this.pas = pas;
        this.position = position;
        this.data = [data];
        this.options = [];
        this.datedeb = datedeb !== null ? moment(new Date(datedeb)) : null;
        this.datefin = datefin !== null ? moment(new Date(datefin)) : null;
    }
}
