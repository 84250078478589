import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import * as AuthtokenActions from './authtoken.action';
import * as TrashActions from './trash.action';
import {EntityService} from '../services/rest/entity.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../services/local/local-storage.service';
import {EnvService} from '../services/rest/env.service';
import {MatSnackBar} from '@angular/material';
import {SessionService} from '../services/local/session.service';
import {Authtoken} from './authtoken.model';
import {ResourceService} from '../services/rest/resource.service';
import {of} from 'rxjs';

@Injectable()
export class AuthtokenEffect {
    loginAuthtoken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.LOGIN_TOKENSESSION),
        switchMap((action: AuthtokenActions.LoginTokenSession) => this.entity.postEntity(action.payload, 'authtoken', 'login')
            .pipe(map(token => ({
                type: AuthtokenActions.LOGIN_TOKENSESSION_SUCCESS,
                payload: new Authtoken(token)
            })), catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    loginAuthtokenSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.LOGIN_TOKENSESSION_SUCCESS),
        tap((action: AuthtokenActions.LoginTokenSessionSuccess) => {
            this.session.justlogedin = true;
            this.local.setCItem(`${this.env.getTypeUtilisateur()}_auth_token_${this.env.getTokenEnvironment()}`, action.payload.token);
            this.snack.open('Bonjour ' + action.payload.idutilisateur.idcontact.prenom + ' !');
            this.router.navigate(['/']); // REDIRECTION VERS L'APP
        })), {dispatch: false});

    refreshAuthtoken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.REFRESH_TOKENSESSION),
        switchMap((action: AuthtokenActions.RefreshTokenSession) => this.entity.getEntity('authtoken', 'getinfo')
            .pipe(map(token => ({type: AuthtokenActions.REFRESH_TOKENSESSION_SUCCESS, payload: token})),
                catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    refreshAuthtokenSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.REFRESH_TOKENSESSION_SUCCESS),
        tap((action: AuthtokenActions.RefreshTokenSessionSuccess) => {
            const randInt = Math.floor(Math.random() * (3));
            let message = '';
            switch (randInt) {
                case 0:
                    message = 'Vous revoilà ' + action.payload.idutilisateur.idcontact.prenom + ' !';
                    break;
                case 1:
                    message = 'Enfin de retour ' + action.payload.idutilisateur.idcontact.prenom + ' !';
                    break;
                case 2:
                    message = 'Merci d\'être revenu ' + action.payload.idutilisateur.idcontact.prenom + ' !';
                    break;
            }
            this.snack.open(message);
            // AUCUNE REDIRECTION AU REFRESH DU TOKEN
        })), {dispatch: false});

    killAuthtoken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.KILL_TOKENSESSION),
        switchMap((action: AuthtokenActions.KillTokenSession) => this.resource.get('authtoken', 'revoque').pipe(map(() => ({
            type: AuthtokenActions.KILL_TOKENSESSION_SUCCESS,
            payload: action.payload
        })), catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    killAuthTokenSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthtokenActions.KILL_TOKENSESSION_SUCCESS),
        tap((action: AuthtokenActions.KillTokenSessionSuccess) => {
            this.local.deleteCItem(`${this.env.getTypeUtilisateur()}_auth_token_${this.env.getTokenEnvironment()}`);
            this.local.clearStorage();
            if (action.payload !== '') {
                this.snack.open(action.payload);
            }
            this.router.navigate(['connexion']); // REDIRECTION VERS LA PAGE DE CONNEXION
        })), {dispatch: false});

    constructor(private actions$: Actions, private session: SessionService, private entity: EntityService, private local: LocalStorageService,
                private router: Router, private current: ActivatedRoute, private env: EnvService, private snack: MatSnackBar,
                private resource: ResourceService) {
    }
}
