import {Injectable} from '@angular/core';
import {shared} from '../../enrivonment.shared';
import * as firebase from 'firebase';
import {AngularFireDatabase} from '@angular/fire/database';
import {EnvService} from './env.service';
import {BehaviorSubject, interval, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeploiementService {
    private dateNow: Date;
    private dateMin: Date;

    private dateMax: Date;
    private clock: Subscription;

    // tslint:disable-next-line:variable-name
    public notif: { date: number, delais: number, from: string, texte: string };

    public firenotif: any;
    public majNotif: BehaviorSubject<any>;
    afficherModale: boolean;

    constructor(private db: AngularFireDatabase, private envService: EnvService) {
        const firebaseNotif = firebase.initializeApp(shared.firenotif, 'notif');
        const ref = firebaseNotif.database().ref('/notification');
        this.firenotif = this.db.object(ref);
        this.afficherModale = true;
        this.initDeploiement();
    }

    private setClock(inter: number) {
        this.clock = interval(inter).subscribe(_ => {
            this.dateNow = new Date();
            if (this.dateMin.getTime() < this.dateNow.getTime() && this.dateNow.getTime() < this.dateMax.getTime()) {
                this.majNotif.next(true);
                this.killClock();
            } else if (this.dateMax.getTime() < this.dateNow.getTime()) {
                this.killClock();
                this.notif = null;
            }
        });
    }

    private killClock() {
        this.majNotif.next(false);
        if (this.clock) {
            this.clock.unsubscribe();
        }
    }

    public initDeploiement() {
        this.majNotif = new BehaviorSubject<boolean>(false);
        this.majNotif.next(false);
        this.firenotif.valueChanges().subscribe((opt: any) => {
            this.killClock();
            this.dateMax = new Date(opt.date);
            this.dateMin = new Date(opt.date);
            this.dateMin.setMinutes(this.dateMax.getMinutes() - opt.delais);
            this.notif = {date: opt.date, delais: opt.delais, from: opt.from, texte: opt.texte};
            this.setClock(1000);
        });
    }
}
