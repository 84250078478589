import {Entity} from '../entity.interface';
import {Client} from '../entite/client/client.model';
import {Classeapporteur} from './classeapporteur.model';

export class Groupeapporteur implements Entity {
    idgroupeapporteur: number;
    nom: string;
    idclient: Client;
    nbapporteur: number;
    bactive: boolean;
    idclasseapporteur: Classeapporteur;

    constructor({
        idgroupeapporteur = null,
        idclient = null,
		bactive = null,
        nbapporteur = null,
        nom = null,
        idclasseapporteur = null,
    } = {}) {
        this.idgroupeapporteur = idgroupeapporteur;
        this.idclient = (idclient === null) ? new Client() : new Client(idclient);
        this.nbapporteur = nbapporteur;
        this.bactive = bactive;
        this.nom = nom;
        this.idclasseapporteur = (idclasseapporteur === null) ? new Classeapporteur() : new Classeapporteur(idclasseapporteur);
    }
}
