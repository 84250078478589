import {Ristourne} from '../ristourne/ristourne.model';
import * as moment from 'moment';
import {Moment} from 'moment';

export class Ristournereglement {

    idristournereglement: number;
    idristourne: Ristourne;
    datereglele: Moment;
    montantregle: number;

    constructor({idristournereglement = null, idristourne = null, datereglele = null, montantregle = null} = {}) {
        this.idristournereglement = idristournereglement;
        this.idristourne = idristourne;
        this.datereglele = datereglele !== null ? moment(new Date(datereglele)) : null;
        this.montantregle = montantregle;
    }
}
