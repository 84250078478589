import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ResourceService} from './resource.service';
import {tap} from 'rxjs/operators';
import {Agence} from '../../entities/entite/agence/agence.model';
import {Entreprise} from '../../entities/entite/entreprise/entreprise.model';
import {Directionregionale} from '../../entities/entite/directionregionale/directionregionale.model';
import {Groupeagences} from '../../entities/entite/groupeagences/groupeagences.model';
import {Apporteuraffaire} from '../../entities/apporteur/apporteuraffaire.model';
import {Pays} from '../../entities/public/pays/pays.model';
import {Poleimmobilier} from '../../entities/banque/poleimmobilier/poleimmobilier.model';
import {Module} from '../../entities/modules/module/module.model';
import {Utilisateur} from '../../entities/utilisateur/utilisateur/utilisateur.model';
import {Profil} from '../../entities/utilisateur/profil/profil.model';
import {Filter} from '../../entities/filter.interface';
import {Repertoire} from '../../entities/document/repertoire.model';
import {Villeinsee} from '../../entities/public/villeinsee/villeinsee.model';
import {Client} from '../../entities/entite/client/client.model';
import {Verifications} from '../../entities/pipeline/verifications.model';
import {Dossier} from '../../entities/dossier/dossier/dossier.model';
import {Typeapporteur} from '../../entities/apporteur/typeapporteur.model';
import {Classeapporteur} from '../../entities/apporteur/classeapporteur.model';
import {Familleapporteur} from '../../entities/apporteur/familleapporteur.model';
import {Aide} from '../../entities/utilisateur/utilisateur/aide.model';
import {Motcle} from '../../entities/releve/motcle/motcle.model';

@Injectable({
    providedIn: 'root'
})
export class GetListeService {

    // tslint:disable-next-line:variable-name
    private _pays: Pays[];

    // tslint:disable-next-line:variable-name
    private _verifications: Verifications[];

    constructor(public resource: ResourceService) {
        this._pays = [];
        this._verifications = [];
    }

    getagences(filter: Filter, urlComponent2: string): Observable<Agence[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'agences', urlComponent2).pipe(tap((result) => result as Agence[])) as Observable<Agence[]>;
    }

    getpoleimmobiliers(filter: Filter): Observable<Poleimmobilier[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'poleimmobiliers', 'autocomplete').pipe(tap((result) => result as Poleimmobilier[])) as Observable<Poleimmobilier[]>;
    }

    getpoleimmobiliersentreprise(entreprise: Entreprise): Observable<Poleimmobilier[]> {
        return this.resource.get('polesimmobiliers', entreprise.identreprise.toString()).pipe(tap((result) => result as Poleimmobilier[])) as Observable<Poleimmobilier[]>;
    }

    getentreprises(filter: Filter, urlComponent2: string): Observable<Entreprise[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'entreprises', urlComponent2).pipe(tap((result) => result as Entreprise[])) as Observable<Entreprise[]>;
    }

    getclients(filter: Filter, urlComponent2: string): Observable<Client[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'clients', urlComponent2).pipe(tap((result) => result as Client[])) as Observable<Client[]>;
    }

    getutilisateurs(filter: Filter, urlComponent2: string): Observable<Utilisateur[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'utilisateurs', urlComponent2).pipe(tap((result) => result as Utilisateur[])) as Observable<Utilisateur[]>;
    }

    getprofils(filter: Filter, urlComponent2: string): Observable<Profil[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'profils', urlComponent2).pipe(tap((result) => result as Profil[])) as Observable<Profil[]>;
    }

    getdirectionregionales(filter: Filter, urlComponent2: string): Observable<Directionregionale[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'directionregionales', urlComponent2).pipe(tap((result) => result as Directionregionale[])) as Observable<Directionregionale[]>;
    }

    getgroupeagences(filter: Filter, urlComponent2: string): Observable<Groupeagences[]> {
        return this.resource.post({
            ...filter,
            noloading: true,
            sort: {active: 'nom', direction: 'ASC'}
        }, 'groupeagences', urlComponent2).pipe(tap((result) => result as Groupeagences[])) as Observable<Groupeagences[]>;
    }

    getpays(): Pays[] {
        // console.log(this._pays);
        if (this._pays.length === 0) {
            this.resource.get('pays').subscribe((pays: Pays[]) => {
                this._pays = pays;
            });
        }
        return this._pays;
    }

    getverifications(): Verifications[] {
        if (this._verifications.length === 0) {
            this.resource.get('verifications').subscribe((verifications: Verifications[]) => {
                this._verifications = verifications;
            });
        }
        return this._verifications;
    }

    getvilleinsee(filter: Filter): Observable<Villeinsee[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'villesinsee').pipe(tap((result) => result as Villeinsee[])) as Observable<Villeinsee[]>;
    }

    getapporteuraffaires(filter: Filter): Observable<Apporteuraffaire[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'apporteuraffaires', 'autocomplete').pipe(tap((result) => result as Apporteuraffaire[])) as Observable<Apporteuraffaire[]>;
    }

    getmodules(filter: Filter): Observable<Module[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'modules').pipe(tap((result) => result as Module[])) as Observable<Module[]>;
    }

    getrepertoiresbyentreprise(entreprise: Entreprise): Observable<Repertoire[]> {
        return this.resource.get('repertoires', entreprise.identreprise.toString()).pipe(tap((result) => result as Repertoire[])) as Observable<Repertoire[]>;
    }

    getrepertoiresadmin(): Observable<Repertoire[]> {
        return this.resource.get('repertoires').pipe(tap((result) => result as Repertoire[])) as Observable<Repertoire[]>;
    }

    getaides(filter: Filter, urlComponent2: string): Observable<Aide[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'aides', urlComponent2).pipe(tap((result) => result as Aide[])) as Observable<Aide[]>;
    }

    getmotscles(filter: Filter, urlComponent2: string): Observable<Motcle[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'motscles', urlComponent2).pipe(tap((result) => result as Motcle[])) as Observable<Motcle[]>;
    }

    getdossiers(filter: Filter, urlComponent2 = 'getlist') {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'dossiers', urlComponent2).pipe(tap((result) => result as Dossier[])) as Observable<Dossier[]>;
    }

    gettypesapporteurs(filter: Filter, urlComponent2: string): Observable<Typeapporteur[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'typeapporteur', urlComponent2).pipe(tap((result) => result as Typeapporteur[])) as Observable<Typeapporteur[]>;
    }

    getclassesapporteurs(filter: Filter): Observable<Classeapporteur[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'classeapporteur', 'getlist').pipe(tap((result) => result as Classeapporteur[])) as Observable<Classeapporteur[]>;
    }

    getfamillessapporteurs(filter: Filter): Observable<Familleapporteur[]> {
        return this.resource.post({
            ...filter,
            noloading: true
        }, 'familleapporteur', 'getlist').pipe(tap((result) => result as Familleapporteur[])) as Observable<Familleapporteur[]>;
    }
}
