import {Pipe, PipeTransform} from '@angular/core';
import {conformToMask, TextMaskConfig} from 'angular2-text-mask';

@Pipe({
    name: 'textMask'
})
export class TextMaskPipe implements PipeTransform {

    transform(value: string | number, maskconfig: TextMaskConfig): any {
        if (value === null || value === undefined) {
            value = '';
        }
        value = '' + value;
        return value.length <= 0 ? '' : conformToMask(
            value,
            maskconfig.mask,
            {guide: false}
        ).conformedValue;
    }
}
