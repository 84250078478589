import {Entity} from '../../modules/shared/config-module/entities/entity.interface';

export class Pipelinestep implements Entity {
    title: string;
    date: Date;
    color: string;
    borderColor: string;
    final: boolean;
    truck: boolean;
    trucktype: TruckType;
    checkmark: boolean;
    description: string;
    current: boolean;

    constructor({title = null, date = null, color = null, borderColor = null, final = null, truck = null, trucktype = null, checkmark = null, description = null, current = null} = {}) {
        this.title = title;
        this.date = date;
        this.color = color;
        this.borderColor = borderColor;
        this.final = final;
        this.truck = truck;
        this.checkmark = checkmark;
        this.description = description;
        this.current = current;
    }
}

export enum TruckType {
    INACTIVE,
    ACTIVE,
    DELAYED,
    UNDETERMINED
}
