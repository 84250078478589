import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {EnvService} from './env.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {LoadingService} from '../utility/loading.service';
import {LocalStorageService} from '../local/local-storage.service';
import {KillTokenSession} from '../../entities/authtoken.action';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.state';
import {SnackbarService} from '../utility/snackbar.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    constructor(private snack: SnackbarService, private cookies: CookieService, private router: Router,
                private loading: LoadingService, private env: EnvService, private local: LocalStorageService,
                private store: Store<AppState>) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const showloading = this.doIShowLoading(request);
        if (showloading) {
            this.loading.show();
            this.loading.loadingstack += 1;
        }


        return next.handle(request).pipe(
            map((event: HttpResponse<any>) => {
                let potentialBodyModifiedResponse = null;
                if (event.hasOwnProperty('body') && event.body !== null) {
                    if (event.body.hasOwnProperty('success') && event.body.hasOwnProperty('content')) {
                        this.snack.openSuccess(event.body.success);
                        potentialBodyModifiedResponse = new HttpResponse({...event, body: event.body.content});
                    }
                }
                return potentialBodyModifiedResponse === null ? event : potentialBodyModifiedResponse;
            }),
            finalize(() => {
                if (showloading) {
                    this.loading.loadingstack -= 1;
                    if (this.loading.loadingstack <= 0) {
                        this.loading.hide();
                        this.loading.loadingstack = 0;
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.loading.loadingstack = 0;
                this.loading.hide();
                let message = 'Une erreur s\'est produite. Veuillez contacter l\'administrateur.';
                if (error.status === 401) {
                    this.store.dispatch(new KillTokenSession('Vous avez été déconnecté(e).'));
                } else {
                    if (error.hasOwnProperty('error') && error.error !== null && error.error.hasOwnProperty('missive')) {
                        message = error.error.missive;
                    }
                    this.snack.openError(message);
                }
                return throwError(error);
            }));
    }

    doIShowLoading(request: HttpRequest<any>): boolean {
        return !(
            (request !== null && request.hasOwnProperty('body') && request.body !== null && request.body.hasOwnProperty('noloading'))
            || (request !== null && request.hasOwnProperty('params') && request.params.has('noloading'))
            || (request !== null && request.hasOwnProperty('url') && request.url.includes('/rgpd/'))
            || (request !== null && request.hasOwnProperty('url') && request.url.includes('/import'))
            || (request !== null && request.hasOwnProperty('url') && request.url.includes('/appliquerdocpersomodeles'))
        );
    }
}
