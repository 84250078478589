import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'cre-upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {

    @Input() passControl: AbstractControl;

    @Input() btntext: string;
    @Input() icon: string;
    @Input() type: string;
    @Input() preview: boolean;
    @Input() previewHeight: string;
    @Input() previewWidth: string;
    @Input() editable: boolean;
    @Input() btnname: string;
    @Input() rawOutput = false;
    @Input() disabled = false;

    file: any = {data: null};

    constructor() {
    }

    ngOnInit() {
        this.passControl.valueChanges.subscribe((data) => {
            if (data === null) {
                this.file = {data: null};
            } else {
                this.file = data;
            }
            if (this.editable === true) {
                this.btntext = 'Modifier';
            }
        });
    }

    async onFileChange(changeEvent) {
        if (this.rawOutput) {
            this.passControl.setValue(changeEvent);
        }

        for (const file of changeEvent.target.files) {
            this.file = {data: null};
            const data = await this.readFile(file);
            this.file = {
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                data,
                btnname: this.btnname
            };
        }

        this.passControl.setValue(this.file);
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = (event: any) => {
                resolve(event.target.result);
            };
        });
    }

    clearFiles() {
        this.file = {data: null};
        this.passControl.setValue(null);
    }
}
