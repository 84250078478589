import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {MaskService} from '../../services/utility/mask.service';
import {GetListeService} from '../../services/rest/get-liste.service';
import {startWith, tap} from 'rxjs/operators';
import {Villeinsee} from '../../entities/public/villeinsee/villeinsee.model';
import {Pays} from '../../entities/public/pays/pays.model';
import {Pagination} from '../../entities/pagination.model';

@Component({
    selector: 'cre-input-cp-ville',
    templateUrl: './input-cp-ville.component.html',
    styleUrls: ['./input-cp-ville.component.scss']
})
export class InputCpVilleComponent implements OnInit {

    @Input() passControlCP: AbstractControl;
    @Input() passControlVille: AbstractControl;
    @Input() passControlPays: AbstractControl;
    @Input() cp: boolean;
    @Input() ville: boolean;

    titreControl: string;
    showControl: AbstractControl;
    timer: any;
    options: any;

    constructor(private mask: MaskService, private liste: GetListeService) {
    }

    ngOnInit() {
        if (this.cp !== undefined) {
            this.cp = true;
            this.titreControl = 'Code postal';
            this.showControl = this.passControlCP;
        } else if (this.ville !== undefined) {
            this.ville = true;
            this.titreControl = 'Ville';
            this.showControl = this.passControlVille;
        }

        this.initAutocomplete();
    }

    initAutocomplete() {
        this.showControl.valueChanges.pipe(
            startWith(''),
            tap(input => {
                const filter = {
                    filter: {autocomplete: input},
                    pagination: new Pagination({pageIndex: 0, pageSize: 100}),
                    sort: null
                };
                if (typeof input === 'string' && input !== '') {
                    if (this.timer !== undefined) {
                        clearTimeout(this.timer);
                        delete this.timer;
                    }
                    this.timer = setTimeout(() => {
                        this.liste.getvilleinsee(filter).subscribe(villesinsee => {
                            this.options = villesinsee as Villeinsee[];
                        });
                    }, 250);
                } else {
                    clearTimeout(this.timer);
                    delete this.timer;
                    this.options = [];
                }
            })).subscribe();
    }

    selectVilleInsee(form) {
        const villeinsee = form.value as Villeinsee;
        this.passControlCP.setValue(villeinsee.codepostal);
        this.passControlVille.setValue(villeinsee.nom);

        if (this.passControlPays) {
            const france = new Pays({
                alpha2: 'FR',
                alpha3: 'FRA',
                code: 250,
                idpays: 75,
                nom_en_gb: 'France',
                nom_fr_fr: 'France'
            });

            this.passControlPays.setValue(france);
        }
    }
}
