import * as AuthtokenActions from './authtoken.action';
import {Authtoken} from './authtoken.model';

export function AuthtokenReducer(state: Authtoken, action: AuthtokenActions.Actions) {
    switch (action.type) {
        case AuthtokenActions.LOGIN_TOKENSESSION_SUCCESS:
            return action.payload;
        case AuthtokenActions.REFRESH_TOKENSESSION_SUCCESS:
            return action.payload;
        case AuthtokenActions.KILL_TOKENSESSION_SUCCESS:
            return new Authtoken();
        default:
            return state !== undefined ? state : new Authtoken();
    }
}
