import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Entity} from '../../entities/entity.interface';
import {AppState} from '../../../../../app.state';
import {Store} from '@ngrx/store';
import {Liste} from '../../entities/liste.model';
import {EnvService} from './env.service';
import {ModaleVersionComponent} from '../../components/modale-version/modale-version.component';
import {VersionService} from '../../../../../services/version.service';
import {MatDialog, MatDialogConfig} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private headers: HttpHeaders;
    private callRoot: string;

    constructor(private http: HttpClient, private cookies: CookieService, private store: Store<AppState>,
                private env: EnvService, private version: VersionService, private dialog: MatDialog) {
        this.store.select('authtoken').subscribe((authtoken) => {
            if (authtoken !== undefined && authtoken.token !== null) {
                this.headers = (new HttpHeaders()).set('X-CREDITEO-AUTH-TOKEN', authtoken.token);
            } else {
                this.headers = new HttpHeaders();
            }
        });
    }

    public initCallRoot(espace: string) {
        if (this.env.getNomApp() === 'MyManci' && espace === 'controle'){
            espace = 'ecran';
        }
        this.callRoot = this.env.getCompleteUrl(espace) + '/rest';
    }

    public setToken(token: string) {
        this.headers = (new HttpHeaders()).set('X-CREDITEO-AUTH-TOKEN', token);
    }

    public get(...urlComponents: string[]): Observable<Entity[]> {
        return this.http.get<Entity[]>(`${this.callRoot}/${urlComponents.join('/')}`, {headers: this.headers});
    }

    public getOne(...urlComponents: string[]): Observable<Entity> {
        return this.http.get<Entity>(`${this.callRoot}/${urlComponents.join('/')}`, {headers: this.headers});
    }

    public getByFiltres(obj: object = {}, ...urlComponents: string[]): Observable<Liste> {
        return this.http.post<Liste>(`${this.callRoot}/${urlComponents.join('/')}`, obj, {headers: this.headers});
    }

    public getWithParams(params: any, ...urlComponents: string[]): Observable<Liste> {
        return this.http.get<Liste>(`${this.callRoot}/${urlComponents.join('/')}`, {headers: this.headers, params});
    }

    public post(obj: object = {}, ...urlComponents: string[]): Observable<Entity> {
        return this.http.post<Entity>(`${this.callRoot}/${urlComponents.join('/')}`, obj, {headers: this.headers});
    }

    public put(obj: object = {}, ...urlComponents: string[]): Observable<Entity> {
        return this.http.put<Entity>(`${this.callRoot}/${urlComponents.join('/')}`, obj, {headers: this.headers});
    }

    public delete(...urlComponents: string[]): Observable<object> {
        return this.http.delete(`${this.callRoot}/${urlComponents.join('/')}`, {headers: this.headers});
    }

    public getURL(url): Observable<Entity[]> {
        return this.http.get<Entity[]>(url, {headers: this.headers});
    }

    pingVersion() {
        this.post({version_code: this.version.current}, 'ping', 'version').subscribe((response: any) => {
            if (!response.hasOwnProperty('version_ok')) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;

                const dialogRef = this.dialog.open(ModaleVersionComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        location.reload();
                    }
                });
            }
        });
    }
}
