import {Pays} from '../../public/pays/pays.model';
import {Nationalite} from '../../public/nationalite/nationalite.model';

export class Emprunteurinfos {
    idemprunteurinfos: number;
    civilite: string;
    situationfamiliale: string;
    nom: string;
    nomjeunefille: string;
    prenom: string;
    email: string;
    adresse: string;
    complementadresse: string;
    codepostal: string;
    ville: string;
    idpays: Pays;
    datenaissance: Date;
    telfixe: string;
    telport: string;
    datecreation: Date;
    lienparente: string;
    villenaissance: string;
    nationalite: string;
    datemariage: Date;
    contratmariage: boolean;
    regimematrimonial: string;
    situationimmo: string;
    role: string;
    nbparts: number;
    connaitcreditimmo: boolean;
    connaitautrecredit: boolean;
    connaittaeg: boolean;
    connaitdifferencetaux: boolean;
    connaitassurance: boolean;
    niveauconnaissance: string;
    telbureau: string;
    datesituationimmo: Date;
    datedivorce: Date;
    numcartesejour: string;
    periodevalidite: number;
    idnationalite: Nationalite;
    idpaysresidence: Pays;
    baccord: boolean;
    constructor({idemprunteurinfos = null, civilite = null, situationfamiliale = null, nom = null,
                nomjeunefille = null, prenom = null, email = null, adresse = null, complementadresse = null,
                codepostal = null, ville = null, idpays = null, datenaissance = null, telfixe = null,
                telport = null, datecreation = null, lienparente = null, villenaissance = null,
                nationalite = null, datemariage = null, contratmariage = null, regimematrimonial = null,
                situationimmo = null, role = null, nbparts = null, connaitcreditimmo = null,
                connaitautrecredit = null, connaittaeg = null, connaitdifferencetaux = null,
                connaitassurance = null, niveauconnaissance = null, telbureau = null, datesituationimmo = null,
                datedivorce = null, numcartesejour = null, periodevalidite = null, idnationalite = null,
                idpaysresidence = null, baccord = null} = {}) {
        this.idemprunteurinfos = idemprunteurinfos;
        this.civilite = civilite;
        this.situationfamiliale = situationfamiliale;
        this.nom = nom;
        this.nomjeunefille = nomjeunefille;
        this.prenom = prenom;
        this.email = email;
        this.adresse = adresse;
        this.complementadresse = complementadresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.idpays = idpays !== null ? new Pays(idpays) : new Pays();
        this.datenaissance = datenaissance;
        this.telfixe = telfixe;
        this.telport = telport;
        this.datecreation = datecreation;
        this.lienparente = lienparente;
        this.villenaissance = villenaissance;
        this.nationalite = nationalite;
        this.datemariage = datemariage;
        this.contratmariage = contratmariage;
        this.regimematrimonial = regimematrimonial;
        this.situationimmo = situationimmo;
        this.role = role;
        this.nbparts = nbparts;
        this.connaitcreditimmo = connaitcreditimmo;
        this.connaitautrecredit = connaitautrecredit;
        this.connaittaeg = connaittaeg;
        this.connaitdifferencetaux = connaitdifferencetaux;
        this.connaitassurance = connaitassurance;
        this.niveauconnaissance = niveauconnaissance;
        this.telbureau = telbureau;
        this.datesituationimmo = datesituationimmo;
        this.datedivorce = datedivorce;
        this.numcartesejour = numcartesejour;
        this.periodevalidite = periodevalidite;
        this.idnationalite = idnationalite !== null ? new Nationalite(idnationalite) : new Nationalite();
        this.idpaysresidence = idpaysresidence !== null ? new Pays(idpaysresidence) : new Pays();
        this.baccord = baccord;
    }
}

