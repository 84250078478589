import {AfterViewInit, ChangeDetectorRef, ContentChild, Directive, Input} from '@angular/core';
import {AbstractControl, FormControl, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material';

export abstract class CustomMatFormFieldNgControl extends NgControl {
    form?: AbstractControl;
}

export abstract class CustomMatFormFieldControl<T> extends MatFormFieldControl<T> {
    required: boolean;
    ngControl: CustomMatFormFieldNgControl;
}

/*
 * Permet d'ajouter les astérisques aux formControls avec Validators.required
 *
 * https://stackblitz.com/edit/angular-material2-issue-qdqb1n?file=app%2Fmat-form-field-required.directive.ts
 */

@Directive({
    selector: 'mat-form-field'
})
export class MatFormFieldRequiredDirective implements AfterViewInit {
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    @ContentChild(MatFormFieldControl) _control: CustomMatFormFieldControl<any>;

    // tslint:disable-next-line:variable-name
    constructor(private _cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.setRequired();

        /*
         * Changement dynamique (si on passe un validator en null)
         * Permet d'update les '*'
         */
        if (this._control.ngControl !== null) {
            this._control.ngControl.control.statusChanges.subscribe((next) => {
                this.setRequired();
            });
        }
    }

    setRequired() {
        if (this._control && this._control.ngControl) {
            const {ngControl} = this._control;
            const validator =
                ngControl.form // [formControl] standalone
                    ? ngControl.form.validator
                    : ngControl.control // formControlName in FromGroup
                    ? ngControl.control.validator
                    : null;
            if (validator) {
                Promise.resolve().then(() => {
                    this._control.required = !!(validator(new FormControl()) || {}).required; // thx @kwkelly
                    this._cdRef.markForCheck();
                });
            } else {
                Promise.resolve().then(() => {
                    this._control.required = false;
                    this._cdRef.markForCheck();
                });
            }
        }
    }

}
