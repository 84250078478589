import {Facture} from '../facture/facture.model';
import * as moment from 'moment';
import {Moment} from 'moment';


export class Facturereglement {

    idfacturereglement: number;
    datereglement: Moment;
    montant: number;
    moyenpaiement: string;
    idfacture: Facture;

    constructor({
                    idfacturereglement = null, datereglement = null, montant = null,
                    moyenpaiement = null, idfacture = null
                } = {}) {
        this.idfacturereglement = idfacturereglement;
        this.datereglement = datereglement !== null ? moment(new Date(datereglement)) : null;
        this.montant = montant;
        this.moyenpaiement = moyenpaiement;
        this.idfacture = (idfacture === null) ? new Facture() : new Facture(idfacture);
    }
}

