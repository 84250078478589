import {Entity} from '../entity.interface';
import {Utilisateur} from '../utilisateur/utilisateur/utilisateur.model';
import {Entreprise} from '../entite/entreprise/entreprise.model';
import {Repertoire} from './repertoire.model';

export class Docpersomodele implements Entity {
    iddocpersomodele: number;
    type: string;
    nom: string;
    auteur: Utilisateur;
    repertoire: Repertoire;
    generation: number;
    etat: string;
    content: string;
    footer: string;
    header: string;
    identreprise: Entreprise;
    parent: Docpersomodele;
    obligatoire: boolean;

    constructor({
                    iddocpersomodele = null, type = null, nom = null, auteur = null, repertoire = null,
                    generation = null, etat = null, content = null, identreprise = null, parent = null,
                    obligatoire = null, footer = null, header = null
                } = {}) {
        this.iddocpersomodele = iddocpersomodele;
        this.type = type;
        this.nom = nom;
        this.auteur = auteur !== null ? new Utilisateur(auteur) : new Utilisateur();
        this.repertoire = repertoire !== null ? new Repertoire(repertoire) : new Repertoire();
        this.generation = generation;
        this.etat = etat;
        this.content = content;
        this.footer = footer;
        this.header = header;
        this.identreprise = identreprise !== null ? new Entreprise(identreprise) : new Entreprise();
        this.parent = parent !== null ? new Docpersomodele(parent) : null;
        this.obligatoire = obligatoire;
    }
}
