import {Injectable} from '@angular/core';
import {Authtoken} from '../../entities/authtoken.model';
import {CookieService} from 'ngx-cookie-service';
import {EnvService} from '../rest/env.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(private cookies: CookieService, private envService: EnvService) {
    }

    // ---------- LOCAL STORAGE

    setLSString(item: string, str: string) {
        localStorage.setItem(item, str);
    }

    getLSItem(item: string) {
        const storedItem = JSON.parse(localStorage.getItem(item));

        if (storedItem === null) {
            return false;
        }

        return storedItem;
    }

    existItem(item: string) {
        return JSON.parse(localStorage.getItem(item)) !== null;
    }

    deleteItem(item: string) {
        if (this.existItem(item)) {
            localStorage.removeItem(item);
        }
    }

    setLSItem(item: string, obj: object) {
        localStorage.setItem(item, JSON.stringify(obj));
    }

    clearStorage() {
        localStorage.clear();
    }

    // ----------- COOKIES

    setCItem(item: string, data: string) {
        // console.log(this.envService.getDomain());
        this.cookies.set(item, data, null, null, this.envService.getDomain());
    }

    deleteCItem(item: string) {
        this.cookies.delete(item, '/', this.envService.getDomain());
    }

    getCEntity(entity: string): any {
        const storedItemStr = this.cookies.get(entity);

        if (storedItemStr.trim().length === 0) {
            return false;
        }

        if (entity.indexOf('auth_token') !== -1) {
            return new Authtoken({token: storedItemStr});
        } else {
            switch (entity) {
                default:
                    // console.log('The entity ' + entity + ' does not exists.');
                    return false;
            }
        }
    }
}
