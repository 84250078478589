export class Ristournestatut {

    idristournestatut: number;
    libelle: string;
    statut: number;

    constructor({idristournestatut = null, libelle = null, statut = null} = {}) {
        this.idristournestatut = idristournestatut;
        this.libelle = libelle;
        this.statut = statut;
    }
}
