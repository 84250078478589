import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SessionService} from '../local/session.service';

@Injectable({
    providedIn: 'root'
})
export class ViewmodeService {

    constructor(private session: SessionService) {
    }

    disable(formgroup: FormGroup, droit: string) {
        if (!this.session.user.idprofil.hasOwnProperty(droit) || !this.session.user.idprofil[droit] === true) {
            formgroup.disable();
        }
    }

    checkDroit(droits: string[]) {
        let hasDroit = false;

        for (const droit of droits) {
            const splittedDroits = droit.split('&');

            let subHasDroit = true;
            for (const splittedDroit of splittedDroits) {
                subHasDroit = subHasDroit && this.evaluate(splittedDroit);
            }

            hasDroit = hasDroit || subHasDroit;
        }

        return hasDroit;
    }

    evaluate(droit) {
        let hasDroit = false;
        let perimeterValue;

        if (droit.indexOf('=') !== -1) {
            const splittedPerimeter = droit.split('=');
            droit = splittedPerimeter[0];
            perimeterValue = parseInt(splittedPerimeter[1], 10);
        }

        if (this.session.user.hasOwnProperty('idprofil') && this.session.user.idprofil.idprofil !== null) {
            if (this.session.user.idprofil.hasOwnProperty(droit)) {
                if (typeof this.session.user.idprofil[droit] === 'boolean') {
                    hasDroit = this.session.user.idprofil[droit];
                } else if (typeof this.session.user.idprofil[droit] === 'number' && perimeterValue !== undefined) {
                    hasDroit = this.session.user.idprofil[droit] === perimeterValue;
                }
            } else {
                // console.log(droit);
                this.session.logout('Configuration du compte incorrecte. Veuillez contacter un administrateur.');
                return;
            }
        } else {
            // console.log(droit);
            this.session.logout('Configuration du compte incorrecte. Veuillez contacter un administrateur.');
            return;
        }

        return hasDroit;
    }
}
