import {Facturereglement} from '../facturereglement/facturereglement.model';
import {Dossier} from '../../dossier/dossier/dossier.model';
import {Entity} from '../../entity.interface';
import {Factureinfosdossier} from '../factureinfosdossier/factureinfosdossier.model';
import {Facturedestinataire} from '../facturedestinataire/factureedestinataire.model';
import {Factureexpediteur} from '../factureexpediteur/factureexpediteur.model';
import {Moment} from 'moment';
import * as moment from 'moment';
import {Utilisateur} from "../../utilisateur/utilisateur/utilisateur.model";

export class Facture implements Entity {
    idfacture: number;
    iddossier: Dossier; // Dossier;
    bpaye: boolean;
    reference: string;
    type: string;
    pdf: string;
    titre: string;
    avoirsur: any; // Avoir;
    totalht: number;
    dateemission: Moment;
    totalttc: number;
    dateecheance: Moment;
    idfactureinfosdossier: Factureinfosdossier;
    idfacturedestinataire: Facturedestinataire;
    idfactureexpediteur: Factureexpediteur;
    commentaire: string;
    escompte: string;
    designation: string;
    idutilisateur: Utilisateur; // Utilisateur;
    bvalide: boolean;
    montantreglements: number;
    reglements: Facturereglement[];

    constructor({
                    idfacture = null, iddossier = null, bpaye = null, reference = null, type = null,
                    pdf = null, titre = null, avoirsur = null, totalht = null, dateemission = null,
                    totalttc = null, dateecheance = null, idfactureinfosdossier = null, idfacturedestinataire = null,
                    idfactureexpediteur = null, commentaire = null, escompte = null, designation = null,
                    idutilisateur = null, bvalide = null, montantreglements = null
                } = {}) {
        this.idfacture = idfacture;
        this.iddossier = iddossier === null ? new Dossier() : new Dossier(iddossier);
        this.bpaye = bpaye;
        this.reference = reference;
        this.type = type;
        this.pdf = pdf;
        this.titre = titre;
        this.avoirsur = avoirsur;
        this.totalht = totalht;
        this.dateemission = dateemission !== null ? moment(new Date(dateemission)) : null;
        this.totalttc = totalttc;
        this.dateecheance = dateecheance !== null ? moment(new Date(dateecheance)) : null;
        this.idfactureinfosdossier = (idfactureinfosdossier === null) ? new Factureinfosdossier() : new Factureinfosdossier(idfactureinfosdossier);
        this.idfacturedestinataire = (idfacturedestinataire === null) ? new Facturedestinataire() : new Facturedestinataire(idfacturedestinataire);
        this.idfactureexpediteur = (idfactureexpediteur === null) ? new Factureexpediteur() : new Factureexpediteur(idfactureexpediteur);
        this.commentaire = commentaire;
        this.escompte = escompte;
        this.designation = designation;
        this.idutilisateur = (idutilisateur === null) ? new Utilisateur() : new Utilisateur(idutilisateur);
        this.bvalide = bvalide;
        this.montantreglements = montantreglements;
        this.reglements = [];
    }
}

