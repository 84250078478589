import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Liste} from '../../liste.model';
import {of} from 'rxjs';
import * as NouveautesActions from './nouveautes.action';
import * as TrashActions from '../../trash.action';
import {EntityService} from '../../../services/rest/entity.service';
import {ParseService} from '../../../services/utility/parse.service';
import {Nouveautes} from './nouveautes.model';

@Injectable()
export class NouveautesEffects {
    getLastArticle$ = createEffect(() => this.actions$.pipe(
        ofType(NouveautesActions.GET_LAST_ARTICLE),
        switchMap((action: NouveautesActions.GetLastArticle) => this.entity.getEntity('nouveautes', 'lastarticle')
            .pipe(map((response: Nouveautes) => ({
                    type: NouveautesActions.GET_LAST_ARTICLE_SUCCESS,
                    payload: response
            })),
                catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    getLastArticleByEspace$ = createEffect(() => this.actions$.pipe(
        ofType(NouveautesActions.GET_LAST_ARTICLES_BY_ESPACE),
        switchMap((action: NouveautesActions.GetLastArticleByEspace) => this.entity.getEntities('nouveautes', 'lastarticlebyespace')
            .pipe(map((response: Nouveautes[]) => ({
                    type: NouveautesActions.GET_LAST_ARTICLES_BY_ESPACE_SUCCESS,
                    payload: response
            })),
                catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    constructor(private actions$: Actions, private entity: EntityService, private parse: ParseService) {
    }
}
