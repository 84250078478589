import {Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {

    isOpen = false;
    nav: MatSidenav;

    constructor() {
    }

    toggle() {
        this.nav.toggle().then(res => {
            this.isOpen = res === 'open';
        });
    }
}
