import {Entity} from '../../entity.interface';

export class Exclumot implements Entity {
    id: number;
    exclu: string;

    constructor({id= null, exclu = null} = {}) {
        this.id = id;
        this.exclu = exclu;
    }
}
