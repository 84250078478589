import {Entity} from '../entity.interface';
import {Client} from '../entite/client/client.model';
import {Familleapporteur} from "./familleapporteur.model";

export class Typeapporteur implements Entity {
    idtypeapporteur: number;
    idclient: Client;
    nom: string;
    idfamille: Familleapporteur;
    bsupprimable: boolean;
    nbapporteur: number;
    bactive: boolean;

    constructor({
        idtypeapporteur = null,
        idclient = null,
        nom = null,
        idfamille = null,
        bsupprimable = null,
        bactive= null,
        nbapporteur = null
    } = {}) {
        this.idtypeapporteur = idtypeapporteur;
        this.idclient = (idclient === null) ? new Client() : new Client(idclient);
        this.nom = nom;
        this.idfamille = (idfamille === null) ? new Familleapporteur() : new Familleapporteur(idfamille);
        this.bsupprimable = bsupprimable;
        this.nbapporteur = nbapporteur;
        this.bactive = bactive;
    }
}
