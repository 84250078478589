import {Component, Input, OnInit} from '@angular/core';
import {Pipelinestep, TruckType} from '../../../../entities/pipeline/pipelinestep.model';

@Component({
    selector: 'cre-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

    @Input() step: Pipelinestep;
    @Input() formatTemps: string;

    constructor() {
    }

    ngOnInit() {
    }

    getTruck() {
        switch (this.step.trucktype) {
            case TruckType.INACTIVE:
                return '../../../../../assets/pictos/truck_inactive.svg';
                break;
            case TruckType.ACTIVE:
                return '../../../../../assets/pictos/truck_active.svg';
                break;
            case TruckType.DELAYED:
                return '../../../../../assets/pictos/truck_delayed.svg';
                break;
            case TruckType.UNDETERMINED:
                return '../../../../../assets/pictos/truck_undetermined.svg';
                break;
        }
    }
}
