import {Entity} from '../../entity.interface';
import {Exclumot} from './exclumot.model';
import {Variantemot} from './variantemot.model';

export class Motcle implements Entity {
    idmotcle: number;
    mot: string;
    exclumots = [];
    variantemots = [];

    constructor({idmotcle = null, mot = null, exclumots = [], variantemots = []} = {}) {
        this.idmotcle = idmotcle;
        this.mot = mot;
        exclumots.forEach((exclumot: Exclumot) => {
            this.exclumots.push(new Exclumot(exclumot));
        });
        variantemots.forEach((variantemot: Variantemot) => {
            this.variantemots.push(new Variantemot(variantemot));
        });
    }
}
