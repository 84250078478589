import { ChangeDetectorRef, Component, ElementRef, HostListener, HostBinding, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {MAT_OPTION_PARENT_COMPONENT, MatOptgroup, MatOption, MatOptionParentComponent, MatPseudoCheckboxState} from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cre-select-all-option',
    templateUrl: './select-all-option.component.html',
    styleUrls: ['./select-all-option.component.scss']
})
export class SelectAllOptionComponent extends MatOption implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() title: string;
    @Input() values: any[] = [];
    @Input() valuesProperty: string;
    @HostBinding('class') cssClass = 'mat-option';
    protected unsubscribe: Subject<any>;

    constructor(elementRef: ElementRef<HTMLElement>,
                changeDetectorRef: ChangeDetectorRef,
                @Optional() @Inject(MAT_OPTION_PARENT_COMPONENT) parent: MatOptionParentComponent,
                @Optional() group: MatOptgroup) {
        super(elementRef, changeDetectorRef, parent, group);

        this.title = 'Tous';
    }

    get selectedItemsCount(): number {
        return this.control && Array.isArray(this.control.value) ? this.control.value.filter(el => el !== null).length : 0;
    }

    get selectedAll(): boolean {
        return this.selectedItemsCount === this.values.length;
    }

    get selectedPartially(): boolean {
        const selectedItemsCount = this.selectedItemsCount;

        return selectedItemsCount > 0 && selectedItemsCount < this.values.length;
    }

    get checkboxState(): MatPseudoCheckboxState {
        let state: MatPseudoCheckboxState = 'unchecked';

        if (this.selectedAll) {
            state = 'checked';
        } else if (this.selectedPartially) {
            state = 'indeterminate';
        }

        return state;
    }

    @HostListener('click') toggleSelection(): void {
        this._selectViaInteraction();

        let values = this.values;

        if (this.valuesProperty) {
            values = values.map(value => value[this.valuesProperty]);
        }

        this.control.markAsDirty();

        this.control.setValue(this.selected ? values : []);
    }

    ngOnInit(): void {
        this.unsubscribe = new Subject<any>();

        this.refresh();

        this.control.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.refresh();
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.unsubscribe) {
            this.unsubscribe.next();
            this.unsubscribe.complete();
        }
    }

    refresh(): void {
        if (this.selectedItemsCount > 0) {
            this.select();
        } else {
            this.deselect();
        }
    }
}
