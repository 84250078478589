import {Entity} from '../entity.interface';
import {Verifications} from './verifications.model';
import {Etape} from './etape.model';

export class Verification implements Entity {
    idverification: string;
    idverifications: Verifications;
    idetape: Etape;
    bobligatoire: boolean;

    constructor({idverification = null, idverifications = null, idetape = null, bobligatoire = null} = {}) {
        this.idverification = idverification;
        this.idverifications = idverifications !== null ? new Verifications(idverifications) : new Verifications();
        this.idetape = idetape !== null ? new Etape(idetape) : new Etape();
        this.bobligatoire = bobligatoire;
    }
}
