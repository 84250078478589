import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'cre-modale-confirmation-paste',
    templateUrl: './modale-confirmation-paste.component.html',
    styleUrls: ['./modale-confirmation-paste.component.scss']
})
export class ModaleConfirmationPasteComponent implements OnInit {

    constructor(public dialog: MatDialogRef<ModaleConfirmationPasteComponent>, @Inject(MAT_DIALOG_DATA) public data: { file }) {
    }

    ngOnInit() {
    }

}
