import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Nouveautes} from '../../../entities/public/nouveautes/nouveautes.model';

export interface NouveautesDialogData {
  nouveautes: Nouveautes;
}

@Component({
  selector: 'cre-modale-nouveautes',
  templateUrl: './modale-nouveautes.component.html',
  styleUrls: ['./modale-nouveautes.component.scss']
})
export class ModaleNouveautesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModaleNouveautesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NouveautesDialogData) { }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }


  dateAsString(date: Date): string {
    date = new Date(date);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }

}
