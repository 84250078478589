import {Entity} from '../entity.interface';

export class Provenance implements Entity {
    idprovenance: number;
    nom: string;
    bdefaut: boolean;
    nbdossiers: number;


    constructor({idprovenance = null, nom = null, bdefaut = false, nbdossiers = null} = {}) {
        this.idprovenance = idprovenance;
        this.nom = nom;
        this.bdefaut = bdefaut;
        this.nbdossiers = nbdossiers;
    }
}
