import {Action} from '@ngrx/store';
import {Nouveautes} from './nouveautes.model';
import {Liste} from '../../liste.model';

export const GET_LAST_ARTICLE = '[NOUVEAUTES] Get last article';
export const GET_LAST_ARTICLE_SUCCESS = '[NOUVEAUTES] Get last article success';
export const GET_LAST_ARTICLES_BY_ESPACE = '[NOUVEAUTES] Get last article by espace';
export const GET_LAST_ARTICLES_BY_ESPACE_SUCCESS = '[NOUVEAUTES] Get last article by espace success';

export class GetLastArticle implements Action {
    readonly type = GET_LAST_ARTICLE;
}

export class GetLastArticleSuccess implements Action {
    readonly type = GET_LAST_ARTICLE_SUCCESS;

    constructor(public payload: Nouveautes) {
    }
}

export class GetLastArticleByEspace implements Action {
    readonly type = GET_LAST_ARTICLES_BY_ESPACE;
}

export class GetLastArticleByEspaceSuccess implements Action {
    readonly type = GET_LAST_ARTICLES_BY_ESPACE_SUCCESS;

    constructor(public payload: Nouveautes[]) {
    }
}

export type Actions = GetLastArticle | GetLastArticleSuccess | GetLastArticleByEspace | GetLastArticleByEspaceSuccess;
