import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModaleNouveautesComponent} from './modale-nouveautes/modale-nouveautes.component';
import {AppState} from '../../../../../app.state';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {Nouveautes} from '../../entities/public/nouveautes/nouveautes.model';
import * as NouveautesActions from '../../entities/public/nouveautes/nouveautes.action';
import {Observable} from 'rxjs';
import {SessionService} from '../../services/local/session.service';

@Component({
    selector: 'cre-nouveautes',
    templateUrl: './nouveautes.component.html',
    styleUrls: ['./nouveautes.component.scss']
})
export class NouveautesComponent implements OnInit {

    public nouveautes: Nouveautes;
    public dialogRef: MatDialogRef<ModaleNouveautesComponent>;
    @Input()
    expanded: boolean;

    constructor(public dialog: MatDialog, public store: Store<AppState>, private actions: Actions, private session: SessionService) {
        this.downloadHtml();
    }

    downloadHtml() {
        // TODO
        this.nouveautes = {
            idnouveautes: -1,
            titre: 'Chargement des nouveautés...',
            contenu: '',
            datecreation: new Date(),
            espace: 'not specified'
        };

        this.store.dispatch(new NouveautesActions.GetLastArticle());

        const killsubscribe = this.actions.pipe(ofType(NouveautesActions.GET_LAST_ARTICLE_SUCCESS))
            .subscribe((action: NouveautesActions.GetLastArticleSuccess) => {
                killsubscribe.unsubscribe();

                this.nouveautes = action.payload;
                // console.log(this.nouveautes, this.getCurrentEspace(), articles);
                // Only if modal is already open
                if (this.dialogRef) {
                    this.dialogRef.componentInstance.data = {nouveautes: this.nouveautes};
                }
            });

        // const killsubscribe = this.actions.pipe(ofType(NouveautesActions.GET_LAST_ARTICLES_BY_ESPACE_SUCCESS))
        //     .subscribe((articles: any) => {
        //         killsubscribe.unsubscribe();
        //
        //         this.nouveautes = articles.payload.filter((nouv: Nouveautes) => nouv.espace === this.getCurrentEspace())[0];
        //         // console.log(this.nouveautes, this.getCurrentEspace(), articles);
        //         // Only if modal is already open
        //         if (this.dialogRef) {
        //             this.dialogRef.componentInstance.data = {nouveautes: this.nouveautes};
        //         }
        //     });
    }

    ngOnInit() {
        // // If bshownouveautes is true, user should be shown the nouveautes modal when landing on page
        // if (this.session.user.bshownouveautes) {
        //     // const espace = this.getCurrentEspace(); TODO EVO: NOUVEAUTES MULTI ESPACES
        //     this.session.user.bshownouveautes = false;
        //     this.store.dispatch(new UtilisateurActions.ToggleUtilisateurNouveaute(this.session.user));
        //     this.openModal();
        // }0
    }

    openModal(): void {
        this.dialogRef = this.dialog.open(ModaleNouveautesComponent, {
            width: '80%',
            maxWidth: '1280px',
            data: {
                nouveautes: this.nouveautes
            }
        });
    }

    getCurrentEspace(): string | boolean {
        const url = window.location.href;
        // Get index of the end of space name
        let index = url.indexOf('.ymanci.fr');
        if (index === -1) {
            index = url.indexOf('.mymanci.premista.tech');
        }

        if (index !== -1) {
            const espace = url.substring(0, index);
            return espace.substring(espace.lastIndexOf('.') + 1, index);
        } else {
            console.log(new Error('Impossible de charger les nouveautes, l\'url du site est inconnue !'));
        }
    }
}
