import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
    selector: 'cre-error-tracker',
    templateUrl: './error-tracker.component.html',
    styleUrls: ['./error-tracker.component.scss']
})
export class ErrorTrackerComponent implements OnInit {

    items: Observable<any[]>;

    constructor(db: AngularFirestore) {
        this.items = db.collection('errors').valueChanges();
    }

    ngOnInit() {
    }

}
