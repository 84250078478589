import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    constructor(private snackBar: MatSnackBar) {
    }

    open(text: string) {
        this.snackBar.open(text);
    }

    openSuccess(text: string) {
        this.snackBar.open(text, null, {panelClass: ['green-snackbar']});
    }

    openError(text: string) {
        this.snackBar.open(text, null, {panelClass: ['red-snackbar']});
    }
}
