import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'matchesAttachments'
})
export class MatchesAttachmentsPipe implements PipeTransform {

    transform(items: any, mode: number): Array<any> {
        if (items == undefined) {
            return;
        }
        if (mode == 0) {
            return items.filter(item => !item.resume);
        }

        if (mode == 1) {
            return items.filter(item => item.resume === true);
        }

        return items;
    }

}
