import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as Countdown from 'countdown';
import {interval, Subscription} from 'rxjs';
import {EnvService} from '../../services/rest/env.service';

@Component({
    selector: 'cre-modale-avertissement-deploiement',
    templateUrl: './modale-avertissement-deploiement.component.html',
    styleUrls: ['./modale-avertissement-deploiement.component.scss']
})
export class ModaleAvertissementDeploiementComponent implements OnInit {

    tempsRestant: Countdown;
    timer: Subscription;
    date: Date;
    contenu: string;

    constructor(public dialog: MatDialogRef<ModaleAvertissementDeploiementComponent>, @Inject(MAT_DIALOG_DATA) public data: { date, texte },
                public env: EnvService) {
    }

    ngOnInit() {
        this.contenu = this.data.texte.replace(/\r\n|\r|\n/g, '<br>');
        this.date = new Date(this.data.date);
        this.getTempsrestant(this.data.date);
        this.initTimer();
    }

    private initTimer() {
        this.timer = interval(1000).subscribe(_ => {
            this.getTempsrestant(this.data.date);
            if (this.compareDate(this.tempsRestant.end, this.tempsRestant.start)) {
                this.timer.unsubscribe();
                this.tempsRestant.hours = 0;
                this.tempsRestant.minutes = 0;
                this.tempsRestant.seconds = 0;
            }
        });
    }

    private getTempsrestant(date1: number) {
        this.tempsRestant = new Countdown(date1);
    }

    public displayCountdown(countdown: Countdown) {
        return (('0' + countdown.hours).slice(-2) + ' heures ')
            + ('0' + countdown.minutes).slice(-2) + ' minutes '
            + ('0' + countdown.seconds).slice(-2) + ' secondes ';
    }

    compareDate(date1: Date, date2: Date) {
        return date1.getSeconds() === date2.getSeconds()
            &&
            date1.getMinutes() === date2.getMinutes()
            &&
            date1.getHours() === date2.getHours()
            &&
            date1.toDateString() === date2.toDateString();
    }
}
