import {Emprunteurinfos} from '../emprunteurinfos/emprunteurinfos.model';

export class Emprunteur {
    idemprunteur: number;
    idemprunteurinfos: Emprunteurinfos;
    numordre: number;

    constructor({idemprunteur = null, idemprunteurinfos = null, numordre = null} = {}) {
        this.idemprunteur = idemprunteur;
        this.idemprunteurinfos = idemprunteurinfos !== null ? new Emprunteurinfos(idemprunteurinfos) : new Emprunteurinfos();
        this.numordre = numordre;
    }
}
