
export class Droit {
    iddroit: number;
    libelle: any;
    niveau: any;

    constructor({iddroit = null, libelle = {}, niveau = {}} = {}) {
        this.iddroit = iddroit;
        this.libelle = libelle;
        this.niveau = niveau;
    }

}
