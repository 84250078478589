import {Pays} from '../../public/pays/pays.model';
import {Poleimmobilier} from '../../banque/poleimmobilier/poleimmobilier.model';
import {Franchise} from '../franchise/franchise.model';
import {Client} from '../client/client.model';
import {Paramlisteentreprise} from '../../parametre/paramlisteentreprise/paramlisteentreprise.model';

export class Entreprise {

    identreprise: number;
    logo1: string;
    nom: string;
    adressesiege: string;
    raisonsociale: string;
    siret: string;
    activite: string;
    capitalsocial: number;
    tvaintracom: string;
    codepostal: string;
    ville: string;
    idpays: Pays;
    tel1: string;
    tel2: string;
    fax: string;
    email: string;
    type: string;
    coordonneesservicereclamation: string;
    nomcompagnieassurance: string;
    numerocontratassurance: string;
    orias: string;
    villercs: string;
    numrcs: string;
    banquetiersca: Poleimmobilier;
    banquemontantsup10: Poleimmobilier;
    banqueparticipationcourtiersup10: Poleimmobilier;
    siren: string;
    idfranchise: Franchise;
    mentionslegales: string;
    baffichermentionslegales: boolean;
    bactif: boolean;
    datedesactivation: Date;
    ribtitulaire: string;
    ribiban: string;
    ribbic: string;
    bbanqueentreprise: boolean;
    bbanqueagence: boolean;
    couleurfieldset: string;
    couleurfond: string;
    couleurtitletext: string;
    couleurtab: string;
    indicefacture: string;
    prefixefacture: string;
    bnouveaumodele: boolean;
    bpoleimmobiliertoutesagences: boolean;
    escompte: string;
    pourcentageponderationloyer: string;
    forfaithonorairesfixe: string;
    fichiersauvegarde: string;
    idclient: Client;
    paramlisteentreprise: Paramlisteentreprise[];
    nbagences: number;
    mentioniobsp: number;
    mentionias: number;
    siegesocialadresse: string;
    bafficherribfacturecommissionsbancaires: boolean;
    bafficherribfacturehonoraires: boolean;
    bmandataire: boolean;
    baffichertableaupretsmandat: boolean;
    bautoriserfacturemandataire: boolean;
    butiliseradresseagence: boolean;
    butiliseragencereferencefacture: boolean;
    challenge: number;
    bnepasafficherdatesdansfacture: number;

    constructor({
                    identreprise = null, logo1 = null, nom = null, adressesiege = null, raisonsociale = null,
                    siret = null, activite = null, capitalsocial = null, tvaintracom = null, codepostal = null,
                    ville = null, idpays = {}, tel1 = null, tel2 = null, fax = null, email = null, type = null,
                    coordonneesservicereclamation = null, nomcompagnieassurance = null, numerocontratassurance = null,
                    orias = null, villercs = null, numrcs = null, banquetiersca = {}, banquemontantsup10 = {},
                    banqueparticipationcourtiersup10 = {}, siren = null, idfranchise = {}, mentionslegales = null,
                    baffichermentionslegales = null, bactif = null, datedesactivation = null, ribtitulaire = null,
                    ribiban = null, ribbic = null, bbanqueentreprise = null, bbanqueagence = null, couleurfieldset = null,
                    couleurfond = null, couleurtitletext = null, couleurtab = null, indicefacture = null,
                    prefixefacture = null, bnouveaumodele = null, bpoleimmobiliertoutesagences = null, escompte = null,
                    forfaithonorairesfixe = null, pourcentageponderationloyer = null, fichiersauvegarde = null, idclient = null,
                    paramlisteentreprise = [], nbagences = null, mentioniobsp = null, mentionias = null, siegesocialadresse = null,
                    bafficherribfacturecommissionsbancaires = null, bafficherribfacturehonoraires = null, bmandataire = null, baffichertableaupretsmandat = null,
                    bautoriserfacturemandataire = null, butiliseradresseagence = null, butiliseragencereferencefacture = null, challenge = null, bnepasafficherdatesdansfacture = null
                } = {}) {
        this.identreprise = identreprise;
        this.logo1 = logo1;
        this.nom = nom;
        this.adressesiege = adressesiege;
        this.raisonsociale = raisonsociale;
        this.siret = siret;
        this.activite = activite;
        this.capitalsocial = capitalsocial;
        this.tvaintracom = tvaintracom;
        this.codepostal = codepostal;
        this.ville = ville;
        this.idpays = (idpays === null) ? new Pays() : new Pays(idpays);
        this.tel1 = tel1;
        this.tel2 = tel2;
        this.fax = fax;
        this.email = email;
        this.type = type;
        this.coordonneesservicereclamation = coordonneesservicereclamation;
        this.nomcompagnieassurance = nomcompagnieassurance;
        this.numerocontratassurance = numerocontratassurance;
        this.orias = orias;
        this.villercs = villercs;
        this.numrcs = numrcs;
        this.banquetiersca = (banquetiersca === null) ? new Poleimmobilier() : new Poleimmobilier(banquetiersca);
        this.banquemontantsup10 = (banquemontantsup10 === null) ? new Poleimmobilier() : new Poleimmobilier(banquemontantsup10);
        this.banqueparticipationcourtiersup10 = (banqueparticipationcourtiersup10 === null) ? new Poleimmobilier() : new Poleimmobilier(banqueparticipationcourtiersup10);
        this.siren = siren;
        this.idfranchise = (idfranchise === null) ? new Franchise() : new Franchise(idfranchise);
        this.mentionslegales = mentionslegales;
        this.baffichermentionslegales = baffichermentionslegales;
        this.bactif = bactif;
        this.datedesactivation = datedesactivation;
        this.ribtitulaire = ribtitulaire;
        this.ribiban = ribiban;
        this.ribbic = ribbic;
        this.bbanqueentreprise = bbanqueentreprise;
        this.bbanqueagence = bbanqueagence;
        this.couleurfieldset = couleurfieldset;
        this.couleurfond = couleurfond;
        this.couleurtitletext = couleurtitletext;
        this.couleurtab = couleurtab;
        this.indicefacture = indicefacture;
        this.prefixefacture = prefixefacture;
        this.bnouveaumodele = bnouveaumodele;
        this.bpoleimmobiliertoutesagences = bpoleimmobiliertoutesagences;
        this.escompte = escompte;
        this.pourcentageponderationloyer = pourcentageponderationloyer;
        this.forfaithonorairesfixe = forfaithonorairesfixe;
        this.fichiersauvegarde = fichiersauvegarde;
        this.idclient = (idclient === null ? new Client() : new Client(idclient));
        this.paramlisteentreprise = paramlisteentreprise as Paramlisteentreprise[];
        this.nbagences = nbagences;
        this.mentioniobsp = mentioniobsp;
        this.mentionias = mentionias;
        this.siegesocialadresse = siegesocialadresse;
        this.bmandataire = bmandataire;
        this.bafficherribfacturecommissionsbancaires = bafficherribfacturecommissionsbancaires;
        this.bafficherribfacturehonoraires = bafficherribfacturehonoraires;
        this.baffichertableaupretsmandat = baffichertableaupretsmandat;
        this.bautoriserfacturemandataire = bautoriserfacturemandataire;
        this.butiliseradresseagence = butiliseradresseagence;
        this.butiliseragencereferencefacture = butiliseragencereferencefacture;
        this.challenge = challenge;
        this.bnepasafficherdatesdansfacture = bnepasafficherdatesdansfacture;
    }
}

