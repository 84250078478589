import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Entity} from '../../entities/entity.interface';
import {Agence} from '../../entities/entite/agence/agence.model';
import {Authtoken} from '../../entities/authtoken.model';
import {Liste} from '../../entities/liste.model';
import {Directionregionale} from '../../entities/entite/directionregionale/directionregionale.model';
import {Facturereglement} from '../../entities/facture/facturereglement/facturereglement.model';
import {Groupeagences} from '../../entities/entite/groupeagences/groupeagences.model';
import {Dossier} from '../../entities/dossier/dossier/dossier.model';
import {Entreprise} from '../../entities/entite/entreprise/entreprise.model';
import {Facturedestinataire} from '../../entities/facture/facturedestinataire/factureedestinataire.model';
import {Facture} from '../../entities/facture/facture/facture.model';
import {Utilisateur} from '../../entities/utilisateur/utilisateur/utilisateur.model';
import {Agencegroupeagences} from '../../entities/entite/agencegroupeagences/agencegroupeagences.model';
import {Client} from '../../entities/entite/client/client.model';
import {Profil} from '../../entities/utilisateur/profil/profil.model';
import {Docpersomodele} from '../../entities/document/docpersomodele.model';
import {Ristourne} from '../../entities/facture/ristourne/ristourne.model';
import {Ristournereglement} from '../../entities/facture/ristournereglement/ristournereglement.model';
import {Module} from '../../entities/modules/module/module.model';
import {Etape} from '../../entities/pipeline/etape.model';
import {Verification} from '../../entities/pipeline/verification.model';
import {MailHistorique} from '../../entities/administration/mailHistorique.model';
import {Nouveautes} from '../../entities/public/nouveautes/nouveautes.model';
import {Utilisateurapikey} from '../../entities/utilisateur/utilisateurapikey/utilisateurapikey.model';
import {Contrat} from '../../entities/document/contrat.model';
import {Template} from '../../entities/administration/template.model';
import {Pipeline} from '../../entities/pipeline/pipeline.model';
import {Typeapporteur} from '../../entities/apporteur/typeapporteur.model';
import {Groupeapporteur} from '../../entities/apporteur/groupeapporteur.model';
import {Classeapporteur} from '../../entities/apporteur/classeapporteur.model';
import {HttpParams} from '@angular/common/http';
import {Aide} from '../../entities/utilisateur/utilisateur/aide.model';
import {Motcle} from '../../entities/releve/motcle/motcle.model';
import {Provenance} from '../../entities/apporteur/provenance.model';
import {Onglet} from '../../entities/statistique/onglet/onglet.model';
import {Stat} from '../../entities/statistique/stat/stat.model';
import {Option} from '../../entities/statistique/option/option.model';

@Injectable({
    providedIn: 'root'
})
export class EntityService {

    // tslint:disable-next-line:variable-name
    private readonly _resource: ResourceService;

    constructor(resource: ResourceService) {
        this._resource = resource;
    }

    get resource(): ResourceService {
        return this._resource;
    }

    public getVirginEntity(entity: string): Entity {
        switch (entity) {
            case 'agences':
                return new Agence();
            case 'directionregionales':
                return new Directionregionale();
            case 'facturereglements':
                return new Facturereglement();
            case 'groupeagences':
                return new Groupeagences();
            case 'entreprises':
                return new Entreprise();
            case 'facturedestinataires':
                return new Facturedestinataire();
            case 'factures':
                return new Facture();
            case 'utilisateurs':
                return new Utilisateur();
            case 'profils':
                return new Profil();
            case 'modules':
                return new Module();
            case 'clients':
                return new Client();
            case 'mailhistorique':
                return new MailHistorique();
            case 'typeapporteur':
                return new Typeapporteur();
            case 'provenance':
                return new Provenance();
            case 'groupeapporteur':
                return new Groupeapporteur();
            case 'classeapporteur':
                return new Classeapporteur();
            case 'aides':
                return new Aide();
            case 'motscles':
                return new Motcle();
            case 'onglets':
                return new Onglet();
            case 'stats':
                return new Stat();
            case 'options':
                return new Option();
            default:
                throw new Error('The entity ' + entity + ' does not exists.');
        }
    }

    /**
     * getEntities starts a http request, and is supposed to obtain a array of 'entity' objects instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/etc..
     * @param entity: string
     * @param urlComponents: string[]
     */
    public getEntities(entity: string, ...urlComponents: string[]): Observable<Entity[]> {
        const callback = this.determineCallbackToBuildEntities(entity);
        return this.resource.get(entity, ...urlComponents).pipe(map((entities) => callback(entities)));
    }

    /**
     * getEntitiesByFiltres starts a http request, and is supposed to obtain a array of 'entity' objects instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/etc..
     * @param obj: object
     * @param entity: string
     * @param urlComponents: string[]
     */
    public getEntitiesByFiltres(obj: object, entity: string, ...urlComponents: string[]): Observable<Liste> {
        const callback = this.determineCallbackToBuildEntities(entity);
        return this.getEntitiesByFiltresWithCallback(obj, callback, entity, ...urlComponents);
    }

    /**
     * getEntitiesByFiltresWithCallback starts a http request, and is supposed to obtain a array of 'entity' objects instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/etc..
     * @param obj: object
     * @param callback: any
     * @param entity: string
     * @param urlComponents: string[]
     */
    public getEntitiesByFiltresWithCallback(obj: object, callback: any, entity: string, ...urlComponents: string[]): Observable<Liste> {
        return this.resource.getByFiltres(obj, entity, ...urlComponents).pipe(map(response => {
            const entities = callback(response.liste);
            return new Liste(entities, response.total);
        }));
    }

    /**
     * getEntitiesByFiltresInParams starts a http request, and is supposed to obtain a array of 'entity' objects instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/...?params1&params2
     * @param params: HttpParams
     * @param callback: any
     * @param bpagination: boolean
     * @param urlComponents: string[]
     */
    public getEntitiesByFiltresInParams(params: HttpParams, callback: any, bpagination: boolean, ...urlComponents: string[]): Observable<Liste> {
        return this.resource.getWithParams(params, ...urlComponents).pipe(map(response => {
            if (bpagination) {
                const entities = callback(response.liste);
                return new Liste(entities, response.total);
            } else {
                return callback(response);
            }
        }));
    }

    /**
     * determineCallbackToBuildEntities will build a 'entity' array based of server response
     * @param entity: string
     */
    public determineCallbackToBuildEntities(entity: string) {
        let callback = null;
        switch (entity) {
            case 'agences':
                callback = (items): Entity[] => {
                    const agences = [];
                    for (const item of items) {
                        const agence = new Agence(item);
                        agences.push(agence);
                    }
                    return agences;
                };
                break;
            case 'directionregionales':
                callback = (items): Entity[] => {
                    const directionsregionales = [];
                    for (const item of items) {
                        directionsregionales.push(new Directionregionale(item));
                    }
                    return directionsregionales;
                };
                break;
            case 'groupeagences':
                callback = (items): Entity[] => {
                    const groupesagences = [];
                    for (const item of items) {
                        const groupeagences = new Groupeagences(item);
                        for (const agencegroupeagences of item.agencesgroupeagences) {
                            groupeagences.agencesgroupeagences.push(new Agencegroupeagences(agencegroupeagences));
                        }
                        groupesagences.push(groupeagences);
                    }
                    return groupesagences;
                };
                break;
            case 'entreprises':
                callback = (items): Entity[] => {
                    const entreprises = [];
                    for (const item of items) {
                        entreprises.push(new Entreprise(item));
                    }
                    return entreprises;
                };
                break;
            case 'utilisateurs':
                callback = (items): Entity[] => {
                    const utilisateurs = [];
                    for (const item of items) {
                        utilisateurs.push(new Utilisateur(item));
                    }
                    return utilisateurs;
                };
                break;
            case 'profils':
                callback = (items): Entity[] => {
                    const profils = [];
                    for (const item of items) {
                        profils.push(new Profil(item));
                    }
                    return profils;
                };
                break;
            case 'dossiers':
                callback = (items): Entity[] => {
                    const dossiers = [];
                    for (const item of items) {
                        const dossier = new Dossier(item);
                        if (item.factures) {
                            for (const facture of item.factures) {
                                dossier.factures.push(new Facture(facture));
                            }
                        }
                        dossiers.push(dossier);
                    }
                    return dossiers;
                };
                break;
            case 'factures' :
                callback = (items): Entity[] => {
                    const factures = [];
                    for (const item of items) {
                        const facture = new Facture(item);
                        for (const reglement of item.reglements) {
                            facture.reglements.push(new Facturereglement(reglement));
                        }
                        factures.push(facture);
                    }
                    return factures;
                };
                break;
            case 'docpersomodeles' :
                callback = (items): Entity[] => {
                    const docpersomodeles = [];
                    for (const item of items) {
                        const docpersomodele = new Docpersomodele(item);
                        docpersomodeles.push(docpersomodele);
                    }
                    return docpersomodeles;
                };
                break;
            case 'modules' :
                callback = (items): Entity[] => {
                    const modules = [];
                    for (const item of items) {
                        const module = new Module(item);
                        modules.push(module);
                    }
                    return modules;
                };
                break;
            case 'clients' :
                callback = (items): Entity[] => {
                    const clients = [];
                    for (const item of items) {
                        const client = new Client(item);
                        clients.push(client);
                    }
                    return clients;
                };
                break;
            case 'etapes' :
                callback = (items): Entity[] => {
                    const etapes = [];
                    for (const item of items) {
                        const etape = this.buildEtape(item);
                        etapes.push(etape);
                    }
                    return etapes;
                };
                break;
            case 'mailhistorique' :
                callback = (items): Entity[] => {
                    const mails = [];
                    for (const item of items) {
                        const mail = new MailHistorique(item);
                        mails.push(mail);
                    }
                    return mails;
                };
                break;
            case 'nouveautes' :
                callback = (items): Entity[] => {
                    const nouveautes = [];
                    for (const item of items) {
                        const nouveaute = new Nouveautes(item);
                        nouveautes.push(nouveaute);
                    }
                    return nouveautes;
                };
                break;
            case 'typeapporteur' :
                callback = (items): Entity[] => {
                    const typeapporteurs = [];
                    for (const item of items) {
                        const typeapporteur = new Typeapporteur(item);
                        typeapporteurs.push(typeapporteur);
                    }
                    return typeapporteurs;
                };
                break;
            case 'groupeapporteur' :
                callback = (items): Entity[] => {
                    const groupeapporteurs = [];
                    for (const item of items) {
                        const groupeapporteur = new Groupeapporteur(item);
                        groupeapporteurs.push(groupeapporteur);
                    }
                    return groupeapporteurs;
                };
                break;
            case 'classeapporteur' :
                callback = (items): Entity[] => {
                    const classeapporteurs = [];
                    for (const item of items) {
                        const classeapporteur = new Classeapporteur(item);
                        classeapporteurs.push(classeapporteur);
                    }
                    return classeapporteurs;
                };
                break;
            case 'utilisateurapikey' :
                callback = (items): Entity[] => {
                    const utilisateurapikeys = [];
                    for (const item of items) {
                        const utilisateurapikey = new Utilisateurapikey(item);
                        utilisateurapikeys.push(utilisateurapikey);
                    }
                    return utilisateurapikeys;
                };
                break;
            case 'aides' :
                callback = (items): Entity[] => {
                    const aides = [];
                    for (const item of items) {
                        const aide = new Aide(item);
                        aides.push(aide);
                    }
                    return aides;
                };
                break;
            case 'motscles' :
                callback = (items): Entity[] => {
                    const motscles = [];
                    for (const item of items) {
                        const motcle = new Motcle(item);
                        motscles.push(motcle);
                    }
                    return motscles;
                };
                break;
            case 'provenance':
                callback = (items): Entity[] => {
                    const provenances = [];
                    for (const item of items) {
                        const provenance = new Provenance(item);
                        provenances.push(provenance);
                    }
                    return provenances;
                };
                break;
            case 'onglets' :
                callback = (items): Entity[] => {
                    const onglets = [];
                    for (const item of items) {
                        const onglet = this.buildOnglet(item);
                        onglets.push(onglet);
                    }
                    return onglets;
                };
                break;
            case 'stats' :
                callback = (items): Entity[] => {
                    const stats = [];
                    for (const item of items) {
                        const stat = this.buildStat(item);
                        stats.push(stat);
                    }
                    return stats;
                };
                break;
            case 'options':
                callback = (items): Entity[] => {
                    const options = [];
                    for (const item of items) {
                        const option = new Stat(item);
                        options.push(option);
                    }
                    return options;
                };
                break;
            default:
                throw new Error('The entity ' + entity + ' does not exists as array.');
        }
        return callback;
    }

    /**
     * getEntityWithCallback starts a http request, and is supposed to obtain a 'entity' object instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/etc..
     * @param entity: string
     * @param callback function
     * @param urlComponents: string[]
     */
    public getEntityWithCallback(entity: string, callback: any, ...urlComponents: string[]): Observable<Entity> {
        return this.resource.getOne(entity, ...urlComponents).pipe(map(response => callback(response)));
    }

    /**
     * getEntity starts a http request, and is supposed to obtain a 'entity' object instance,
     * no matter the Rest API route represented by entity/urlComponents[0]/urlComponents[1]/etc..
     * @param entity: string
     * @param urlComponents: string[]
     */
    public getEntity(entity: string, ...urlComponents: string[]): Observable<Entity> {
        const callback = this.determineCallbackToBuildEntity(entity);
        return this.getEntityWithCallback(entity, callback, ...urlComponents);
    }

    /**
     * determineCallbackToBuildEntities will build an 'entity' based of server response
     * @param entity: string
     */
    public determineCallbackToBuildEntity(entity: string) {
        let callback = null;
        switch (entity) {
            case 'agences':
                callback = (item): Entity => {
                    return new Agence(item);
                };
                break;
            case 'directionregionales':
                callback = (item): Entity => {
                    return new Directionregionale(item);
                };
                break;
            case 'groupeagences':
                callback = (item): Entity => {
                    return this.buildGroupeagences(item);
                };
                break;
            case 'entreprises':
                callback = (item): Entity => {
                    return new Entreprise(item);
                };
                break;
            case 'utilisateurs':
                callback = (item): Entity => {
                    return new Utilisateur(item);
                };
                break;
            case 'profils':
                callback = (item): Entity => {
                    return new Profil(item);
                };
                break;
            case 'authtoken':
                callback = (item): Entity => {
                    return new Authtoken(item);
                };
                break;
            case 'clients':
                callback = (item): Entity => {
                    return new Client(item);
                };
                break;
            case 'docpersomodeles':
                callback = (item): Entity => {
                    return new Docpersomodele(item);
                };
                break;
            case 'modules':
                callback = (item): Entity => {
                    return new Module(item);
                };
                break;
            case 'dossiers':
                callback = (item): Entity => {
                    return new Dossier(item);
                };
                break;
            case 'mailhistorique':
                callback = (item): Entity => {
                    return new MailHistorique(item);
                };
                break;
            case 'nouveautes':
                callback = (item): Entity => {
                    return new Nouveautes(item);
                };
                break;
            case 'typeapporteur':
                callback = (item): Entity => {
                    return new Typeapporteur(item);
                };
                break;
            case 'groupeapporteur':
                callback = (item): Entity => {
                    return new Groupeapporteur(item);
                };
                break;
            case 'classeapporteur':
                callback = (item): Entity => {
                    return new Classeapporteur(item);
                };
                break;
            case 'utilisateurapikey':
                callback = (item): Entity => {
                    return new Utilisateurapikey(item);
                };
                break;
            case 'contrats':
                callback = (item): Entity => {
                    return new Contrat(item);
                };
                break;
            case 'template':
                callback = (item): Entity => {
                    return new Template(item);
                };
                break;
            case 'aides':
                callback = (item): Entity => {
                    return new Aide(item);
                };
                break;
            case 'motscles':
                callback = (item): Entity => {
                    return new Motcle(item);
                };
                break;
            case 'pipelines':
                callback = (item): Entity => {
                    return this.buildPipeline(item);
                };
                break;
            case 'provenance':
                callback = (item): Entity => {
                    return new Provenance(item);
                };
                break;
            case 'onglet':
                callback = (item): Entity => {
                    return this.buildOnglet(item);
                };
                break;
            case 'stat':
                callback = (item): Entity => {
                    return this.buildStat(item);
                };
                break;
            case 'option':
                callback = (item): Entity => {
                    return new Option(item);
                };
                break;
            default:
                throw new Error('The entity ' + entity + ' does not exists.');
        }
        return callback;
    }

    public postEntity(obj: object, entity: string, ...urlComponents: string[]): Observable<Entity> {
        return this.resource.post(obj, entity, ...urlComponents);
    }

    public putEntity(obj: object, entity: string, ...urlComponents: string[]): Observable<Entity> {
        return this.resource.put(obj, entity, ...urlComponents);
    }

    public delEntity(entity: string, ...urlComponents: string[]): Observable<Entity> {
        return this.resource.delete(entity, ...urlComponents);
    }

    // COMPLEXE ITEM BUILDER

    public buildPipeline(item) {
        const pipeline = new Pipeline(item);
        if (item !== null && item.hasOwnProperty('etapes')) {
            for (const etape of item.etapes) {
                pipeline.etapes.push(this.buildEtape(etape));
            }
        }
        return pipeline;
    }

    public buildEtape(item) {
        const etape = new Etape(item);
        if (item !== null && item.hasOwnProperty('verifications')) {
            for (const verification of item.verifications) {
                etape.verifications.push(new Verification(verification));
            }
        }
        return etape;
    }

    public buildFacture(item) {
        const facture = new Facture(item);
        if (item !== null && item.hasOwnProperty('reglements')) {
            for (const reglement of item.reglements) {
                facture.reglements.push(new Facturereglement(reglement));
            }
        }
        return facture;
    }

    public buildOnglet(item) {
        const onglet = new Onglet(item);
        for (const elem of item.stats) {
            onglet.stats.push(this.buildStat(elem));
        }
        return onglet;
    }

    public buildStat(item) {
        const stat = new Stat(item);
        for (const elem of item.options) {
            stat.options.push(new Option(elem));
        }
        return stat;
    }

    public buildGroupeagences(item) {
        const groupe = new Groupeagences(item);
        if (item !== null && item.hasOwnProperty('agencesgroupeagences')) {
            for (const agencesgroupeagence of item.agencesgroupeagences) {
                groupe.agencesgroupeagences.push(new Agencegroupeagences(agencesgroupeagence));
            }
        }
        return groupe;
    }

    public buildRistourne(item) {
        const ristourne = item === null ? new Ristourne() : new Ristourne(item);
        if (item !== null && item.hasOwnProperty('reglements')) {
            for (const reglement of item.reglements) {
                ristourne.reglements.push(new Ristournereglement(reglement));
            }
        }
        return ristourne;
    }

    public buildDossier(item) {
        const ristourne = this.buildRistourne(item.idristourne);
        const dossier = new Dossier(item);
        if (item !== null && item.hasOwnProperty('factures')) {
            for (const facture of item.factures) {
                dossier.factures.push(this.buildFacture(facture));
            }
        }
        dossier.idristourne = ristourne;
        return dossier;
    }
}
