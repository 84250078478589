import {Entity} from '../entity.interface';
import {Pipeline} from './pipeline.model';
import {Verification} from './verification.model';

export class Etape implements Entity {
    idetape: number;
    idpipeline: Pipeline;
    libelle: string;
    avancement: number;
    numordre: number;
    betapefacturable: boolean;

    // Virtuelles
    verifications: Verification[];
    islock: boolean;
    isgroup: boolean;
    sousetapes: Etape[];
    hasmoved: boolean;

    constructor({idetape = null, idpipeline = null, libelle = null, avancement = null, numordre = null, betapefacturable = null, hasmoved = null} = {}) {
        this.idetape = idetape;
        this.idpipeline = idpipeline !== null ? new Pipeline(idpipeline) : new Pipeline();
        this.libelle = libelle;
        this.avancement = avancement;
        this.numordre = numordre;
        this.betapefacturable = betapefacturable;
        this.islock = false;
        this.sousetapes = [];
        this.verifications = [];
        this.hasmoved = hasmoved;
    }

}
