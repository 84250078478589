import {Entity} from '../entity.interface';
import {Contact} from '../utilisateur/contact/contact.model';
import {Utilisateur} from '../utilisateur/utilisateur/utilisateur.model';

export class MailHistorique implements Entity {
    idmailhistorique: number;
    html: string;
    destinataire: Contact;
    type: string;
    dateenvoi: Date;
    bouvert: boolean;
    bactif: boolean;
    dateouverture: Date;
    expediteur: Utilisateur;

    constructor({
                    idmailhistorique = null,
                    html = null,
                    destinataire = null,
                    type = null,
                    dateenvoi = null,
                    bouvert = null,
                    bactif = null,
                    dateouverture = null,
                    expediteur = null
                } = {}) {
        this.idmailhistorique = idmailhistorique;
        this.html = html;
        this.destinataire = (destinataire) ? destinataire : new Contact();
        this.type = type;
        this.dateenvoi = dateenvoi;
        this.bouvert = bouvert;
        this.bactif = bactif;
        this.dateouverture = dateouverture;
        this.expediteur = (expediteur) ? expediteur : new Utilisateur();
    }
}
