import {Entity} from '../entity.interface';
import {Contact} from '../utilisateur/contact/contact.model';
import {Agence} from '../entite/agence/agence.model';
import {Groupeapporteur} from "./groupeapporteur.model";

export class Apporteuraffaire implements Entity {
    typecommission: string;
    pourcentagecommission: string;
    valeurcommission: number;
    plafondcommission: number;
    delaisreglement: number;
    idapporteuraffaire: number;
    idcontact: Contact;
    idagence: Agence;
    seuilcommission: number;
    typeapporteur: string;
    suffixelien: string;
    idgroupe: Groupeapporteur;

    constructor({idapporteuraffaire = null, typecommission = null, pourcentagecommission = null, valeurcommission = null, plafondcommission = null,
                 delaisreglement = null, idcontact = null, idagence = null,
                 seuilcommission = null, typeapporteur = null, suffixelien = null, idgroupe = null} = {}) {
        this.typecommission = typecommission;
        this.pourcentagecommission = pourcentagecommission;
        this.valeurcommission = valeurcommission;
        this.plafondcommission = plafondcommission;
        this.delaisreglement = delaisreglement;
        this.idapporteuraffaire = idapporteuraffaire;
        this.idcontact = idcontact !== null ? new Contact(idcontact) : new Contact();
        this.idagence = idagence !== null ? new Agence(idagence) : new Agence();
        this.seuilcommission = seuilcommission;
        this.typeapporteur = typeapporteur;
        this.suffixelien = suffixelien;
        this.idgroupe = idgroupe;
    }
}
