import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {LocalStorageService} from '../services/local/local-storage.service';
import {EnvService} from '../services/rest/env.service';
import {SessionService} from '../services/local/session.service';
import * as AuthtokenActions from '../entities/authtoken.action';
import {AppState} from '../../../../app.state';
import {Store} from '@ngrx/store';

@Injectable()

export class VerifResolver implements Resolve<boolean> {
    constructor(private local: LocalStorageService, private env: EnvService, private session: SessionService, private store: Store<AppState>) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
        // console.log('RESOLVING VERIF...');
        const authtoken = this.local.getCEntity(`${this.env.getTypeUtilisateur()}_auth_token_${this.env.getTokenEnvironment()}`);
        return new Promise((resolve, reject) => {
            if (authtoken) {
                resolve(true);
            } else {
                this.session.logout();
                resolve(false);
            }
        });
    }
}
