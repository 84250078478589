import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import { SessionService } from '../services/local/session.service';
import {ViewmodeService} from '../services/utility/viewmode.service';

@Directive({
    selector: '[droit]'
})
export class DroitDirective implements OnInit {

    @Input() droit: string[];

    constructor(private session: SessionService, private el: ElementRef, private viewmode: ViewmodeService) {
    }

    ngOnInit(): void {
        if (!this.viewmode.checkDroit(this.droit)) {
            this.el.nativeElement.remove();
        }
    }

}
