import {Entity} from '../../entity.interface';
import {Agence} from '../agence/agence.model';
import {Agencegroupeagences} from '../agencegroupeagences/agencegroupeagences.model';
import {Client} from '../client/client.model';

export class Groupeagences implements Entity {
    nom: string;
    idgroupeagences: number;
    agencesgroupeagences: Agencegroupeagences[];
    idclient: Client;
    nbutilisateurslies: Client;
    nbagences: number;

    constructor({nom = null, idgroupeagences = null, idclient = null, nbutilisateurslies = null, nbagences = null} = {}) {
        this.agencesgroupeagences = [];
        this.nom = nom;
        this.idgroupeagences = idgroupeagences;
        this.idclient = (idclient === null ? new Client() : new Client(idclient));
        this.nbutilisateurslies = nbutilisateurslies;
        this.nbagences = nbagences;
    }
}
