import {Entity} from '../../modules/shared/config-module/entities/entity.interface';

export class Category implements Entity {
    id: number;
    label: string;

    constructor({id = null, label = null} = {}) {
        this.id = id;
        this.label = label;
    }
}
