import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
/**
 * Classe destinée à contenir tout ce qui concerne l'environnement (dev, beta, hotfix, app (prod))
 * Permet de récupérer l'environnement courant à partir du sous-domaine.
 * L'environnement est le sous-domaine sauf lorsque celui-ci n'est pas un nom d'environnement connu ('dev', 'beta', 'hotfix' pour Créditéo OU 'dev', 'recette' poyr MyManci).
 * Dans ce cas, on considère qu'il s'agit de la prod soit 'app'.
 */
export class EnvService {

    constructor() {
    }

    // ------------ CONSTRUCTION DU TOKEN

    getTokenEnvironment(): string {
        const sub = window.location.host.split('.')[0];
        return ['dev', 'beta', 'hotfix', 'recette', 'app'].includes(sub) ? sub : 'app';
    }

    isProd(): boolean {
        const sub = window.location.host.split('.')[0];
        return ['dev', 'beta', 'hotfix', 'recette'].includes(sub) ? false : true;
    }

    getTypeUtilisateur(): string {
        const sub = window.location.host.split('.');
        let refsub = 0;
        if (['dev', 'beta', 'hotfix', 'recette'].includes(sub[0])) {
            refsub = 1;
        }
        switch (sub[refsub]) {
            case 'statistiques':
            case 'parametrage':
            case 'retrocession':
            case 'support':
            case 'app':
                return 'crediteo';
            case 'emprunteur':
                return 'emprunteur';
            case 'banque':
                return 'banque';
            case 'controle':
            case 'ecran':
                return 'controle';
            case 'apporteur':
                return 'apporteur';
            default:
                return 'crediteo';
        }
    }

    // ------------- IDENTIFICATION DE L'APP

    getNomApp() {
        return this.getDomain() === 'premista.tech' ? 'MyManci' : 'Créditéo';
    }

    getDomain() {
        const sub = window.location.host.split('.');
        return `${sub[sub.length - 2]}.${sub[sub.length - 1]}`;
    }

    getTrueDomain() {
        return this.getDomain() === 'premista.tech' ? 'mymanci.premista.tech' : this.getDomain();
    }

    // -------------- CONSTRUCTION DES URLS VERS CREDITEO, INTERNES OU EXTERNES

    getProtocol() {
        return window.location.protocol;
    }

    getEnvironment(espace = ''): string {
        const sub = window.location.host.split('.')[0];
        if (this.getNomApp() === 'Créditéo') {
            return ['dev', 'beta', 'hotfix'].includes(sub) ?
                (espace === '' ? sub : sub + '.' + espace) :
                espace === '' ? 'app' : espace;
        } else {
            const espaceCas1 = espace;
            let espaceCas2 = espace;
            if (espaceCas2 !== '') {
                espaceCas2 = espaceCas2 + '.';
            }
            return ['dev', 'recette', 'beta'].includes(sub) ?
                (espaceCas1 === '' ? sub + '.mymanci' : sub + '.' + espaceCas1 + '.mymanci') :
                    espaceCas2 + 'mymanci';
        }
    }

    /**
     * URL UTILISÉE A DES FINS D'AFFICHAGE
     */
    getUrl(espace: string = '') {
        return `${this.getEnvironment(espace)}.${this.getDomain()}`;
    }

    /**
     * URL COMPLETE VERS LE BACKEND DE CET ESPACE
     */
    getCompleteUrl(espace: string = '') {
        return `${this.getProtocol()}//${this.getEnvironment(espace)}.${this.getDomain()}`;
    }

    /**
     * URL COMPLETE VERS LE BACKEND 'APP'
     */
    getCompleteAppUrl() {
        return `${this.getCompleteUrl('')}/rest/rest.php`;
    }

}
