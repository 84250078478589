import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {LocalStorageService} from '../services/local/local-storage.service';
import {EnvService} from '../services/rest/env.service';
import {ResourceService} from '../services/rest/resource.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {SessionService} from '../services/local/session.service';

@Injectable()

export class AdminResolver implements Resolve<boolean> {
    constructor(private store: Store<AppState>, private router: Router, private local: LocalStorageService, private session: SessionService,
                private env: EnvService, private resource: ResourceService, private actions: Actions) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
        const token = route.params['token'];
        return new Promise((resolve, reject) => {
            if (token !== null) {
                this.local.setCItem(`crediteo_auth_token_${this.env.getTokenEnvironment()}`, token);
                this.router.navigate(['/reseauetentreprises', 'reseau']);
                // Reset de l'entreprise selectionnée dans pipeline
                this.local.deleteItem('entreprisesel');
                resolve(true);
            } else {
                this.router.navigate(['/connexion']);
                resolve(false);
            }
        });
    }
}
