import {Entity} from '../entity.interface';

export class Template implements Entity {
    idtemplate: number;
    html: string;
    type: string;

    constructor({
                    idtemplate = null,
                    html = null,
                    type = null
                } = {}) {
        this.idtemplate = idtemplate;
        this.html = html;
        this.type = type;
    }
}
