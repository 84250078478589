export class Factureinfosdossier {

    idfactureinfosdossier: number;
    montantfinancement: number;
    datedeblocagefond: Date;
    numerodossier: string;
    traitepar: string;
    portepar: string;
    agencebancaire: string;
    commission: number;
    retrocommissions: number;
    honorairesbruts: number;
    tva: number;
    total: number;
    raisonsociale: string;
    siret: string;

    constructor({idfactureinfosdossier = null, montantfinancement = null, datedeblocagefond = null,
                    numerodossier = null, traitepar = null, portepar = null, agencebancaire = null,
                    commission = null, retrocommissions = null, honorairesbruts = null, tva = null,
                    total = null, raisonsociale = null, siret = null} = {}) {
        this.idfactureinfosdossier = idfactureinfosdossier;
        this.montantfinancement = montantfinancement;
        this.datedeblocagefond = datedeblocagefond;
        this.numerodossier = numerodossier;
        this.traitepar = traitepar;
        this.portepar = portepar;
        this.agencebancaire = agencebancaire;
        this.commission = commission;
        this.retrocommissions = retrocommissions;
        this.honorairesbruts = honorairesbruts;
        this.tva = tva;
        this.total = total;
        this.raisonsociale = raisonsociale;
        this.siret = siret;
    }

}


