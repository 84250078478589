import {Utilisateur} from '../../utilisateur/utilisateur/utilisateur.model';

export class Poleimmobilier {

    idpoleimmobilier: number;
    nom: string;
    adresse: string;
    codepostal: string;
    ville: string;
    commissionbanqueprospect: number;
    commissionbanqueclient: number;
    commissionplafondclient: number;
    delaisreglement: number;
    commentairesenvoiprescription: string;
    fraisdossier: number;
    bactif: boolean;
    // idutilisateur: Utilisateur;
    nompole: string;
    adressepole: string;
    codepostalpole: string;
    villepole: string;
    responsablepole: string;
    mentionpole: string;
    bpolefacturation: boolean;
    commissionbanqueptzclient: number;
    commissionplafondprospect: number;
    commissionbanqueptzprospect: number;

    constructor({idpoleimmobilier = null, nom = null, adresse = null, codepostal = null, ville = null,
                commissionbanqueprospect = null, commissionbanqueclient = null, commissionplafondclient = null,
                delaisreglement = null, commentairesenvoiprescription = null, fraisdossier = null, bactif = null,
                nompole = null, adressepole = null, codepostalpole = null, villepole = null,
                responsablepole = null, mentionpole = null, bpolefacturation = null, commissionbanqueptzclient = null,
                commissionplafondprospect = null, commissionbanqueptzprospect = null} = {}) {
        this.idpoleimmobilier = idpoleimmobilier;
        this.nom = nom;
        this.adresse = adresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.commissionbanqueprospect = commissionbanqueprospect;
        this.commissionbanqueclient = commissionbanqueclient;
        this.commissionplafondclient = commissionplafondclient;
        this.delaisreglement = delaisreglement;
        this.commentairesenvoiprescription = commentairesenvoiprescription;
        this.fraisdossier = fraisdossier;
        this.bactif = bactif;
        // this.idutilisateur = idutilisateur !== null ? new Utilisateur(idutilisateur) : new Utilisateur();
        this.nompole = nompole;
        this.adressepole = adressepole;
        this.codepostalpole = codepostalpole;
        this.villepole = villepole;
        this.responsablepole = responsablepole;
        this.mentionpole = mentionpole;
        this.bpolefacturation = bpolefacturation;
        this.commissionbanqueptzclient = commissionbanqueptzclient;
        this.commissionplafondprospect = commissionplafondprospect;
        this.commissionbanqueptzprospect = commissionbanqueptzprospect;
    }
}


