import {Injectable} from '@angular/core';
import {ResourceService} from '../modules/shared/config-module/services/rest/resource.service';

@Injectable({
    providedIn: 'root'
})
export class CommentairesService {

    constructor(private resource: ResourceService) {
    }

    post(commentaires) {
        return this.resource.post( commentaires, 'commentaires');
    }
}
