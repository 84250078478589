import {Injectable} from '@angular/core';
import FileSaver from 'file-saver/dist/FileSaver';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor() {
    }

    downloadB64(data, nom, type) {
        if (!data.includes('data:')) {
            switch (type) {
                case 'pdf':
                    data = 'data:application/pdf;base64,' + data;
                    break;
                case 'xls':
                    data = 'data:application/vnd.ms-excel;base64,' + data;
                    break;
                case 'xml':
                    data = 'data:application/xml;base64,' + data;
                    break;
                default:
                    return;
            }
        }
        FileSaver.saveAs(data, nom);
    }

    openTabB64(data, type) {
        window.open(URL.createObjectURL(this.dataURItoBlob(data, type)));
    }

    dataURItoBlob(data, type) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(data);

        // separate out the mime component
        const mimeString = type;

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const bb = new Blob([ab], {type: mimeString});
        return bb;
    }


    exportToCsv(filename: string, rows: object[]) {
        if (!rows || !rows.length) {
            return;
        }
        const separator = ',';
        const keys = Object.keys(rows[0]);
        const csvContent =
            keys.join(separator) +
            '\n' +
            rows.map(row => {
                return keys.map(k => {
                    if (k === 'datenaissance') {
                        row[k] = new Date(row[k]);
                    }
                    let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                    cell = cell instanceof Date
                        ? cell.toLocaleDateString()
                        : cell.toString().replace(/"/g, '""');
                    if (cell.search(/([",\n])/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');

        const blob = new Blob([csvContent], {type: 'text/csv;charset=UTF-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link.remove();
            }
        }
    }

    openTabDataURI(datawithtype) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(datawithtype.split(',')[1]);

        // separate out the mime component
        const mimeString = datawithtype.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const bb = new Blob([ab], {type: mimeString});
        window.open(URL.createObjectURL(bb));
    }
}
