import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Issue} from './issue.model';
import * as IssueActions from './issue.action';
import * as TrashActions from '../../modules/shared/config-module/entities/trash.action';
import {of} from 'rxjs';
import {EntityService} from '../../modules/shared/config-module/services/rest/entity.service';
import {ParseService} from '../../modules/shared/config-module/services/utility/parse.service';
import {Liste} from '../../modules/shared/config-module/entities/liste.model';
import {Entity} from '../../modules/shared/config-module/entities/entity.interface';

@Injectable()
export class IssueEffects {

    issuescallback = (items): Entity[] => {
        const issues = [];
        for (const item of items) {
            issues.push(new Issue(item));
        }
        return issues;
    }

    issuecallback = (item): Entity => {
        return new Issue(item);
    }

    createIssue$ = createEffect(() => this.actions$.pipe(
        ofType(IssueActions.CREATE_ISSUE),
        switchMap((action: IssueActions.CreateIssue) => this.entity.postEntity(this.parse.entity(action.payload), 'issues')
            .pipe(map((response: Issue) => ({
                type: IssueActions.CREATE_ISSUE_SUCCESS,
                payload: response
            })), catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    getIssues$ = createEffect(() => this.actions$.pipe(
        ofType(IssueActions.GET_ISSUES),
        switchMap((action: IssueActions.GetIssues) => this.entity.getEntitiesByFiltresInParams(action.payload, this.issuescallback, true, 'issues')
            .pipe(map((response: Liste) => ({
                type: IssueActions.GET_ISSUES_SUCCESS,
                payload: response
            })), catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    getIssue$ = createEffect(() => this.actions$.pipe(
        ofType(IssueActions.GET_ISSUE),
        switchMap((action: IssueActions.GetIssue) => this.entity.getEntityWithCallback('issues', this.issuecallback, action.payload.id.toString())
            .pipe(map((response: Issue) => ({
                type: IssueActions.GET_ISSUE_SUCCESS,
                payload: response
            })), catchError(() => of({type: TrashActions.TRASH_ACTION}))))));

    constructor(private actions$: Actions, private entity: EntityService, private parse: ParseService) {
    }
}
