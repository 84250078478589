import {Entity} from '../../entity.interface';

export class Role implements Entity {
    idrole: number;
    nom: string;

    constructor({idrole = null, nom = null} = {}) {
        this.idrole = idrole;
        this.nom = nom;
    }
}
