import {Entity} from '../entity.interface';
import {Client} from '../entite/client/client.model';

export class Classeapporteur implements Entity {
    idclasseapporteur: number;
    idclient: Client;
    nom: string;
    nbgroupe: number;
    bactive: boolean;

    constructor({
        idclasseapporteur = null,
        idclient = null,
        nom = null,
		bactive = null,
                    nbgroupe = null
    } = {}) {
        this.idclasseapporteur = idclasseapporteur;
        this.idclient = (idclient === null) ? new Client() : new Client(idclient);
        this.nom = nom;
        this.nbgroupe = nbgroupe;
        this.bactive = bactive;
    }
}
