import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {EffectsModule} from '@ngrx/effects';
import {ConfigModule} from './modules/shared/config-module/config.module';
import {ErrorHandlerService} from './modules/shared/config-module/services/utility/error-handler.service';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AuthtokenReducer} from './modules/shared/config-module/entities/authtoken.reducer';
import {AuthtokenEffect} from './modules/shared/config-module/entities/authtoken.effect';
import {LoginComponent} from './components/login/login.component';
import { EspaceSupportComponent } from './components/espace-support/espace-support.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { CharteComponent } from './components/charte/charte.component';
import { NouveauComponent } from './components/nouveau/nouveau.component';
import {IssueEffects} from './entities/issue/issue.effects';
import {IssueReducer} from './entities/issue/issue.reducer';
import { PipelineComponent } from './components/tickets/pipeline/pipeline.component';
import { TicketComponent } from './components/tickets/ticket/ticket.component';
import { StepComponent } from './components/tickets/pipeline/step/step.component';
import {AvatarModule} from 'ngx-avatar';
import { MatchesAttachmentsPipe } from './pipes/matches-attachments.pipe';
import { NltobrPipe } from './pipes/nltobr.pipe';
import {NouveautesEffects} from './modules/shared/config-module/entities/public/nouveautes/nouveautes.effects';
import { SidenavRemoteComponent } from './components/sidenav-remote/sidenav-remote.component';
import {IssuesTotalReducer} from './entities/issue/issuestotal.reducer';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        EspaceSupportComponent,
        TicketsComponent,
        CharteComponent,
        NouveauComponent,
        PipelineComponent,
        TicketComponent,
        StepComponent,
        MatchesAttachmentsPipe,
        NltobrPipe,
        SidenavRemoteComponent
    ],
    imports: [
        /* SHARED CONFIGURATION BETWEEN CREDITEO PROJECTS*/
        ConfigModule,
        /* STORE CONFIGURATION, DEPENDS OF THE PROJECT */
        StoreModule.forRoot({
            authtoken: AuthtokenReducer,
            issues: IssueReducer,
            issuestotal: IssuesTotalReducer
        }),
        EffectsModule.forRoot([
            AuthtokenEffect,
            IssueEffects,
            NouveautesEffects
        ]),
        // StoreDevtoolsModule.instrument({
        //     maxAge: 25 // Retains last 25 states
        // }),
        /* NAVIGATOR COMPATIBILITY MODULES */
        BrowserModule,
        BrowserAnimationsModule,
        /* ROUTING MODULES */
        AppRoutingModule,
        RouterModule,
        /* OBVIOUS MODULES */
        HttpClientModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        AvatarModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: ErrorHandlerService},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
