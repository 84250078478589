import {Entity} from '../../entity.interface';
import {Utilisateur} from "../utilisateur/utilisateur.model";

export class Utilisateurapikey implements Entity {
    idutilisateurapikey: number;
    domaine: string;
    token: string;
    idutilisateur: number;

    constructor({
                    idutilisateurapikey = null,
                    domaine = null,
                    token = null,
                    idutilisateur = null
                } = {}) {
        this.idutilisateurapikey = idutilisateurapikey;
        this.domaine = domaine;
        this.token = token;
        this.idutilisateur = idutilisateur;
    }
}
