import {Entity} from '../../entity.interface';
import {Apporteuraffaire} from '../../apporteur/apporteuraffaire.model';
import {Partagedossier} from '../../partage/partagedossier.model';
import {Emprunteurfamille} from '../../emprunteur/emprunteurfamille/emprunteurfamille.model';
import {Ristourne} from '../../facture/ristourne/ristourne.model';
import {Facture} from '../../facture/facture/facture.model';
import {Emprunteur} from '../../emprunteur/emprunteur/emprunteur.model';
import * as moment from 'moment';
import {Moment} from 'moment';

export class Dossier implements Entity {
    iddossier: number;
    idapporteuraffaire: Apporteuraffaire;
    etat: string;
    datedepot: Moment;
    datecomplet: Moment;
    motifabandon: string;
    provenance: string;
    detailsprovenance: string;
    reference: string;
    idprojet: any; // Projet
    iddossiertype: any; // Dossiertype
    isclient: boolean;
    bactif: boolean;
    methodecalculendettement: string;
    pourcentageponderationrevenu: number;
    tauxendettement: number;
    dateclient: Moment;
    bsouhaitsclient: boolean;
    couttotal: number;
    montanttotal: number;
    idsimulation: any; // Simulation
    bmasquercoordonnees: boolean;
    commentaireemprunteur: string;
    commentaireenfants: string;
    commentairesitpro: string;
    commentairepatrimoine: string;
    commentairerevenuscharges: string;
    commentairesitban: string;
    commentaireepargne: string;
    commentaireepargneautomatique: string;
    commentaireprojet: string;
    bcommentaireemprunteur: boolean;
    bcommentaireenfants: boolean;
    bcommentairesitpro: boolean;
    bcommentairepatrimoine: boolean;
    bcommentairerevenuscharges: boolean;
    bcommentairesitban: boolean;
    bcommentaireepargne: boolean;
    bcommentaireepargneautomatique: boolean;
    bcommentaireprojet: boolean;
    honorairesbanques: number;
    nomapporteur: string;
    devise: string;
    source: string;
    idristourne: Ristourne;
    partagedossiers: Partagedossier[];
    familles: Emprunteurfamille[];
    factures: Facture[];
    emprunteurprincipal: Emprunteur;

    constructor({
                    iddossier = null, idapporteuraffaire = null, etat = null, datedepot = null, datecomplet = null, motifabandon = null, provenance = null,
                    detailsprovenance = null, reference = null, idprojet = null, iddossiertype = null, isclient = null, bactif = null, methodecalculendettement = null,
                    pourcentageponderationrevenu = null, tauxendettement = null, dateclient = null, bsouhaitsclient = null, couttotal = null, montanttotal = null,
                    idsimulation = null, bmasquercoordonnees = null, commentaireemprunteur = null, commentaireenfants = null, commentairesitpro = null,
                    commentairepatrimoine = null, commentairerevenuscharges = null, commentairesitban = null, commentaireepargne = null, commentaireepargneautomatique = null, commentaireprojet = null,
                    bcommentaireemprunteur = null, bcommentaireenfants = null, bcommentairesitpro = null, bcommentairepatrimoine = null,
                    bcommentairerevenuscharges = null, bcommentairesitban = null, bcommentaireepargne = null, bcommentaireepargneautomatique = null, bcommentaireprojet = null,
                    honorairesbanques = null, nomapporteur = null, devise = null, source = null, idristourne = null, partagedossiers = [], familles = [],
                    emprunteurprincipal = null
                } = {}) {
        this.iddossier = iddossier;
        this.idapporteuraffaire = (idapporteuraffaire === null) ? new Apporteuraffaire() : new Apporteuraffaire(idapporteuraffaire);
        this.etat = etat;
        this.datedepot = datedepot !== null ? moment(new Date(datedepot)) : null;
        this.datecomplet = datecomplet !== null ? moment(new Date(datecomplet)) : null;
        this.motifabandon = motifabandon;
        this.provenance = provenance;
        this.detailsprovenance = detailsprovenance;
        this.reference = reference;
        this.idprojet = idprojet;
        this.iddossiertype = iddossiertype;
        this.isclient = isclient;
        this.bactif = bactif;
        this.methodecalculendettement = methodecalculendettement;
        this.pourcentageponderationrevenu = pourcentageponderationrevenu;
        this.tauxendettement = tauxendettement;
        this.dateclient = dateclient !== null ? moment(new Date(dateclient)) : null;
        this.bsouhaitsclient = bsouhaitsclient;
        this.couttotal = couttotal;
        this.montanttotal = montanttotal;
        this.idsimulation = idsimulation;
        this.bmasquercoordonnees = bmasquercoordonnees;
        this.commentaireemprunteur = commentaireemprunteur;
        this.commentaireenfants = commentaireenfants;
        this.commentairesitpro = commentairesitpro;
        this.commentairepatrimoine = commentairepatrimoine;
        this.commentairerevenuscharges = commentairerevenuscharges;
        this.commentairesitban = commentairesitban;
        this.commentaireepargne = commentaireepargne;
        this.commentaireepargneautomatique = commentaireepargneautomatique;
        this.commentaireprojet = commentaireprojet;
        this.bcommentaireemprunteur = bcommentaireemprunteur;
        this.bcommentaireenfants = bcommentaireenfants;
        this.bcommentairesitpro = bcommentairesitpro;
        this.bcommentairepatrimoine = bcommentairepatrimoine;
        this.bcommentairerevenuscharges = bcommentairerevenuscharges;
        this.bcommentairesitban = bcommentairesitban;
        this.bcommentaireepargne = bcommentaireepargne;
        this.bcommentaireepargneautomatique = bcommentaireepargneautomatique;
        this.bcommentaireprojet = bcommentaireprojet;
        this.honorairesbanques = honorairesbanques;
        this.nomapporteur = nomapporteur;
        this.devise = devise;
        this.source = source;
        this.factures = [];
        this.idristourne = idristourne !== null ? new Ristourne(idristourne) : new Ristourne();
        this.partagedossiers = [];
        for (const partagedossier of partagedossiers) {
            this.partagedossiers.push(new Partagedossier(partagedossier));
        }
        this.familles = [];
        for (const famille of familles) {
            this.familles.push(new Emprunteurfamille(famille));
        }
        this.emprunteurprincipal = emprunteurprincipal !== null ? new Emprunteur(emprunteurprincipal) : new Emprunteur();
    }
}
