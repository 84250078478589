import {Utilisateur} from './utilisateur/utilisateur/utilisateur.model';
import {Entity} from './entity.interface';

export class Authtoken implements Entity {
    idutilisateurtoken: number;
    idutilisateur: Utilisateur;
    token: string;
    derniereutilisation: Date;
    datecreation: Date;
    compteur: number;
    brevoque: boolean;
    brevoquepourmaj: boolean;

    constructor({idutilisateurtoken = null, idutilisateur = null, token = null, derniereutilisation = null,
                    datecreation = null, compteur = null, brevoque = null, brevoquepourmaj = null
                } = {}) {
        this.idutilisateurtoken = idutilisateurtoken;
        this.idutilisateur = idutilisateur !== null ? new Utilisateur(idutilisateur) : new Utilisateur();
        this.token = token;
        this.derniereutilisation = derniereutilisation;
        this.datecreation = datecreation;
        this.compteur = compteur;
        this.brevoque = brevoque;
        this.brevoquepourmaj = brevoquepourmaj;
    }
}
