import {Entity} from '../../entity.interface';

export class Module implements Entity {
    idmodule: number;
    nom: string;
    description: number;
    prix: string;
    couleur: string;
    pictogramme: string;
    informations: string;
    lienacces: string;
    ordre: number;
    bdemodispo: boolean;
    binclus: boolean;
    baconsommables: boolean;
    reference: string;
    bactif: boolean;
    bactifdemo: boolean;
    bdemofaite: boolean;

    constructor({
                    idmodule = null, nom = null, description = null, prix = null, couleur = null, pictogramme = null, informations = null,
                    lienacces = null, ordre = null, bdemodispo = null, binclus = null, baconsommables = null,
                    reference = null, bactif = null, bactifdemo = null, bdemofaite = null
                } = {}) {
        this.idmodule = idmodule;
        this.nom = nom;
        this.description = description;
        this.prix = prix;
        this.couleur = couleur;
        this.pictogramme = pictogramme;
        this.informations = informations;
        this.lienacces = lienacces;
        this.ordre = ordre;
        this.bdemodispo = bdemodispo;
        this.binclus = binclus;
        this.baconsommables = baconsommables;
        this.reference = reference;
        this.bactif = bactif;
        this.bactifdemo = bactifdemo;
        this.bdemofaite = bdemofaite;
    }
}
