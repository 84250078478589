import {ErrorHandler, Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Error} from '../../entities/error.model';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {EnvService} from '../rest/env.service';
import {SessionService} from '../local/session.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private errorsCollection: AngularFirestoreCollection<Error>;
    errors: Observable<Error[]>;

    constructor(private afs: AngularFirestore, private env: EnvService, private session: SessionService) {
        this.errorsCollection = afs.collection<Error>('errors');
        this.errors = this.errorsCollection.valueChanges();
    }

    handleError(err) {
        if (environment.production && this.env.getDomain() === 'ymanci.fr') {
            const usertostring = this.session.user.idutilisateur !== null ?
                this.session.user.idutilisateur + ': ' + this.session.user.idcontact.nom + ' ' + this.session.user.idcontact.prenom : 'null';
            const error = {
                message: '',
                status: '',
                location: window.location.href,
                useragent: navigator.userAgent,
                date: new Date(),
                user: usertostring
            };
            if (err instanceof HttpErrorResponse) {
                error.message = 'There was an HTTP error. ' + err.message;
                error.status = 'Status code: ' + (err as HttpErrorResponse).status;
            } else if (err instanceof TypeError) {
                error.message = 'There was a Type error. ' + err.message;
                error.status = 'No Status';
            } else if (err instanceof Error) {
                error.message = 'There was a general error. ' + err.message;
                error.status = 'No Status';
            } else {
                error.message = 'Nobody threw an error but something happened. ' + err;
                error.status = 'No Status';
            }
            this.errorsCollection.add(error).then(() => {
            });
        } else {
            console.error(err);
        }
    }
}
