import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {RefreshResolver} from './modules/shared/config-module/resolvers/refresh.resolver';
import {TicketsComponent} from './components/tickets/tickets.component';
import {EspaceSupportComponent} from './components/espace-support/espace-support.component';
import {CharteComponent} from './components/charte/charte.component';
import {NouveauComponent} from './components/nouveau/nouveau.component';
import {TicketComponent} from './components/tickets/ticket/ticket.component';
import {VerifResolver} from './modules/shared/config-module/resolvers/verif.resolver';

const routes: Routes = [
    {
        path: 'connexion',
        component: LoginComponent,
        resolve: {ignoredboolean: RefreshResolver},
        canDeactivate: []
    },
    {
        path: '',
        component: EspaceSupportComponent,
        children: [
            {
                path: '',
                component: TicketsComponent,
                resolve: {ignoredboolean: VerifResolver}
            },
            {
                path: 'u/:url',
                component: TicketsComponent,
                resolve: {ignoredboolean: VerifResolver}
            },
            {
                path: 't/:id',
                component: TicketComponent,
                resolve: {ignoredboolean: VerifResolver}
            },
            {
                path: 'nouveau',
                component: NouveauComponent,
                resolve: {ignoredboolean: VerifResolver}
            },
            {
                path: 'charte',
                component: CharteComponent,
                resolve: {ignoredboolean: VerifResolver}
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
        resolve: {ignoredboolean: RefreshResolver}
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
