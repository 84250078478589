import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Errors} from '../../modules/shared/config-module/enums/errors.enum';
import {ResourceService} from '../../modules/shared/config-module/services/rest/resource.service';
import * as AuthtokenActions from '../../modules/shared/config-module/entities/authtoken.action';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import {EnvService} from '../../modules/shared/config-module/services/rest/env.service';

@Component({
    selector: 'cre-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    authForm: FormGroup;
    enumErrors = Errors;
    nom: string;
    logosrc: string;


    constructor(private rest: ResourceService, private store: Store<AppState>, private env: EnvService) {
        switch (this.env.getDomain()) {
            case 'ymanci.fr':
                this.nom = 'Créditéo';
                this.logosrc = '/assets/images/logo.png';
                break;
            case 'premista.tech':
                this.nom = 'MyManci';
                this.logosrc = '/assets/images/logoP.png';
                break;
        }
    }

    ngOnInit() {
        this.authForm = new FormGroup({
            login: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            stayConnected: new FormControl(true, [Validators.required])
        });
    }

    handleSubmit(): void {
        this.store.dispatch(new AuthtokenActions.LoginTokenSession(this.authForm.value));
    }

}
