import {Component, Input, OnInit} from '@angular/core';
import {Issue} from '../../../entities/issue/issue.model';
import {Pipelinestep} from '../../../entities/pipeline/pipelinestep.model';

@Component({
    selector: 'cre-pipeline',
    templateUrl: './pipeline.component.html',
    styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent implements OnInit {

    @Input() steps: Pipelinestep[];
    @Input() formatTemps: string;

    constructor() {
    }

    ngOnInit() {
    }
}
