import {Entity} from '../../entity.interface';
import {Client} from '../../entite/client/client.model';

export class Profil implements Entity {
    idprofil: number;
    idclient: Client;
    libelle: string;
    description: string;
    bdossierposseder: boolean;
    bdossierchangerstatut: boolean;
    bdossierabandonner: boolean;
    bdossierreprendreabandon: boolean;
    bdossiercloturer: boolean;
    bdossiermodifiercloture: boolean;
    bdossierpasserprospectclient: boolean;
    bdossieraccederdocuments: boolean;
    bdossierajouterdocument: boolean;
    bdossierdeposerdocument: boolean;
    bdossiersupprimerdocument: boolean;
    bdossierajouterdossierdocuments: boolean;
    bdossiersupprimerdossierdocuments: boolean;
    bdossierimprimerdocument: boolean;
    bdossiertelechargerdocument: boolean;
    bdossierappliquermodeledocument: boolean;
    bdossiermodifierhonoraires: boolean;
    bdossiermodifierristourne: boolean;
    bdossiermodifierpaiementapporteur: boolean;
    bdossierdupliquer: boolean;
    bdossiermodifiernumero: boolean;
    bdossiermodifierprovenance: boolean;
    bdossiermodifierproprietaire: boolean;
    bdossiermodifierportepar: boolean;
    bdossiermodifierpourcentagerevenulocatif: boolean;
    bdossieraccedersimulation: boolean;
    bdossieraccederocr: boolean;
    bdossieraccedersuivi: boolean;
    bdossierajoutercommentaire: boolean;
    bdossiersaisirtachenonproprietaire: boolean;
    bdossiersaisirtacheautrepersonne: boolean;
    bdossierenvoyercompteemprunteur: boolean;
    bdossierenvoyercomptebanque: boolean;
    bfacturecreerhonoraires: boolean;
    bfacturecreercommissionbancaire: boolean;
    bfacturecreermandataire: boolean;
    bfacturecreerproforma: boolean;
    bfacturecreerhorsdossier: boolean;
    bfacturemodifierhonoraires: boolean;
    bfacturemodifiercommissionbancaire: boolean;
    bfacturemodifiermandataire: boolean;
    bfacturemodifierproforma: boolean;
    bfacturemodifierhorsdossier: boolean;
    bfacturevaliderhonoraires: boolean;
    bfacturevalidercommissionbancaire: boolean;
    bfacturevalidermandataire: boolean;
    bfacturevaliderproforma: boolean;
    bfacturevaliderhorsdossier: boolean;
    bfacturereglerhonoraires: boolean;
    bfacturereglercommissionbancaire: boolean;
    bfacturereglermandataire: boolean;
    bfacturereglerhorsdossier: boolean;
    bfacturesupprimerreglementhonoraires: boolean;
    bfacturesupprimerreglementcommissionbancaire: boolean;
    bfacturesupprimerreglementmandataire: boolean;
    bfacturesupprimerreglementhorsdossier: boolean;
    bfacturetelecharger: boolean;
    bfacturefaireavoirhonoraires: boolean;
    bfacturefaireavoircommissionbancaire: boolean;
    bfacturefaireavoirmandataire: boolean;
    bfacturefaireavoirhorsdossier: boolean;
    bfactureexporter: boolean;
    bcomparateuracceder: boolean;
    bcomparateursouscrire: boolean;
    bmenuaccederagenda: boolean;
    bmenuaccedermandats: boolean;
    bmenuaccedercalculatricecapaciteemprunt: boolean;
    bmenuaccedercalculatricesimulateurautonome: boolean;
    bmenuaccedercalculatricerachatpret: boolean;
    bmenuaccederprospects: boolean;
    bmenuaccederclients: boolean;
    bmenuaccederfacturation: boolean;
    bmenuaccederbanques: boolean;
    bmenuaccederapporteurs: boolean;
    bmenuaccedersupport: boolean;
    bmenuaccedernotaires: boolean;
    bstatistiquesaccederdashboard: boolean;
    bstatistiquesaccederca: boolean;
    bstatistiquesaccederpipeline: boolean;
    bstatistiquesaccedervolumedossiercommercial: boolean;
    bstatistiquesaccederbanques: boolean;
    bstatistiquesaccederrepartitiondossierbanque: boolean;
    bstatistiquesaccederapporteurs: boolean;
    bstatistiquesaccederencaissements: boolean;
    bstatistiquesaccederdossiersclient: boolean;
    bparametrageaccedermesinformations: boolean;
    bparametrageaccedermesdonneesutilisateur: boolean;
    bparametrageaccederparametres: boolean;
    bparametrageaccederentreprises: boolean;
    bparametragelireentreprise: boolean;
    bparametrageajouterentreprise: boolean;
    bparametragemodifierentreprise: boolean;
    bparametragesupprimerentreprise: boolean;
    bparametrageaccederreseaux: boolean;
    bparametragelirereseau: boolean;
    bparametragemodifierreseau: boolean;
    bparametrageaccederdirectionsregionales: boolean;
    bparametrageliredirectionregionale: boolean;
    bparametrageajouterdirectionregionale: boolean;
    bparametragemodifierdirectionregionale: boolean;
    bparametragesupprimerdirectionregionale: boolean;
    bparametrageaccedergroupesagences: boolean;
    bparametrageliregroupeagence: boolean;
    bparametrageajoutergroupeagence: boolean;
    bparametragemodifiergroupeagence: boolean;
    bparametragesupprimergroupeagence: boolean;
    bparametrageaccederagences: boolean;
    bparametragelireagence: boolean;
    bparametrageajouteragence: boolean;
    bparametragemodifieragence: boolean;
    bparametragesupprimeragence: boolean;
    bparametrageaccederutilisateurs: boolean;
    bparametragelireutilisateur: boolean;
    bparametrageajouterutilisateur: boolean;
    bparametragemodifierutilisateur: boolean;
    bparametragesupprimerutilisateur: boolean;
    bparametrageaccederprofilsutilisateurs: boolean;
    bparametrageaccederapporteurs: boolean;
    bparametragelireprofilutilisateurs: boolean;
    bparametrageajouterprofilutilisateurs: boolean;
    bparametragemodifierprofilutilisateurs: boolean;
    bparametragesupprimerprofilutilisateurs: boolean;
    bparametrageaccederpipelines: boolean;
    bparametragelirepipeline: boolean;
    bparametrageajouterpipeline: boolean;
    bparametragemodifierpipeline: boolean;
    bparametragesupprimerpipeline: boolean;
    bparametrageaccedermodelesdocumentsiobsp: boolean;
    bparametrageliremodeledocumentiobsp: boolean;
    bparametrageajoutermodeledocumentiobsp: boolean;
    bparametragemodifiermodeledocumentiobsp: boolean;
    bparametragesupprimermodeledocumentiobsp: boolean;
    bparametrageaccederautresmodelesdocuments: boolean;
    bparametragelireautremodeledocument: boolean;
    bparametrageajouterautremodeledocument: boolean;
    bparametragemodifierautremodeledocument: boolean;
    bparametragesupprimerautremodeledocument: boolean;
    bparametrageaccedergestionmodules: boolean;
    bparametrageliremodule: boolean;
    bparametrageactivermodule: boolean;
    bparametragedesactivermodule: boolean;
    bparametrageaccedermesdonneesentreprise: boolean;
    bpartenairelirepoleimmobilier: boolean;
    bpartenaireajouterpoleimmobilier: boolean;
    bpartenairemodifierpoleimmobilier: boolean;
    bpartenairesupprimerpoleimmobilier: boolean;
    bpartenairelirepoleimmobilieragence: boolean;
    bpartenaireajouterpoleimmobilieragence: boolean;
    bpartenairemodifierpoleimmobilieragence: boolean;
    bpartenairesupprimerpoleimmobilieragence: boolean;
    bpartenairelirepoleimmobiliercontact: boolean;
    bpartenaireajouterpoleimmobiliercontact: boolean;
    bpartenairemodifierpoleimmobiliercontact: boolean;
    bpartenairesupprimerpoleimmobiliercontact: boolean;
    bpartenairelireentrepriseapporteur: boolean;
    bpartenaireajouterentrepriseapporteur: boolean;
    bpartenairemodifierentrepriseapporteur: boolean;
    bpartenairesupprimerentrepriseapporteur: boolean;
    bpartenairelireagenceapporteur: boolean;
    bpartenaireajouteragenceapporteur: boolean;
    bpartenairemodifieragenceapporteur: boolean;
    bpartenairesupprimeragenceapporteur: boolean;
    bpartenairelirecontactapporteur: boolean;
    bpartenaireajoutercontactapporteur: boolean;
    bpartenairemodifiercontactapporteur: boolean;
    bpartenairesupprimercontactapporteur: boolean;
    bpartenairelirenotaireapporteur: boolean;
    bpartenaireajouternotaireapporteur: boolean;
    bpartenairemodifiernotaireapporteur: boolean;
    bpartenairesupprimernotaireapporteur: boolean;
    bpartenairelireparrainageapporteur: boolean;
    bpartenaireajouterparrainageapporteur: boolean;
    bpartenairemodifierparrainageapporteur: boolean;
    bpartenairesupprimerparrainageapporteur: boolean;
    bpartenaireenvoyerespaceapporteur: boolean;
    bapporteurgestionnational: boolean;
    perimetredossiers: boolean;
    bperimetredroitliredossier: boolean;
    bperimetredroitajouterdossier: boolean;
    bperimetredroitmodifierdossier: boolean;
    bperimetredroitsupprimerdossier: boolean;
    perimetrestatistiques: boolean;
    perimetrefacturation: boolean;
    perimetrepolesimmobiliers: boolean;
    perimetreapporteurs: boolean;
    perimetrenotaires: boolean;
    bpartagerdossiers: boolean;
    btransfererdossiers: boolean;
    bpartagerundossier: boolean;
    bpartagerdroitsfacturation: boolean;
    perimetreparametrage: number;
    perimetresupport: number;
    nbutilisations: number;
    bparametragelirenotaire: boolean;
    bparametrageajouternotaire: boolean;
    bparametragemodifiernotaire: boolean;
    bparametragesupprimernotaire: boolean;
    bparametrageliretypeapporteurs: boolean;
    bparametrageajoutertypeapporteurs: boolean;
    bparametragemodifiertypeapporteurs: boolean;
    bparametragesupprimertypeapporteurs: boolean;
    bparametrageliregroupeapporteurs: boolean;
    bparametrageajoutergroupeapporteurs: boolean;
    bparametragemodifiergroupeapporteurs: boolean;
    bparametragesupprimergroupeapporteurs: boolean;
    bparametragelireclasseapporteurs: boolean;
    bparametrageajouterclasseapporteurs: boolean;
    bparametragemodifierclasseapporteurs: boolean;
    bparametragesupprimerclasseapporteurs: boolean;
    bparametrageaccedergestionnotaires: boolean;
    bparametragelireprovenances: boolean;
    bparametrageajouterprovenances: boolean;
    bparametragemodifierprovenances: boolean;
    bparametragesupprimerprovenances: boolean;
    bfacturecreerristourne: boolean;
    bfacturefaireavoirristourne: boolean;
    bfacturemodifierristourne: boolean;
    bfacturereglerristourne: boolean;
    bfacturesupprimerreglementristourne: boolean;
    bfacturevaliderristourne: boolean;
    baccesimportmajreference: boolean;

    constructor({
                    idprofil = null,
                    idclient = null,
                    libelle = null,
                    description = null,
                    bdossierposseder = null,
                    bdossierchangerstatut = null,
                    bdossierabandonner = null,
                    bdossierreprendreabandon = null,
                    bdossiercloturer = null,
                    bdossiermodifiercloture = null,
                    bdossierpasserprospectclient = null,
                    bdossieraccederdocuments = null,
                    bdossierajouterdocument = null,
                    bdossierdeposerdocument = null,
                    bdossiersupprimerdocument = null,
                    bdossierajouterdossierdocuments = null,
                    bdossiersupprimerdossierdocuments = null,
                    bdossierimprimerdocument = null,
                    bdossiertelechargerdocument = null,
                    bdossierappliquermodeledocument = null,
                    bdossiermodifierhonoraires = null,
                    bdossiermodifierristourne = null,
                    bdossiermodifierpaiementapporteur = null,
                    bdossierdupliquer = null,
                    bdossiermodifiernumero = null,
                    bdossiermodifierprovenance = null,
                    bdossiermodifierproprietaire = null,
                    bdossiermodifierportepar = null,
                    bdossiermodifierpourcentagerevenulocatif = null,
                    bdossieraccedersimulation = null,
                    bdossieraccederocr = null,
                    bdossieraccedersuivi = null,
                    bdossierajoutercommentaire = null,
                    bdossiersaisirtachenonproprietaire = null,
                    bdossiersaisirtacheautrepersonne = null,
                    bdossierenvoyercompteemprunteur = null,
                    bdossierenvoyercomptebanque = null,
                    bfacturecreerhonoraires = null,
                    bfacturecreercommissionbancaire = null,
                    bfacturecreermandataire = null,
                    bfacturecreerproforma = null,
                    bfacturecreerhorsdossier = null,
                    bfacturemodifierhonoraires = null,
                    bfacturemodifiercommissionbancaire = null,
                    bfacturemodifiermandataire = null,
                    bfacturemodifierproforma = null,
                    bfacturemodifierhorsdossier = null,
                    bfacturevaliderhonoraires = null,
                    bfacturevalidercommissionbancaire = null,
                    bfacturevalidermandataire = null,
                    bfacturevaliderproforma = null,
                    bfacturevaliderhorsdossier = null,
                    bfacturereglerhonoraires = null,
                    bfacturereglercommissionbancaire = null,
                    bfacturereglermandataire = null,
                    bfacturereglerhorsdossier = null,
                    bfacturesupprimerreglementhonoraires = null,
                    bfacturesupprimerreglementcommissionbancaire = null,
                    bfacturesupprimerreglementmandataire = null,
                    bfacturesupprimerreglementhorsdossier = null,
                    bfacturetelecharger = null,
                    bfacturefaireavoirhonoraires = null,
                    bfacturefaireavoircommissionbancaire = null,
                    bfacturefaireavoirmandataire = null,
                    bfacturefaireavoirhorsdossier = null,
                    bfactureexporter = null,
                    bcomparateuracceder = null,
                    bcomparateursouscrire = null,
                    bmenuaccederagenda = null,
                    bmenuaccedermandats = null,
                    bmenuaccedercalculatricecapaciteemprunt = null,
                    bmenuaccedercalculatricesimulateurautonome = null,
                    bmenuaccedercalculatricerachatpret = null,
                    bmenuaccederprospects = null,
                    bmenuaccederclients = null,
                    bmenuaccederfacturation = null,
                    bmenuaccederbanques = null,
                    bmenuaccederapporteurs = null,
                    bmenuaccedersupport = null,
                    bmenuaccedernotaires = null,
                    bstatistiquesaccederdashboard = null,
                    bstatistiquesaccederca = null,
                    bstatistiquesaccederpipeline = null,
                    bstatistiquesaccedervolumedossiercommercial = null,
                    bstatistiquesaccederbanques = null,
                    bstatistiquesaccederrepartitiondossierbanque = null,
                    bstatistiquesaccederapporteurs = null,
                    bstatistiquesaccederencaissements = null,
                    bstatistiquesaccederdossiersclient = null,
                    bparametrageaccedermesinformations = null,
                    bparametrageaccedermesdonneesutilisateur = null,
                    bparametrageaccederparametres = null,
                    bparametrageaccederentreprises = null,
                    bparametragelireentreprise = null,
                    bparametrageajouterentreprise = null,
                    bparametragemodifierentreprise = null,
                    bparametragesupprimerentreprise = null,
                    bparametrageaccederreseaux = null,
                    bparametragelirereseau = null,
                    bparametragemodifierreseau = null,
                    bparametrageaccederdirectionsregionales = null,
                    bparametrageliredirectionregionale = null,
                    bparametrageajouterdirectionregionale = null,
                    bparametragemodifierdirectionregionale = null,
                    bparametragesupprimerdirectionregionale = null,
                    bparametrageaccedergroupesagences = null,
                    bparametrageliregroupeagence = null,
                    bparametrageajoutergroupeagence = null,
                    bparametragemodifiergroupeagence = null,
                    bparametragesupprimergroupeagence = null,
                    bparametrageaccederagences = null,
                    bparametragelireagence = null,
                    bparametrageajouteragence = null,
                    bparametragemodifieragence = null,
                    bparametragesupprimeragence = null,
                    bparametrageaccederutilisateurs = null,
                    bparametragelireutilisateur = null,
                    bparametrageajouterutilisateur = null,
                    bparametragemodifierutilisateur = null,
                    bparametragesupprimerutilisateur = null,
                    bparametrageaccederprofilsutilisateurs = null,
                    bparametrageaccederapporteurs = null,
                    bparametragelireprofilutilisateurs = null,
                    bparametrageajouterprofilutilisateurs = null,
                    bparametragemodifierprofilutilisateurs = null,
                    bparametragesupprimerprofilutilisateurs = null,
                    bparametrageaccederpipelines = null,
                    bparametragelirepipeline = null,
                    bparametrageajouterpipeline = null,
                    bparametragemodifierpipeline = null,
                    bparametragesupprimerpipeline = null,
                    bparametrageaccedermodelesdocumentsiobsp = null,
                    bparametrageliremodeledocumentiobsp = null,
                    bparametrageajoutermodeledocumentiobsp = null,
                    bparametragemodifiermodeledocumentiobsp = null,
                    bparametragesupprimermodeledocumentiobsp = null,
                    bparametrageaccederautresmodelesdocuments = null,
                    bparametragelireautremodeledocument = null,
                    bparametrageajouterautremodeledocument = null,
                    bparametragemodifierautremodeledocument = null,
                    bparametragesupprimerautremodeledocument = null,
                    bparametrageaccedergestionmodules = null,
                    bparametrageliremodule = null,
                    bparametrageactivermodule = null,
                    bparametragedesactivermodule = null,
                    bparametrageaccedermesdonneesentreprise = null,
                    bpartenairelirepoleimmobilier = null,
                    bpartenaireajouterpoleimmobilier = null,
                    bpartenairemodifierpoleimmobilier = null,
                    bpartenairesupprimerpoleimmobilier = null,
                    bpartenairelirepoleimmobilieragence = null,
                    bpartenaireajouterpoleimmobilieragence = null,
                    bpartenairemodifierpoleimmobilieragence = null,
                    bpartenairesupprimerpoleimmobilieragence = null,
                    bpartenairelirepoleimmobiliercontact = null,
                    bpartenaireajouterpoleimmobiliercontact = null,
                    bpartenairemodifierpoleimmobiliercontact = null,
                    bpartenairesupprimerpoleimmobiliercontact = null,
                    bpartenairelireentrepriseapporteur = null,
                    bpartenaireajouterentrepriseapporteur = null,
                    bpartenairemodifierentrepriseapporteur = null,
                    bpartenairesupprimerentrepriseapporteur = null,
                    bpartenairelireagenceapporteur = null,
                    bpartenaireajouteragenceapporteur = null,
                    bpartenairemodifieragenceapporteur = null,
                    bpartenairesupprimeragenceapporteur = null,
                    bpartenairelirecontactapporteur = null,
                    bpartenaireajoutercontactapporteur = null,
                    bpartenairemodifiercontactapporteur = null,
                    bpartenairesupprimercontactapporteur = null,
                    bpartenairelirenotaireapporteur = null,
                    bpartenaireajouternotaireapporteur = null,
                    bpartenairemodifiernotaireapporteur = null,
                    bpartenairesupprimernotaireapporteur = null,
                    bpartenairelireparrainageapporteur = null,
                    bpartenaireajouterparrainageapporteur = null,
                    bpartenairemodifierparrainageapporteur = null,
                    bpartenairesupprimerparrainageapporteur = null,
                    bpartenaireenvoyerespaceapporteur = null,
                    bapporteurgestionnational = null,
                    perimetredossiers = null,
                    bperimetredroitliredossier = null,
                    bperimetredroitajouterdossier = null,
                    bperimetredroitmodifierdossier = null,
                    bperimetredroitsupprimerdossier = null,
                    perimetrestatistiques = null,
                    perimetrefacturation = null,
                    perimetrepolesimmobiliers = null,
                    perimetreapporteurs = null,
                    perimetrenotaires = null,
                    bpartagerdossiers = null,
                    btransfererdossiers = null,
                    bpartagerundossier = null,
                    bpartagerdroitsfacturation = null,
                    perimetreparametrage = null,
                    perimetresupport = null,
                    nbutilisations = null,
                    bparametragelirenotaire = null,
                    bparametrageajouternotaire = null,
                    bparametragemodifiernotaire = null,
                    bparametragesupprimernotaire = null,
                    bparametrageliretypeapporteurs = null,
                    bparametrageajoutertypeapporteurs = null,
                    bparametragemodifiertypeapporteurs = null,
                    bparametragesupprimertypeapporteurs = null,
                    bparametrageliregroupeapporteurs = null,
                    bparametrageajoutergroupeapporteurs = null,
                    bparametragemodifiergroupeapporteurs = null,
                    bparametragesupprimergroupeapporteurs = null,
                    bparametragelireclasseapporteurs = null,
                    bparametrageajouterclasseapporteurs = null,
                    bparametragemodifierclasseapporteurs = null,
                    bparametragesupprimerclasseapporteurs = null,
                    bparametrageaccedergestionnotaires = null,
                    bparametragelireprovenances = null,
                    bparametrageajouterprovenances = null,
                    bparametragemodifierprovenances = null,
                    bparametragesupprimerprovenances = null,
                    bfacturecreerristourne = null,
                    bfacturefaireavoirristourne = null,
                    bfacturemodifierristourne = null,
                    bfacturereglerristourne = null,
                    bfacturesupprimerreglementristourne = null,
                    bfacturevaliderristourne = null,
                    baccesimportmajreference = null,
                } = {}) {
        this.idprofil = idprofil;
        this.idclient = (idclient === null) ? new Client() : new Client(idclient);
        this.libelle = libelle;
        this.description = description;
        this.bdossierposseder = bdossierposseder;
        this.bdossierchangerstatut = bdossierchangerstatut;
        this.bdossierabandonner = bdossierabandonner;
        this.bdossierreprendreabandon = bdossierreprendreabandon;
        this.bdossiercloturer = bdossiercloturer;
        this.bdossiermodifiercloture = bdossiermodifiercloture;
        this.bdossierpasserprospectclient = bdossierpasserprospectclient;
        this.bdossieraccederdocuments = bdossieraccederdocuments;
        this.bdossierajouterdocument = bdossierajouterdocument;
        this.bdossierdeposerdocument = bdossierdeposerdocument;
        this.bdossiersupprimerdocument = bdossiersupprimerdocument;
        this.bdossierajouterdossierdocuments = bdossierajouterdossierdocuments;
        this.bdossiersupprimerdossierdocuments = bdossiersupprimerdossierdocuments;
        this.bdossierimprimerdocument = bdossierimprimerdocument;
        this.bdossiertelechargerdocument = bdossiertelechargerdocument;
        this.bdossierappliquermodeledocument = bdossierappliquermodeledocument;
        this.bdossiermodifierhonoraires = bdossiermodifierhonoraires;
        this.bdossiermodifierristourne = bdossiermodifierristourne;
        this.bdossiermodifierpaiementapporteur = bdossiermodifierpaiementapporteur;
        this.bdossierdupliquer = bdossierdupliquer;
        this.bdossiermodifiernumero = bdossiermodifiernumero;
        this.bdossiermodifierprovenance = bdossiermodifierprovenance;
        this.bdossiermodifierproprietaire = bdossiermodifierproprietaire;
        this.bdossiermodifierportepar = bdossiermodifierportepar;
        this.bdossiermodifierpourcentagerevenulocatif = bdossiermodifierpourcentagerevenulocatif;
        this.bdossieraccedersimulation = bdossieraccedersimulation;
        this.bdossieraccederocr = bdossieraccederocr;
        this.bdossieraccedersuivi = bdossieraccedersuivi;
        this.bdossierajoutercommentaire = bdossierajoutercommentaire;
        this.bdossiersaisirtachenonproprietaire = bdossiersaisirtachenonproprietaire;
        this.bdossiersaisirtacheautrepersonne = bdossiersaisirtacheautrepersonne;
        this.bdossierenvoyercompteemprunteur = bdossierenvoyercompteemprunteur;
        this.bdossierenvoyercomptebanque = bdossierenvoyercomptebanque;
        this.bfacturecreerhonoraires = bfacturecreerhonoraires;
        this.bfacturecreercommissionbancaire = bfacturecreercommissionbancaire;
        this.bfacturecreermandataire = bfacturecreermandataire;
        this.bfacturecreerproforma = bfacturecreerproforma;
        this.bfacturecreerhorsdossier = bfacturecreerhorsdossier;
        this.bfacturemodifierhonoraires = bfacturemodifierhonoraires;
        this.bfacturemodifiercommissionbancaire = bfacturemodifiercommissionbancaire;
        this.bfacturemodifiermandataire = bfacturemodifiermandataire;
        this.bfacturemodifierproforma = bfacturemodifierproforma;
        this.bfacturemodifierhorsdossier = bfacturemodifierhorsdossier;
        this.bfacturevaliderhonoraires = bfacturevaliderhonoraires;
        this.bfacturevalidercommissionbancaire = bfacturevalidercommissionbancaire;
        this.bfacturevalidermandataire = bfacturevalidermandataire;
        this.bfacturevaliderproforma = bfacturevaliderproforma;
        this.bfacturevaliderhorsdossier = bfacturevaliderhorsdossier;
        this.bfacturereglerhonoraires = bfacturereglerhonoraires;
        this.bfacturereglercommissionbancaire = bfacturereglercommissionbancaire;
        this.bfacturereglermandataire = bfacturereglermandataire;
        this.bfacturereglerhorsdossier = bfacturereglerhorsdossier;
        this.bfacturesupprimerreglementhonoraires = bfacturesupprimerreglementhonoraires;
        this.bfacturesupprimerreglementcommissionbancaire = bfacturesupprimerreglementcommissionbancaire;
        this.bfacturesupprimerreglementmandataire = bfacturesupprimerreglementmandataire;
        this.bfacturesupprimerreglementhorsdossier = bfacturesupprimerreglementhorsdossier;
        this.bfacturetelecharger = bfacturetelecharger;
        this.bfacturefaireavoirhonoraires = bfacturefaireavoirhonoraires;
        this.bfacturefaireavoircommissionbancaire = bfacturefaireavoircommissionbancaire;
        this.bfacturefaireavoirmandataire = bfacturefaireavoirmandataire;
        this.bfacturefaireavoirhorsdossier = bfacturefaireavoirhorsdossier;
        this.bfactureexporter = bfactureexporter;
        this.bcomparateuracceder = bcomparateuracceder;
        this.bcomparateursouscrire = bcomparateursouscrire;
        this.bmenuaccederagenda = bmenuaccederagenda;
        this.bmenuaccedermandats = bmenuaccedermandats;
        this.bmenuaccedercalculatricecapaciteemprunt = bmenuaccedercalculatricecapaciteemprunt;
        this.bmenuaccedercalculatricesimulateurautonome = bmenuaccedercalculatricesimulateurautonome;
        this.bmenuaccedercalculatricerachatpret = bmenuaccedercalculatricerachatpret;
        this.bmenuaccederprospects = bmenuaccederprospects;
        this.bmenuaccederclients = bmenuaccederclients;
        this.bmenuaccederfacturation = bmenuaccederfacturation;
        this.bmenuaccederbanques = bmenuaccederbanques;
        this.bmenuaccederapporteurs = bmenuaccederapporteurs;
        this.bmenuaccedersupport = bmenuaccedersupport;
        this.bmenuaccedernotaires = bmenuaccedernotaires;
        this.bstatistiquesaccederdashboard = bstatistiquesaccederdashboard;
        this.bstatistiquesaccederca = bstatistiquesaccederca;
        this.bstatistiquesaccederpipeline = bstatistiquesaccederpipeline;
        this.bstatistiquesaccedervolumedossiercommercial = bstatistiquesaccedervolumedossiercommercial;
        this.bstatistiquesaccederbanques = bstatistiquesaccederbanques;
        this.bstatistiquesaccederrepartitiondossierbanque = bstatistiquesaccederrepartitiondossierbanque;
        this.bstatistiquesaccederapporteurs = bstatistiquesaccederapporteurs;
        this.bstatistiquesaccederencaissements = bstatistiquesaccederencaissements;
        this.bstatistiquesaccederdossiersclient = bstatistiquesaccederdossiersclient;
        this.bparametrageaccedermesinformations = bparametrageaccedermesinformations;
        this.bparametrageaccedermesdonneesutilisateur = bparametrageaccedermesdonneesutilisateur;
        this.bparametrageaccederparametres = bparametrageaccederparametres;
        this.bparametrageaccederentreprises = bparametrageaccederentreprises;
        this.bparametragelireentreprise = bparametragelireentreprise;
        this.bparametrageajouterentreprise = bparametrageajouterentreprise;
        this.bparametragemodifierentreprise = bparametragemodifierentreprise;
        this.bparametragesupprimerentreprise = bparametragesupprimerentreprise;
        this.bparametrageaccederreseaux = bparametrageaccederreseaux;
        this.bparametragelirereseau = bparametragelirereseau;
        this.bparametragemodifierreseau = bparametragemodifierreseau;
        this.bparametrageaccederdirectionsregionales = bparametrageaccederdirectionsregionales;
        this.bparametrageliredirectionregionale = bparametrageliredirectionregionale;
        this.bparametrageajouterdirectionregionale = bparametrageajouterdirectionregionale;
        this.bparametragemodifierdirectionregionale = bparametragemodifierdirectionregionale;
        this.bparametragesupprimerdirectionregionale = bparametragesupprimerdirectionregionale;
        this.bparametrageaccedergroupesagences = bparametrageaccedergroupesagences;
        this.bparametrageliregroupeagence = bparametrageliregroupeagence;
        this.bparametrageajoutergroupeagence = bparametrageajoutergroupeagence;
        this.bparametragemodifiergroupeagence = bparametragemodifiergroupeagence;
        this.bparametragesupprimergroupeagence = bparametragesupprimergroupeagence;
        this.bparametrageaccederagences = bparametrageaccederagences;
        this.bparametragelireagence = bparametragelireagence;
        this.bparametrageajouteragence = bparametrageajouteragence;
        this.bparametragemodifieragence = bparametragemodifieragence;
        this.bparametragesupprimeragence = bparametragesupprimeragence;
        this.bparametrageaccederutilisateurs = bparametrageaccederutilisateurs;
        this.bparametragelireutilisateur = bparametragelireutilisateur;
        this.bparametrageajouterutilisateur = bparametrageajouterutilisateur;
        this.bparametragemodifierutilisateur = bparametragemodifierutilisateur;
        this.bparametragesupprimerutilisateur = bparametragesupprimerutilisateur;
        this.bparametrageaccederprofilsutilisateurs = bparametrageaccederprofilsutilisateurs;
        this.bparametrageaccederapporteurs = bparametrageaccederapporteurs;
        this.bparametragelireprofilutilisateurs = bparametragelireprofilutilisateurs;
        this.bparametrageajouterprofilutilisateurs = bparametrageajouterprofilutilisateurs;
        this.bparametragemodifierprofilutilisateurs = bparametragemodifierprofilutilisateurs;
        this.bparametragesupprimerprofilutilisateurs = bparametragesupprimerprofilutilisateurs;
        this.bparametrageaccederpipelines = bparametrageaccederpipelines;
        this.bparametragelirepipeline = bparametragelirepipeline;
        this.bparametrageajouterpipeline = bparametrageajouterpipeline;
        this.bparametragemodifierpipeline = bparametragemodifierpipeline;
        this.bparametragesupprimerpipeline = bparametragesupprimerpipeline;
        this.bparametrageaccedermodelesdocumentsiobsp = bparametrageaccedermodelesdocumentsiobsp;
        this.bparametrageliremodeledocumentiobsp = bparametrageliremodeledocumentiobsp;
        this.bparametrageajoutermodeledocumentiobsp = bparametrageajoutermodeledocumentiobsp;
        this.bparametragemodifiermodeledocumentiobsp = bparametragemodifiermodeledocumentiobsp;
        this.bparametragesupprimermodeledocumentiobsp = bparametragesupprimermodeledocumentiobsp;
        this.bparametrageaccederautresmodelesdocuments = bparametrageaccederautresmodelesdocuments;
        this.bparametragelireautremodeledocument = bparametragelireautremodeledocument;
        this.bparametrageajouterautremodeledocument = bparametrageajouterautremodeledocument;
        this.bparametragemodifierautremodeledocument = bparametragemodifierautremodeledocument;
        this.bparametragesupprimerautremodeledocument = bparametragesupprimerautremodeledocument;
        this.bparametrageaccedergestionmodules = bparametrageaccedergestionmodules;
        this.bparametrageliremodule = bparametrageliremodule;
        this.bparametrageactivermodule = bparametrageactivermodule;
        this.bparametragedesactivermodule = bparametragedesactivermodule;
        this.bparametrageaccedermesdonneesentreprise = bparametrageaccedermesdonneesentreprise;
        this.bpartenairelirepoleimmobilier = bpartenairelirepoleimmobilier;
        this.bpartenaireajouterpoleimmobilier = bpartenaireajouterpoleimmobilier;
        this.bpartenairemodifierpoleimmobilier = bpartenairemodifierpoleimmobilier;
        this.bpartenairesupprimerpoleimmobilier = bpartenairesupprimerpoleimmobilier;
        this.bpartenairelirepoleimmobilieragence = bpartenairelirepoleimmobilieragence;
        this.bpartenaireajouterpoleimmobilieragence = bpartenaireajouterpoleimmobilieragence;
        this.bpartenairemodifierpoleimmobilieragence = bpartenairemodifierpoleimmobilieragence;
        this.bpartenairesupprimerpoleimmobilieragence = bpartenairesupprimerpoleimmobilieragence;
        this.bpartenairelirepoleimmobiliercontact = bpartenairelirepoleimmobiliercontact;
        this.bpartenaireajouterpoleimmobiliercontact = bpartenaireajouterpoleimmobiliercontact;
        this.bpartenairemodifierpoleimmobiliercontact = bpartenairemodifierpoleimmobiliercontact;
        this.bpartenairesupprimerpoleimmobiliercontact = bpartenairesupprimerpoleimmobiliercontact;
        this.bpartenairelireentrepriseapporteur = bpartenairelireentrepriseapporteur;
        this.bpartenaireajouterentrepriseapporteur = bpartenaireajouterentrepriseapporteur;
        this.bpartenairemodifierentrepriseapporteur = bpartenairemodifierentrepriseapporteur;
        this.bpartenairesupprimerentrepriseapporteur = bpartenairesupprimerentrepriseapporteur;
        this.bpartenairelireagenceapporteur = bpartenairelireagenceapporteur;
        this.bpartenaireajouteragenceapporteur = bpartenaireajouteragenceapporteur;
        this.bpartenairemodifieragenceapporteur = bpartenairemodifieragenceapporteur;
        this.bpartenairesupprimeragenceapporteur = bpartenairesupprimeragenceapporteur;
        this.bpartenairelirecontactapporteur = bpartenairelirecontactapporteur;
        this.bpartenaireajoutercontactapporteur = bpartenaireajoutercontactapporteur;
        this.bpartenairemodifiercontactapporteur = bpartenairemodifiercontactapporteur;
        this.bpartenairesupprimercontactapporteur = bpartenairesupprimercontactapporteur;
        this.bpartenairelirenotaireapporteur = bpartenairelirenotaireapporteur;
        this.bpartenaireajouternotaireapporteur = bpartenaireajouternotaireapporteur;
        this.bpartenairemodifiernotaireapporteur = bpartenairemodifiernotaireapporteur;
        this.bpartenairesupprimernotaireapporteur = bpartenairesupprimernotaireapporteur;
        this.bpartenairelireparrainageapporteur = bpartenairelireparrainageapporteur;
        this.bpartenaireajouterparrainageapporteur = bpartenaireajouterparrainageapporteur;
        this.bpartenairemodifierparrainageapporteur = bpartenairemodifierparrainageapporteur;
        this.bpartenairesupprimerparrainageapporteur = bpartenairesupprimerparrainageapporteur;
        this.bpartenaireenvoyerespaceapporteur = bpartenaireenvoyerespaceapporteur;
        this.bapporteurgestionnational = bapporteurgestionnational;
        this.perimetredossiers = perimetredossiers;
        this.bperimetredroitliredossier = bperimetredroitliredossier;
        this.bperimetredroitajouterdossier = bperimetredroitajouterdossier;
        this.bperimetredroitmodifierdossier = bperimetredroitmodifierdossier;
        this.bperimetredroitsupprimerdossier = bperimetredroitsupprimerdossier;
        this.perimetrestatistiques = perimetrestatistiques;
        this.perimetrefacturation = perimetrefacturation;
        this.perimetrepolesimmobiliers = perimetrepolesimmobiliers;
        this.perimetreapporteurs = perimetreapporteurs;
        this.perimetrenotaires = perimetrenotaires;
        this.bpartagerdossiers = bpartagerdossiers;
        this.btransfererdossiers = btransfererdossiers;
        this.bpartagerundossier = bpartagerundossier;
        this.bpartagerdroitsfacturation = bpartagerdroitsfacturation;
        this.perimetreparametrage = perimetreparametrage;
        this.perimetresupport = perimetresupport;
        this.nbutilisations = nbutilisations;
        this.bparametragelirenotaire = bparametragelirenotaire;
        this.bparametrageajouternotaire = bparametrageajouternotaire;
        this.bparametragemodifiernotaire = bparametragemodifiernotaire;
        this.bparametragesupprimernotaire = bparametragesupprimernotaire;
        this.bparametrageliretypeapporteurs = bparametrageliretypeapporteurs;
        this.bparametrageajoutertypeapporteurs = bparametrageajoutertypeapporteurs;
        this.bparametragemodifiertypeapporteurs = bparametragemodifiertypeapporteurs;
        this.bparametragesupprimertypeapporteurs = bparametragesupprimertypeapporteurs;
        this.bparametrageliregroupeapporteurs = bparametrageliregroupeapporteurs;
        this.bparametrageajoutergroupeapporteurs = bparametrageajoutergroupeapporteurs;
        this.bparametragemodifiergroupeapporteurs = bparametragemodifiergroupeapporteurs;
        this.bparametragesupprimergroupeapporteurs = bparametragesupprimergroupeapporteurs;
        this.bparametragelireclasseapporteurs = bparametragelireclasseapporteurs;
        this.bparametrageajouterclasseapporteurs = bparametrageajouterclasseapporteurs;
        this.bparametragemodifierclasseapporteurs = bparametragemodifierclasseapporteurs;
        this.bparametragesupprimerclasseapporteurs = bparametragesupprimerclasseapporteurs;
        this.bparametrageaccedergestionnotaires = bparametrageaccedergestionnotaires;
        this.bparametragelireprovenances = bparametragelireprovenances;
        this.bparametrageajouterprovenances = bparametrageajouterprovenances;
        this.bparametragemodifierprovenances = bparametragemodifierprovenances;
        this.bparametragesupprimerprovenances = bparametragesupprimerprovenances;
        this.perimetreapporteurs = perimetreapporteurs;
        this.bfacturecreerristourne = bfacturecreerristourne;
        this.bfacturefaireavoirristourne = bfacturefaireavoirristourne;
        this.bfacturemodifierristourne = bfacturemodifierristourne;
        this.bfacturereglerristourne = bfacturereglerristourne;
        this.bfacturesupprimerreglementristourne = bfacturesupprimerreglementristourne;
        this.bfacturevaliderristourne = bfacturevaliderristourne;
        this.baccesimportmajreference = baccesimportmajreference;
    }
}
