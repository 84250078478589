import {Component, OnInit} from '@angular/core';
import {SnackbarService} from '../../modules/shared/config-module/services/utility/snackbar.service';
import {Issue} from '../../entities/issue/issue.model';
import {Store} from '@ngrx/store';
import * as IssueActions from '../../entities/issue/issue.action';
import {AppState} from '../../app.state';
import {Actions, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Category} from '../../entities/category/category.model';
import {IssueService} from '../../services/issue.service';
import {LocalStorageService} from '../../modules/shared/config-module/services/local/local-storage.service';
import {SessionService} from '../../modules/shared/config-module/services/local/session.service';
import {FormControl, FormGroup} from '@angular/forms';
import {EnvService} from "../../modules/shared/config-module/services/rest/env.service";

@Component({
    selector: 'cre-nouveau',
    templateUrl: './nouveau.component.html',
    styleUrls: ['./nouveau.component.scss']
})
export class NouveauComponent implements OnInit {

    // Questionnaire en cours de remplissage
    bencours = false;
    // Synthèse en visualisation
    bsynthese = false;

    // Liste de questions ré-utilisés
    listequestions = {
        typedemande: new Question({
            titre: 'Type de ticket.',
            type: QuestionTypes.boutons,
            options: [
                'Aide',
                'Anomalie',
                'Évolution'
            ],
            bobligatoire: true,
            bresetnecessaire: true, // Reset les questions suivantes,
            issuecol: 'category'
        }),
        partie: new Question({
            titre: 'Partie du logiciel concernée.',
            type: QuestionTypes.radio,
            options: [
                'API',
                'Apporteurs',
                'Banques',
                'Calculatrices',
                'Documents',
                'Dossiers',
                'Facturation',
                'Paramétrages',
                'Simulations',
                'Statistiques',
                'Autres/Je ne sais pas'
            ],
            bobligatoire: true,
            issuecol: 'section'
        }),
        demande: new Question({
            titre: 'Décrivez-nous votre demande.',
            type: QuestionTypes.textarea,
            bobligatoire: true
        }),
        fichiers: new Question({
            titre: 'Souhaitez-vous ajouter des fichiers ?',
            type: QuestionTypes.file,
            bobligatoire: false
        }),
        titre: new Question({
            titre: 'Titre du ticket.',
            type: QuestionTypes.text,
            bobligatoire: true,
            issuecol: 'title'
        })
    };
    // Liste de questions ré-utilisés (anomalie)
    listequestionsanomalie = {
        nomApporteur: new Question({
            titre: 'Nom apporteur. (Optionnel)',
            type: QuestionTypes.text
        }),
        nomBanque: new Question({
            titre: 'Nom banque. (Optionnel)',
            type: QuestionTypes.text
        }),
        docConcerne: new Question({
            titre: 'Document concerné. (Optionnel)',
            type: QuestionTypes.file
        }),
        refDossier: new Question({
            titre: 'Référence du dossier. (Optionnel)',
            type: QuestionTypes.text
        }),
        refFacture: new Question({
            titre: 'Référence/Intitulé facture. (Optionnel)',
            type: QuestionTypes.text
        }),
        refSimulation: new Question({
            titre: 'Référence simulation. (Optionnel)',
            type: QuestionTypes.text
        }),
    };

    // Fin commune des questions
    fincommune = new Question({
        ...this.listequestions.demande,
        suivant: new Question({
            ...this.listequestions.fichiers,
            suivant: {...this.listequestions.titre}
        })
    });

    // Objet questionnaire
    questionnaire = new Question({
        ...this.listequestions.typedemande,
        bsuivantmultiple: true,
        suivant: {
            'Aide': new Question({
                ...this.listequestions.partie,
                suivant: new Question({...this.fincommune})
            }),
            'Évolution': new Question({ // Demande d'aide
                ...this.listequestions.partie,
                suivant: new Question({...this.fincommune})
            }),
            'Anomalie': new Question({
                ...this.listequestions.partie,
                bresetnecessaire: true, // Reset les questions suivantes
                bsuivantmultiple: true,
                suivant: {
                    'API': new Question({...this.fincommune}),
                    'Apporteurs': new Question({
                        ...this.listequestionsanomalie.nomApporteur,
                        suivant: new Question({...this.fincommune})
                    }),
                    'Banques': new Question({
                        ...this.listequestionsanomalie.nomBanque,
                        suivant: new Question({...this.fincommune})
                    }),
                    'Calculatrices': new Question({...this.fincommune}),
                    'Documents': new Question({
                        ...this.listequestionsanomalie.docConcerne,
                        suivant: new Question({
                            ...this.listequestionsanomalie.refDossier,
                            suivant: new Question({...this.fincommune})
                        })
                    }),
                    'Dossiers': new Question({
                        ...this.listequestionsanomalie.refDossier,
                        suivant: new Question({...this.fincommune})
                    }),
                    'Facturation': new Question({
                        ...this.listequestionsanomalie.refFacture,
                        suivant: new Question({...this.fincommune})
                    }),
                    'Paramétrages': this.fincommune,
                    'Simulations': new Question({
                        ...this.listequestionsanomalie.refDossier,
                        suivant: new Question({
                            ...this.listequestionsanomalie.refSimulation,
                            suivant: new Question({...this.fincommune})
                        })
                    }),
                    'Statistiques': new Question({...this.fincommune}),
                    'Autres/Je ne sais pas': new Question({ // toutes les questions
                        ...this.listequestionsanomalie.refDossier,
                        suivant: new Question({
                            ...this.listequestionsanomalie.refSimulation,
                            suivant: new Question({
                                ...this.listequestionsanomalie.refFacture,
                                suivant: new Question({
                                    ...this.listequestionsanomalie.nomApporteur,
                                    suivant: new Question({
                                        ...this.listequestionsanomalie.nomBanque,
                                        suivant: new Question({
                                            ...this.listequestionsanomalie.docConcerne,
                                            suivant: new Question({...this.fincommune})
                                        })
                                    })
                                })
                            })
                        })
                    })
                }
            })
        }
    });

    // Question courante
    question: Question;

    // synthèse
    synthese: Question[] = [];

    bpremierpassage = true;
    questionDemande = null;

    informationsSupplementairesForm: FormGroup;

    public bdemandeconnexion = false;

    constructor(private snack: SnackbarService, private store: Store<AppState>, private actions: Actions, private router: Router,
                private issues: IssueService, private localStorage: LocalStorageService, public session: SessionService,
                public env: EnvService) {
        this.questionnaire.suivant['Je ne sais pas'] = new Question({...this.questionnaire.suivant['Anomalie']});
        this.informationsSupplementairesForm = new FormGroup({
            baccepteconnexionsupport: new FormControl(true)
        });
    }

    /**
     * Bouton Commencer
     * Début du questionnaire
     */
    commencer() {
        this.bencours = true;

        // Question courante = questionnaire (qui est une question)
        this.question = this.questionnaire;
    }

    ngOnInit() {
    }

    /**
     * Bouton Créer le ticket
     * On reconstruit le ticket, puis on l'envoie
     */
    send() {
        let informationsSupplementaires = {};
        if (this.bdemandeconnexion) {
            informationsSupplementaires = this.informationsSupplementairesForm.getRawValue();
        }

        const issue = new Issue({text: '', ...informationsSupplementaires});

        // contenu du ticket
        this.synthese.forEach((question) => {
            let valeur = question.valeur;
            // Si il ne s'agit pas d'une colonne interprétée
            if (!question.issuecol) {
                // Si il s'agit de fichier(s)
                if (question.type === QuestionTypes.file && question.valeur) {
                    valeur = '';
                    question.valeur.forEach((fichier) => {
                        valeur += fichier.name + ' ';
                        issue.attachments.push(fichier);
                    });
                }
            } else {
                // Il s'agit d'une colonne autre que text dans l'issue
                switch (question.issuecol) {
                    case 'category':
                        const correspondances = {
                            'Aide': 3,
                            'Évolution': 2,
                            'Anomalie': 1
                        };

                        issue.category = correspondances[question.valeur];
                        break;
                    case 'section':
                        if (question.valeur !== 'Autres/Je ne sais pas') {
                            issue.section = question.valeur;
                        }
                        break;
                    default:
                        issue[question.issuecol] = question.valeur;
                        break;
                }
            }

            // Si la question est vide on ne l'inclu pas
            if (valeur) {
                // On modifie le titre
                let titre = '';
                switch (question.titre) {
                    case this.listequestions.partie.titre:
                        titre = 'Partie';
                        break;
                    case this.listequestions.fichiers.titre:
                        titre = 'Fichiers';
                        break;
                    default:
                        titre = question.titre.includes('.') ? question.titre.split('.')[0] : question.titre;
                        break;
                }

                if (question.titre === this.listequestions.demande.titre) {
                    // Cas demande (On insère Bonjour et nom prénom de la personne)
                    issue.text += `<br><strong>Bonjour,<br><br>${valeur}<br><br>${this.session.user.idcontact.nom} ${this.session.user.idcontact.prenom}</strong><br>`;
                } else if (![this.listequestions.titre.titre, this.listequestions.fichiers.titre].includes(question.titre)) {
                    // On ne souhaite pas afficher les questions suivantes
                    issue.text += `${titre} : <strong>${valeur}</strong><br>`;
                }
            }
        });

        // Si il s'agit d'une anomalie on souhaite envoyer une possible URL de provenance de l'utilisateur
        const url = this.localStorage.getLSItem('lasturl');
        if (url) {
            issue.text += `<div class="hide-espace-support">URL de provenance de l'utilisateur :<br><a>${url}</a></div>`;
        }

        issue.useragent = navigator.userAgent;

        // Suppression de L'URL d'origine de l'utilisateur
        this.localStorage.deleteItem('lasturl');

        this.store.dispatch(new IssueActions.CreateIssue(issue));

        const killsubscribe = this.actions.pipe(ofType(IssueActions.CREATE_ISSUE_SUCCESS)).subscribe((data: any) => {
            killsubscribe.unsubscribe();
            this.issues.loadIssuesNavigation();
            this.router.navigate(['/t', data.payload.id]);
        });
    }

    /**
     * Ajout d'un fichier
     * @param changeEvent
     * @param question
     */
    async onFileChange(changeEvent, question) {
        if (!question.valeur) {
            question.valeur = [];
        }

        for (let i = 0; i < changeEvent.target.files.length; i++) {
            const data = await this.readFile(changeEvent.target.files[i]);
            question.valeur.push({
                lastModified: changeEvent.target.files[i].lastModified,
                lastModifiedDate: changeEvent.target.files[i].lastModifiedDate,
                name: changeEvent.target.files[i].name,
                size: changeEvent.target.files[i].size,
                type: changeEvent.target.files[i].type,
                data
            });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = (event: any) => {
                resolve(event.target.result);
            };
        });
    }

    removeFile(fileToRemove, question) {
        question.valeur = question.valeur.filter(file => file !== fileToRemove);
    }

    /**
     * Bouton Précédent dans le questionnaire
     */
    precedent() {
        let route = [];
        this.traceRoute(this.questionnaire, route);

        let last_question = null;
        route.forEach((question: Question) => {
            // Question actuelle trouvée donc précédente aussi
            if (question === this.question) {
                this.question = last_question;

                // Verif si il y a besoin de reset les valeurs
                if (this.question && this.question.bresetnecessaire) {
                    // On souhaite reset les questions suivantes
                    this.resetRoute(this.question.suivant[this.question.valeur]);
                }

                // Cas 1ère question
                if (!this.question) {
                    this.bencours = false;
                }
                return;
            }
            last_question = question;
        });
    }

    /**
     * Question suivante
     */
    suivant() {
        // On prends en charge le changement de question
        if (this.question.bsuivantmultiple) {
            const val = this.question.valeur;
            this.question = this.question.suivant[val];

            // VERIFICATION POUR DETERMINER SI IL S'AGIT D'UNE ANOMALIE
            // (Pour afficher ou non la checkbox de connexion au compte)
            if (this.question.titre === this.listequestions.partie.titre) {
                const correspondances = {
                    'Aide': 3,
                    'Évolution': 2,
                    'Anomalie': 1
                };
                this.bdemandeconnexion = correspondances[val] !== 2;
            }
        } else {
            this.question = this.question.suivant;
        }

        // Questionnaire terminé
        if (!this.question) {
            // Questionnaire terminé
            this.traceRoute(this.questionnaire, this.synthese);
            this.bsynthese = true;
            this.bpremierpassage = false;
        } else {
            // Sauvegarde de la question demande
            if (this.question.titre === this.listequestions.demande.titre) {
                if (this.bpremierpassage) {
                    this.questionDemande = this.question;
                }
            }

            // Pré-remplissage de la question titre à l'aide de la question demande
            if (this.question.titre === this.listequestions.titre.titre) {
                if (this.bpremierpassage) {
                    const listephrases = this.questionDemande.valeur
                        .split('\n')
                        .filter(phrase => phrase && (phrase.length > 10));


                    if (listephrases.length > 0) {
                        // Si il y a un point on split pour prendre la phase
                        const titre = listephrases[0].includes('.') ? listephrases[0].split('.')[0]
                                    : listephrases[0].includes('?') ? listephrases[0].split('?')[0]
                                    : listephrases[0];
                        this.question.valeur = this.truncate(titre, 89);
                    }
                }
            }

            // Un input text intouché laisse null en valeur, ce qui fausse l'algo de synthese
            if (this.question.valeur === null && this.question.type !== QuestionTypes.file) {
                this.question.valeur = '';
            }

            // VERIFICATION POUR DETERMINER SI IL S'AGIT D'UNE QUESTION FICHIER
            // (Et y mettre tableau vide car sinon ça fausse traceRoute())
            if (this.question.type === QuestionTypes.file && !this.question.valeur) {
                this.question.valeur = [];
            }
        }
    }

    suivantAvecVal(val) {
        this.question.valeur = val;
        this.suivant();
    }

    /**
     * Modifier une question depuis la synthèse
     * @param questionsynthese
     */
    modifierQuestion(questionsynthese: Question) {
        this.bsynthese = false;
        this.synthese = [];
        this.question = questionsynthese;

        // Les questions impliquant un changement de chemin critique doivent être réinitialisés afin de ne pas tromper traceRoute()
        if (this.question.bresetnecessaire) {
            this.resetRoute(this.question.suivant[this.question.valeur]);
        }
    }

    /**
     * Réintialise les valeurs sur les questions suivants la question en paramètre
     * @param question
     */
    private resetRoute(question: Question) {
        question.valeur = null;
        if (question.suivant) {
            if (!question.suivant.hasOwnProperty('suivant')) {
                for (const suivantKey in question.suivant) {
                    if (question.suivant.hasOwnProperty(suivantKey)) {
                        if (typeof question.suivant[suivantKey].valeur === 'string') {
                            this.resetRoute(question.suivant[suivantKey]);
                        }
                    }
                }
            } else {
                this.resetRoute(question.suivant);
            }
        }
    }

    /**
     * Push dans recap la liste des questions avec une valeur
     * @param question
     * @param recap
     */
    private traceRoute(question: Question, recap: Question[]) {
        recap.push(question);
        if (question.suivant) {
            if (!question.suivant.hasOwnProperty('suivant')) {
                for (const suivantKey in question.suivant) {
                    if (question.suivant.hasOwnProperty(suivantKey)) {
                        if (question.suivant[suivantKey].valeur !== null) {
                            this.traceRoute(question.suivant[suivantKey], recap);
                        }
                    }
                }
            } else {
                this.traceRoute(question.suivant, recap);
            }
        }
    }

    /**
     * Limite la longueur d'un texte
     *
     * @param source
     * @param size
     */
    truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }
}

export enum QuestionTypes {
    radio,
    text,
    file,
    textarea,
    boutons
}

export class Question {
    titre: string;
    type: number;
    options: any;
    valeur: any;
    suivant: any;
    bsuivantmultiple: any;
    bobligatoire: boolean;
    bresetnecessaire: boolean;
    issuecol: string;
    bfinal: boolean;

    constructor({
                    titre = null, type = null, options = null, valeur = null, suivant = null,
                    bsuivantmultiple = false, bobligatoire = false, bresetnecessaire = false,
                    issuecol = null, bfinal = false
                } = {}) {
        this.titre = titre;
        this.type = type;
        this.options = options;
        this.valeur = valeur;
        this.suivant = suivant;
        this.bsuivantmultiple = bsuivantmultiple;
        this.bobligatoire = bobligatoire;
        this.bresetnecessaire = bresetnecessaire;
        this.issuecol = issuecol;
        this.bfinal = bfinal;
    }
}
