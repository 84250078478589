import {Component, Input, OnInit} from '@angular/core';
import {Errors} from '../../enums/errors.enum';

@Component({
    selector: 'cre-input-mot-de-passe',
    templateUrl: './input-mot-de-passe.component.html',
    styleUrls: ['./input-mot-de-passe.component.scss']
})
export class InputMotDePasseComponent implements OnInit {

    @Input() passControl: any;
    bafficher = false;
    enumErrors = Errors;

    constructor() {
    }

    ngOnInit() {

    }

}
