// @ts-ignore-file
import {Injectable} from '@angular/core';
import {Entity} from '../../entities/entity.interface';
import {Facturereglement} from '../../entities/facture/facturereglement/facturereglement.model';
import {Entreprise} from '../../entities/entite/entreprise/entreprise.model';
import {Client} from '../../entities/entite/client/client.model';
import {Utilisateur} from '../../entities/utilisateur/utilisateur/utilisateur.model';
import {Agence} from '../../entities/entite/agence/agence.model';
import {isMoment} from 'moment';
import {Contrat} from '../../entities/document/contrat.model';
import {Ristourne} from '../../entities/facture/ristourne/ristourne.model';
import {Ristournereglement} from '../../entities/facture/ristournereglement/ristournereglement.model';

@Injectable({
    providedIn: 'root'
})
export class ParseService {

    constructor() {
    }

    communParse(obj: object) {
        const parsedobj = {...obj};
        for (const prop in obj) {
            if (obj[prop] !== null && typeof obj[prop] === 'string' && obj[prop].length === 0) {
                parsedobj[prop] = null;
            } else if (obj[prop] !== null && typeof obj[prop] === 'object') {
                if (isMoment(obj[prop])) {
                    if (this.isFunction(obj[prop].toISOString)) {
                        parsedobj[prop] = obj[prop].toISOString(true);
                    }
                } else {
                    parsedobj[prop] = this.entity(obj[prop]);
                }
            }
        }
        return parsedobj;
    }

    isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    entity(entity: Entity): object {
        const parsedobj = {...entity};
        for (const prop in entity) {
            const value = entity[prop];
            if (entity[prop] !== null && typeof entity[prop] === 'object') {
                if (isMoment(entity[prop])) {
                    parsedobj[prop] = this.cleanMomentDateToISOstring(value);
                }
            } else if (entity instanceof Facturereglement) {
                switch (prop) {
                    case 'montant':
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Entreprise) {
                switch (prop) {
                    case 'pourcentageponderationloyer':
                    case 'capitalsocial':
                    case 'indicefacture':
                    case 'forfaithonorairesfixe':
                    case 'challenge':
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    case 'prefixefacture':
                    case 'ribbic':
                    case 'ribiban':
                        parsedobj[prop] = this.forceUpperCase(this.cleanStr(value));
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Client) {
                switch (prop) {
                    case 'creditsignatures' :
                    case 'nbsignaturesutilisees' :
                    case 'dureevalidite' :
                    case 'encours' :
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Utilisateur) {
                switch (prop) {
                    case 'recouvrement' :
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Agence) {
                switch (prop) {
                    case 'capitalsocial' :
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    case 'ribbichonoraires':
                    case 'ribibanhonoraires':
                    case 'ribbiccommissionsbancaires':
                    case 'ribibancommissionsbancaires':
                        parsedobj[prop] = this.forceUpperCase(this.cleanStr(value));
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Ristourne) {
                switch (prop) {
                    case 'montant':
                    case 'montanttva':
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Ristournereglement) {
                switch (prop) {
                    case 'montantregle':
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    default:
                        break;
                }
            } else if (entity instanceof Contrat) {
                switch (prop) {
                    case 'prixunitaireht':
                    case 'capital':
                    case 'indicefacture':
                    case 'nblicence':
                    case 'dureevalidite':
                        parsedobj[prop] = this.cleanStrToNumber(value);
                        break;
                    case 'bic':
                    case 'iban':
                        parsedobj[prop] = this.forceUpperCase(this.cleanStr(value));
                        break;
                    default:
                        break;
                }
            }
        }
        return this.communParse(parsedobj);
    }

    cleanNumbersInObject(obj: object, keys: string[]) {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (keys.includes(prop)) {
                    obj[prop] = this.cleanStrToNumber(obj[prop]);
                } else if (typeof obj[prop] === 'object') {
                    this.cleanNumbersInObject(obj[prop], keys);
                }
            }
        }
        return obj;
    }

    unmaskObject(obj: object) {
        const parsedobj = {...obj};
        for (const prop in obj) {
            const value = obj[prop];
            parsedobj[prop] = this.cleanStr(value);
        }
        return parsedobj;
    }

    forceUpperCase(str: string) {
        return str.toUpperCase();
    }

    cleanStrToNumber(str): number {
        return typeof str === 'string' ? str.length === 0 ? null : parseFloat(this.unmask(str)) : str;
    }

    cleanStr(str) {
        return this.unmask(str);
    }

    cleanMomentDateToISOstring(date) {
        return date.toISOString(true);
    }

    unmask(text: string): string {
        if (text === null) {
            return '';
        } else if (typeof text !== 'string') {
            return text;
        }
        return text.replace(/_/g, '').replace(/€/g, '').replace(/%/g, '').replace(/ /g, '').replace(/,/g, '.');
    }

    deepCopy(obj) {
        let copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || 'object' !== typeof obj) {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = this.deepCopy(obj[attr]);
                }
            }
            return copy;
        }

        throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }
}
