import {Entity} from '../../entity.interface';
import {Client} from '../client/client.model';

export class Directionregionale implements Entity {
    nom: string;
    nomabrege: string;
    codepostal: string;
    ville: string;
    email: string;
    telfixe: string;
    adresse: string;
    iddirectionregionale: number;
    nbdossiers: number;
    nbagences: number;
    idclient: Client;

    constructor({
                    nom = null,
                    nomabrege = null,
                    codepostal = null,
                    ville = null,
                    email = null,
                    telfixe = null,
                    adresse = null,
                    iddirectionregionale = null,
                    nbdossiers = null,
                    nbagences = null,
                    idclient = null
                } = {}) {
        this.nom = nom;
        this.nomabrege = nomabrege;
        this.codepostal = codepostal;
        this.ville = ville;
        this.email = email;
        this.telfixe = telfixe;
        this.adresse = adresse;
        this.iddirectionregionale = iddirectionregionale;
        this.nbdossiers = nbdossiers;
        this.nbagences = nbagences;
        this.idclient = (idclient === null ? new Client() : new Client(idclient));
    }
}
