import {Authtoken} from './authtoken.model';
import {Action} from '@ngrx/store';

export const LOGIN_TOKENSESSION = '[TOKENSESSION] Login';
export const LOGIN_TOKENSESSION_SUCCESS = '[TOKENSESSION] Success Login';
export const REFRESH_TOKENSESSION = '[TOKENSESSION] Refresh';
export const REFRESH_TOKENSESSION_SUCCESS = '[TOKENSESSION] Success Refresh';
export const KILL_TOKENSESSION = '[TOKENSESSION] Kill';
export const KILL_TOKENSESSION_SUCCESS = '[TOKENSESSION] Success Kill';

export class LoginTokenSession implements Action {
    readonly type = LOGIN_TOKENSESSION;

    constructor(public payload: { login: string, password: string, stayConnected: boolean }) {
    }
}

export class LoginTokenSessionSuccess implements Action {
    readonly type = LOGIN_TOKENSESSION_SUCCESS;

    constructor(public payload: Authtoken) {
    }
}

export class RefreshTokenSession implements Action {
    readonly type = REFRESH_TOKENSESSION;

    constructor(public payload: Authtoken) {
    }
}

export class RefreshTokenSessionSuccess implements Action {
    readonly type = REFRESH_TOKENSESSION_SUCCESS;

    constructor(public payload: Authtoken) {
    }
}

export class KillTokenSession implements Action {
    readonly type = KILL_TOKENSESSION;

    constructor(public payload: string = 'À bientôt !') {
    }
}

export class KillTokenSessionSuccess implements Action {
    readonly type = KILL_TOKENSESSION_SUCCESS;

    constructor(public payload: string = 'À bientôt !') {
    }
}
export type Actions = LoginTokenSession | LoginTokenSessionSuccess | RefreshTokenSession | RefreshTokenSessionSuccess | KillTokenSession | KillTokenSessionSuccess;
