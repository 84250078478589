export enum Errors {
    'required' = 'Ce champ est requis',
    'isGif' = 'Format invalide.',
    'nameExists' = 'Ce nom existe déjà.',
    'isSupZero' = 'Ce champ doit être supérieur à 0.',
    'isSupOrEqualZero' = 'Ce champ doit être supérieur ou égal à 0.',
    'isInfSolde' = 'Ce champ ne peut pas dépasser le solde.',
    'isLength4' = 'Ce champ doit faire exactement 4 caractères.',
    'emailExist' = 'Cette adresse email est déjà utilisée.',
    'loginExist' = 'Cet identifiant est déjà utilisé.',
    'isTheMaskFulfilled' = 'Le format doit être respecté.',
    'isOneWord' = 'Un seul mot est autorisé.',
    'prefixeExist' = 'Ce préfixe de facturation est déjà utilisé.'
}
