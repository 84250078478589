import {Entity} from '../../entity.interface';
import {Pays} from '../../public/pays/pays.model';
import {Entreprise} from '../entreprise/entreprise.model';
import {Directionregionale} from '../directionregionale/directionregionale.model';
import {Agencegroupeagences} from '../agencegroupeagences/agencegroupeagences.model';
import {Client} from '../client/client.model';
import {Utilisateuragence} from '../../utilisateur/utilisateur/utilisateuragence.model';

export class Agence implements Entity {
    idagence: number;
    identreprise: Entreprise;
    nom: string;
    adresse: string;
    codepostal: string;
    ville: string;
    email: string;
    telfixe: string;
    telport: string;
    fax: string;
    idpays: Pays;
    bprincipale: boolean;
    bactif: boolean;
    datedesactivation: string;
    iddirectionregionale: Directionregionale;
    agencegroupeagences: Agencegroupeagences[];
    nbdossiers: number;
    idclient: Client;
    numeroagence: string;
    lienpartenairesbancaires: string;
    lienpartenairesassureurs: string;
    villercs: string;
    numerorcs: string;
    numeroorias: string;
    lieninfosgenerales: string;
    emaildelegueprotectiondonnees: string;
    bp: string;
    cedex: string;
    nbutilisateurs: number;
    nbutilisateursactifs: number;
    utilisateuragences: Utilisateuragence[];
    formejuridique: string;
    capitalsocial: string;
    ribtitulairehonoraires: string;
    ribibanhonoraires: string;
    ribbichonoraires: string;
    ribtitulairecommissionsbancaires: string;
    ribibancommissionsbancaires: string;
    ribbiccommissionsbancaires: string;
    siren: string;
    mentionslegales: string;
    incrementristourne: number;

    constructor({
                    idagence = null, identreprise = null, nom = null, adresse = null, codepostal = null, ville = null,
                    email = null, telfixe = null, telport = null, fax = null, idpays = null, bprincipale = null,
                    bactif = null, datedesactivation = null, iddirectionregionale = null, nbdossiers = null, idclient = null,
                    numeroagence = null, lienpartenairesbancaires = null, lienpartenairesassureurs = null, villercs = null,
                    numerorcs = null, numeroorias = null, lieninfosgenerales = null, emaildelegueprotectiondonnees = null,
                    bp = null, cedex = null, nbutilisateurs = null, nbutilisateursactifs = null, formejuridique = null,
                    capitalsocial = null, ribtitulairehonoraires = null, ribibanhonoraires = null, ribbichonoraires = null,
                    ribtitulairecommissionsbancaires = null, ribibancommissionsbancaires = null, ribbiccommissionsbancaires = null, siren = null,
                    mentionslegales = null, incrementristourne = null
                } = {}) {
        this.idagence = idagence;
        this.identreprise = (identreprise === null) ? new Entreprise() : new Entreprise(identreprise);
        this.nom = nom;
        this.adresse = adresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.email = email;
        this.telfixe = telfixe;
        this.telport = telport;
        this.fax = fax;
        this.idpays = (idpays === null) ? new Pays() : new Pays(idpays);
        this.bprincipale = bprincipale;
        this.bactif = bactif;
        this.datedesactivation = datedesactivation;
        this.iddirectionregionale = (iddirectionregionale === null) ? new Directionregionale() : new Directionregionale(iddirectionregionale);
        this.agencegroupeagences = [];
        this.nbdossiers = nbdossiers;
        this.idclient = (idclient === null ? new Client() : new Client(idclient));
        this.numeroagence = numeroagence;
        this.lienpartenairesbancaires = lienpartenairesbancaires;
        this.lienpartenairesassureurs = lienpartenairesassureurs;
        this.villercs = villercs;
        this.numerorcs = numerorcs;
        this.numeroorias = numeroorias;
        this.lieninfosgenerales = lieninfosgenerales;
        this.emaildelegueprotectiondonnees = emaildelegueprotectiondonnees;
        this.bp = bp;
        this.cedex = cedex;
        this.nbutilisateurs = nbutilisateurs;
        this.nbutilisateursactifs = nbutilisateursactifs;
        this.utilisateuragences = [];
        this.formejuridique = formejuridique;
        this.capitalsocial = capitalsocial;
        this.ribtitulairehonoraires = ribtitulairehonoraires;
        this.ribibanhonoraires = ribibanhonoraires;
        this.ribbichonoraires = ribbichonoraires;
        this.ribtitulairecommissionsbancaires = ribtitulairecommissionsbancaires;
        this.ribibancommissionsbancaires = ribibancommissionsbancaires;
        this.ribbiccommissionsbancaires = ribbiccommissionsbancaires;
        this.siren = siren;
        this.mentionslegales = mentionslegales;
        this.incrementristourne = incrementristourne;
    }
}
