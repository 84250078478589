import {Entity} from '../entity.interface';

export class Verifications implements Entity {
    idverifications: number;
    libelle: string;
    desc: string;
    ordreaffichage: number;

    // Virtuelles
    optionnel: boolean;
    obligatoire: boolean;

    constructor({idverifications = null, libelle = null, desc = null, ordreaffichage = null} = {}) {
        this.idverifications = idverifications;
        this.libelle = libelle;
        this.desc = desc;
        this.ordreaffichage = ordreaffichage;
        this.optionnel = false;
        this.obligatoire = false;
    }

}
