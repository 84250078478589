export class Facturedestinataire {

    idfacturedestinataire: number;
    nom: string;
    adresse: string;
    complementadresse: string;
    codepostal: string;
    ville: string;
    pays: string;
    responsablepole: string;
    mentionpole: string;

    constructor({idfacturedestinataire = null, nom = null, adresse = null, complementadresse = null,
                    codepostal = null, ville = null, pays = null, responsablepole = null, mentionpole = null} = {}) {

        this.idfacturedestinataire = idfacturedestinataire;
        this.nom = nom;
        this.adresse = adresse;
        this.complementadresse = complementadresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.pays = pays;
        this.responsablepole = responsablepole;
        this.mentionpole = mentionpole;
    }
}

