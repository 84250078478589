import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../services/utility/loading.service';

@Component({
    selector: 'cre-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    constructor(public loading: LoadingService) {
    }

    ngOnInit() {
        this.loading.hide();
    }

}
