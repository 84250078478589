import {Entity} from '../../entity.interface';
import {Moduleclient} from '../../modules/moduleclient/moduleclient.model';
import {Moment} from 'moment';
import * as moment from 'moment';

export class Client implements Entity {
    idclient: number;
    identifiant: string;
    nomclient: string;
    nomdirigeant: string;
    prenomdirigeant: string;
    adresse: string;
    codepostal: string;
    ville: string;
    telephone: string;
    telephone2: string;
    fax: string;
    email: string;
    siret: string;
    datecreation: Moment;
    datemodification: Moment;
    bsignaturelecactive: boolean;
    bapiparametrage: boolean;
    creditsignatures: number;
    nbsignaturesutilisees: number;
    encours: number;
    logo1: string;
    srclogo1: string;
    minilogo1: string;
    srcminilogo1: string;
    moduleclients: Moduleclient[] = [];
    bdemo: boolean;
    binterne: boolean;
    modelefacturation: number;
    modeledossier: number;

    constructor({
                    idclient = null, identifiant = null, nomclient = null, nomdirigeant = null, prenomdirigeant = null,
                    adresse = null, codepostal = null, ville = null, telephone = null, telephone2 = null, fax = null,
                    email = null, siret = null, datecreation = null, datemodification = null, bsignaturelecactive = null,
                    creditsignatures = null, logo1 = null, srclogo1 = null, minilogo1 = null, srcminilogo1 = null, bapiparametrage = null,
                    nbsignaturesutilisees = null, encours = null, moduleclients = [], bdemo = false,
                    binterne = false, modelefacturation = 1, modeledossier = 1
                } = {}) {
        this.idclient = idclient;
        this.identifiant = identifiant;
        this.nomclient = nomclient;
        this.nomdirigeant = nomdirigeant;
        this.prenomdirigeant = prenomdirigeant;
        this.adresse = adresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.telephone = telephone;
        this.telephone2 = telephone2;
        this.fax = fax;
        this.email = email;
        this.siret = siret;
        this.datecreation = datecreation !== null ? moment(new Date(datecreation)) : moment(new Date());
        this.datemodification = datemodification !== null ? moment(new Date(datemodification)) : moment(new Date());
        this.bsignaturelecactive = bsignaturelecactive;
        this.bapiparametrage = bapiparametrage;
        this.creditsignatures = creditsignatures;
        this.nbsignaturesutilisees = nbsignaturesutilisees;
        this.encours = encours;
        this.logo1 = logo1;
        this.srclogo1 = srclogo1;
        this.minilogo1 = minilogo1;
        this.srcminilogo1 = srcminilogo1;
        this.bdemo = bdemo;
        this.binterne = binterne;
        this.modelefacturation = modelefacturation;
        this.modeledossier = modeledossier;
        if (moduleclients) {
            for (const moduleclient of moduleclients) {
                this.moduleclients.push(moduleclient);
            }
        }
    }
}
