import {Utilisateur} from '../../utilisateur/utilisateur/utilisateur.model';
import {Emprunteur} from '../emprunteur/emprunteur.model';
import {Entity} from '../../entity.interface';

export class Emprunteurfamille implements Entity {
    idemprunteurfamille: number;
    nom: string;
    idutilisateur: Utilisateur;
    emprunteurs: Emprunteur[];

    constructor({idemprunteurfamille = null, nom = null, idutilisateur = null, emprunteurs = []} = {}) {
        this.idemprunteurfamille = idemprunteurfamille;
        this.nom = nom;
        this.idutilisateur = idutilisateur !== null ? new Utilisateur(idutilisateur) : new Utilisateur();
        this.emprunteurs = [];
        for (const emprunteur of emprunteurs) {
            this.emprunteurs.push(new Emprunteur(emprunteur));
        }
    }
}
