import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Pays} from '../../entities/public/pays/pays.model';
import {startWith, tap} from 'rxjs/operators';
import {GetListeService} from '../../services/rest/get-liste.service';

@Component({
    selector: 'cre-input-pays',
    templateUrl: './input-pays.component.html',
    styleUrls: ['./input-pays.component.scss']
})
export class InputPaysComponent implements OnInit {

    @Input() passControl: AbstractControl;

    optionsPays: Pays[] = [];

    constructor(private liste: GetListeService) {
    }

    ngOnInit() {
        this.initAutocomplete();
    }

    initAutocomplete() {
        this.passControl.valueChanges.pipe(
            startWith(this.passControl.value.nom_fr_fr),
            tap(input => {
                if (typeof input === 'string' && input !== '') {
                    this.optionsPays = this._filterPays(input);
                } else {
                    this.optionsPays = this.liste.getpays();
                }
            })).subscribe();
    }

    displayPays(pays?: Pays): string | undefined {
        return pays ? pays.nom_fr_fr : undefined;
    }

    checkCountry() {
        setTimeout(() => {
            if (this.passControl.value === null || typeof this.passControl.value !== 'object') {
                this.passControl.setValue('');
            }
        }, 100);
    }

    private _filterPays(nom: string): Pays[] {
        const tmp = nom.toLocaleLowerCase();

        return this.liste.getpays().filter(country => country.nom_fr_fr.toLocaleLowerCase().indexOf(tmp) === 0);
    }
}
