import {Component, OnInit} from '@angular/core';
import {SidenavService} from '../../services/sidenav.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
    selector: 'cre-sidenav-remote',
    templateUrl: './sidenav-remote.component.html',
    styleUrls: ['./sidenav-remote.component.scss']
})
export class SidenavRemoteComponent implements OnInit {
    mobileQuery: MediaQueryList;

    constructor(public sidenav: SidenavService, media: MediaMatcher) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
    }

    ngOnInit() {
    }

}
