import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'cre-modale-confirmation',
  templateUrl: './modale-confirmation.component.html',
  styleUrls: ['./modale-confirmation.component.scss']
})
export class ModaleConfirmationComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ModaleConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: {title, text}) { }

  false;
  ngOnInit() {
  }

}
