import {Entity} from '../../entity.interface';
import {Utilisateur} from "../utilisateur/utilisateur.model";
import {Pays} from "../../public/pays/pays.model";

export class Contact implements Entity {
    idcontact: number;
    civilite: string;
    situationfamiliale: string;
    nom: string;
    nomjeunefille: string;
    prenom: string;
    email: string;
    adresse: string;
    complementadresse: string;
    codepostal: string;
    ville: string;
    pays: Pays;
    datenaissance: Date;
    telfixe: string;
    telport: string;
    datecreation: Date;
    bactif: boolean;
    // idutilisateur: Utilisateur;
    datemiseajour: Date;
    idcontactgoogle: string;
    lienparente: string;
    villenaissance: string;
    nationalite: string;
    datemariage: Date;
    contratmariage: string;
    regimematrimonial: string;
    situationimmo: string;
    initiales: string;

    constructor({idcontact = null, civilite = null, situationfamiliale = null, nom = null, nomjeunefille = null,
                 prenom = null, email = null, adresse = null, complementadresse = null, codepostal = null,
                 ville = null, pays = null, datenaissance = null, telfixe = null, telport = null,
                 datecreation = null, bactif = null, idutilisateur = null, datemiseajour = null,
                 idcontactgoogle = null, lienparente = null, villenaissance = null, nationalite = null,
                 datemariage = null, contratmariage = null, regimematrimonial = null, situationimmo = null, initiales = null} = {}) {
        this.idcontact = idcontact;
        this.civilite = civilite;
        this.situationfamiliale = situationfamiliale;
        this.nom = nom;
        this.nomjeunefille = nomjeunefille;
        this.prenom = prenom;
        this.email = email;
        this.adresse = adresse;
        this.complementadresse = complementadresse;
        this.codepostal = codepostal;
        this.ville = ville;
        this.pays = pays !== null ? new Pays(pays) : new Pays();
        this.datenaissance = datenaissance;
        this.telfixe = telfixe;
        this.telport = telport;
        this.datecreation = datecreation;
        this.bactif = bactif;
        // this.idutilisateur = new Utilisateur(idutilisateur);
        this.datemiseajour = datemiseajour;
        this.idcontactgoogle = idcontactgoogle;
        this.lienparente = lienparente;
        this.villenaissance = villenaissance;
        this.nationalite = nationalite;
        this.datemariage = datemariage;
        this.contratmariage = contratmariage;
        this.regimematrimonial = regimematrimonial;
        this.situationimmo = situationimmo;
        this.initiales = initiales;

    }
}
