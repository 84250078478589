import {Action} from '@ngrx/store';
import {Issue} from './issue.model';
import {Liste} from '../../modules/shared/config-module/entities/liste.model';

export const GET_ISSUES = '[ISSUE] Get Filtered List';
export const GET_ISSUES_SUCCESS = '[ISSUE] Success Get Filtered List';
export const GET_ISSUE = '[ISSUE] Get';
export const GET_ISSUE_SUCCESS = '[ISSUE] Success Get';
export const CREATE_ISSUE = '[ISSUE] Create';
export const CREATE_ISSUE_SUCCESS = '[ISSUE] Success Create';

export class GetIssues implements Action {
    readonly type = GET_ISSUES;

    constructor(public payload: any) {
    }
}

export class GetIssuesSuccess implements Action {
    readonly type = GET_ISSUES_SUCCESS;

    constructor(public payload: Liste) {
    }
}

export class GetIssue implements Action {
    readonly type = GET_ISSUE;

    constructor(public payload: Issue) {
    }
}

export class GetIssueSuccess implements Action {
    readonly type = GET_ISSUE_SUCCESS;

    constructor(public payload: Issue) {
    }
}

export class CreateIssue implements Action {
    readonly type = CREATE_ISSUE;

    constructor(public payload: Issue) {
    }
}

export class CreateIssueSuccess implements Action {
    readonly type = CREATE_ISSUE_SUCCESS;

    constructor(public payload: Issue) {
    }
}

export type Actions =
    GetIssues
    | GetIssuesSuccess
    | GetIssue
    | GetIssueSuccess
    | CreateIssue
    | CreateIssueSuccess;
