import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ModaleConfirmationComponent} from '../modale-confirmation/modale-confirmation.component';
import {ModaleConfirmationPasteComponent} from './modale-confirmation-paste/modale-confirmation-paste.component';

@Component({
    selector: 'cre-paste-upload',
    templateUrl: './paste-upload.component.html',
    styleUrls: ['./paste-upload.component.scss']
})
export class PasteUploadComponent implements OnInit, OnDestroy {

    @Input() passControl: AbstractControl;
    @Input() passModel: any;

    constructor(private dialog: MatDialog) {
    }

    ngOnInit() {
        this.initPasteEvent();
    }

    ngOnDestroy() {
        document.onpaste = null;
    }

    private initPasteEvent() {
        document.onpaste = (event: any) => {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;

            // find pasted image among pasted items
            let blob = null;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') === 0) {
                    blob = items[i].getAsFile();
                }
            }

            // load image if there is a pasted image
            if (blob !== null) {
                // todo : Prévisualisation
                this.openModaleConfirmation(blob);
            }
        };
    }

    private async openModaleConfirmation(blob: any) {
        const data = await this.readFile(blob);
        const file = {
            lastModified: blob.lastModified,
            lastModifiedDate: blob.lastModifiedDate,
            name: blob.name,
            size: blob.size,
            type: blob.type,
            data
        };

        const ref = this.dialog.open(ModaleConfirmationPasteComponent, {
            data: {
                file
            }
        });

        ref.afterClosed().subscribe(result => {
            if (result === true) {
                this.pushFile(file);
            }
        });
    }

    private async pushFile(file: any) {
        if (this.passControl !== undefined) {
            let liste = this.passControl.value;
            liste = (!liste) ? [] : liste;
            liste.push(file);
            this.passControl.setValue(liste);
        } else {
            if (!Array.isArray(this.passModel)) {
                this.passModel = [];
            }
            this.passModel.push(file);
        }
    }

    private readFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = (event: any) => {
                resolve(event.target.result);
            };
        });
    }
}
