import {Entity} from '../../entity.interface';

export class Pays implements Entity {
    idpays: number;
    code: number;
    alpha2: string;
    alpha3: string;
    // tslint:disable-next-line:variable-name
    nom_en_gb: string;
    // tslint:disable-next-line:variable-name
    nom_fr_fr: string;

    constructor({idpays = null, code = null, alpha2 = null, alpha3 = null, nom_en_gb = null, nom_fr_fr = null} = {}) {
        this.idpays = idpays;
        this.code = code;
        this.alpha2 = alpha2;
        this.alpha3 = alpha3;
        this.nom_en_gb = nom_en_gb;
        this.nom_fr_fr = nom_fr_fr;
    }
}
