import {Injectable} from '@angular/core';
import {EntityService} from '../modules/shared/config-module/services/rest/entity.service';
import {Entity} from '../modules/shared/config-module/entities/entity.interface';
import {Issue} from '../entities/issue/issue.model';
import {ResourceService} from '../modules/shared/config-module/services/rest/resource.service';
import {LocalStorageService} from '../modules/shared/config-module/services/local/local-storage.service';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IssueService {

    issuesNavigation = [];
    issuesNavigationLoaded = false;
    stats = {
        total:  {category: {Anomalie: 0, Évolution: 0, Aide: 0}, total: 0},
        opened: {category: {Anomalie: 0, Évolution: 0, Aide: 1}, total: 0},
        closed: {category: {Anomalie: 0, Évolution: 0, Aide: 0}, total: 0}
    };

    constructor(private entity: EntityService, private resource: ResourceService, private localStorage: LocalStorageService) {
    }

    issuescallback = (items): Entity[] => {
        const issues = [];
        for (const item of items) {
            issues.push(new Issue(item));
        }
        return issues;
    };

    /**
     * Charge la liste de tickets
     * @param filter
     */
    getTicketsListe(filter) {
        const issues$ = this.entity.getEntitiesByFiltresInParams(filter, this.issuescallback, false, 'issues').pipe(share());

        issues$.subscribe(() => {
            this.loadIssuesNavigation();
        });

        return issues$;
    }

    /**
     * Charge la liste d'ids de tickets pour la navigation ticket à ticket
     *  (<)  #XXXX  (>)
     * @param customFilter
     */
    loadIssuesNavigation(customFilter = null) {
        this.issuesNavigationLoaded = true;

        const lsfilter = this.localStorage.getLSItem('filtres');

        customFilter = customFilter ? customFilter : {
            pagination: lsfilter.pagination,
            autocomplete: lsfilter.autocomplete,
            utilisateur: lsfilter.utilisateur,
            category: lsfilter.category,
            statut: lsfilter.statut,
            sort: JSON.stringify(JSON.parse(lsfilter.sort).value),
            datemin: lsfilter.datemin,
            datemax: lsfilter.datemax,
            etat: lsfilter.etat
        };

        // Si on passe en paramètre un customFilter on l'utilise, sinon on utilise le localstorage (cas filtres non sauvegardés)
        const filter = {
            ...customFilter,
            noloading: true
        };

        // Chargement des statistiques sans la pagination
        this.loadStatistiques({...filter, pagination: null});

        this.resource.getWithParams(filter, 'issues', 'navigation').subscribe((res: any) => {
            this.issuesNavigation = [];

            res.liste.forEach((issue) => {
                this.issuesNavigation.push(this.getTicketRef(issue));
            });

            this.issuesNavigation.sort();
        });
    }

    /**
     * Chargement des statistiques
     * @param filter
     */
    loadStatistiques(filter) {
        this.resource.getWithParams(filter, 'issues', 'stats').subscribe((res: any) => {
            this.stats = res.stats;
        });
    }

    closeTicket(issue: Issue) {
        return this.resource.delete('issues', issue.id.toString());
    }

    /**
     * Retourne la reference du ticket
     */
    getTicketRef(issue) {
        return issue.issueRef ? `${issue.issueRef.id}_${issue.issueRefIndex}` : issue.id;
    }
}
