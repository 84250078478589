import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    // tslint:disable-next-line:variable-name
    private _current = '2.1.4.4';

    constructor() {
    }

    get current(): string {
        return this._current;
    }
}
