import * as IssueActions from './issue.action';
import {Issue} from './issue.model';

export function IssueReducer(state: Issue[], action: IssueActions.Actions) {
    switch (action.type) {
        case IssueActions.GET_ISSUES_SUCCESS:
            return action.payload.liste;
        default:
            return state !== undefined ? state : [];
    }
}
