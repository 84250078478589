import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../services/local/session.service';
import {EnvService} from '../../services/rest/env.service';
import {DeploiementService} from '../../services/rest/deploiement.service';
import {interval, Subscription} from 'rxjs';
import * as Countdown from 'countdown';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ModaleAvertissementDeploiementComponent} from '../modale-avertissement-deploiement/modale-avertissement-deploiement.component';
import {ViewmodeService} from '../../services/utility/viewmode.service';

@Component({
    selector: 'cre-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['../../../../../../variables.scss', './account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {

    constructor(public session: SessionService, public envService: EnvService, private dialog: MatDialog,
                private firebaseService: DeploiementService, private router: Router,
                public viewmode: ViewmodeService) {
        this.envService = envService;
    }

    ispremista: boolean;
    afficherTempsrestant: boolean;
    tempsRestant: Countdown;
    timer: Subscription;
    afficherModale: boolean;

    @Input() bcachersupport: boolean;

    static compareDate(date1: Date, date2: Date) {
        return date1.getSeconds() === date2.getSeconds()
            &&
            date1.getMinutes() === date2.getMinutes()
            &&
            date1.getHours() === date2.getHours()
            &&
            date1.toDateString() === date2.toDateString();
    }

    ngOnInit() {
        if (this.envService.getDomain() === 'premista.tech') {
            this.ispremista = true;
        }

        this.firebaseService.initDeploiement();

        this.firebaseService.majNotif.subscribe(opt => {
            if (opt === true && this.firebaseService.notif.from === (this.envService.getUrl())) {
                setTimeout(() => this.afficherTempsrestant = true, 0);
                this.getTempsrestant(this.firebaseService.notif.date);
                this.initTimer();
                if (this.firebaseService.afficherModale) {
                    const ref = this.dialog.open(ModaleAvertissementDeploiementComponent, {
                        data: {date: this.firebaseService.notif.date, texte: this.firebaseService.notif.texte},
                        width: '900px'
                    });
                    ref.afterClosed().subscribe(_ => {
                        this.firebaseService.afficherModale = false;
                    });
                }
            } else {
                this.afficherTempsrestant = false;
            }
        });
    }

    private initTimer() {
        this.timer = interval(1000).subscribe(_ => {
            this.getTempsrestant(this.firebaseService.notif.date);
            if (AccountMenuComponent.compareDate(this.tempsRestant.end, this.tempsRestant.start)) {
                this.timer.unsubscribe();
                this.tempsRestant.hours = 0;
                this.tempsRestant.minutes = 0;
                this.tempsRestant.seconds = 0;
            }
        });
    }

    private getTempsrestant(date1: number) {
        this.tempsRestant = new Countdown(date1);
    }

    public displayCountdown(countdown: Countdown) {
        return 'Mise à jour dans : ' + ((countdown.hours !== 0) ? (('0' + countdown.hours).slice(-2) + ':') : '')
            + ('0' + countdown.minutes).slice(-2) + ':'
            + ('0' + countdown.seconds).slice(-2);
    }

    openSupport() {
        let url = this.envService.getCompleteUrl('support');
        // Enregistrement de l'URL de provenance
        url += '#/u/' + btoa(window.location.href);

        window.open(url, '_blank');
    }
}
