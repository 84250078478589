import {Entity} from '../../entity.interface';
import {Role} from '../role/role.model';
import {Contact} from '../contact/contact.model';
import {Client} from '../../entite/client/client.model';
import {Utilisateuragence} from './utilisateuragence.model';
import {Profil} from '../profil/profil.model';
import {Groupeagences} from '../../entite/groupeagences/groupeagences.model';
import {Agence} from '../../entite/agence/agence.model';
import * as moment from 'moment';
import {Utilisateurapikey} from "../utilisateurapikey/utilisateurapikey.model";


export class Utilisateur implements Entity {
    idutilisateur: number;
    idcontact: Contact; // Contact
    idrole: Role; // Role
    login: string;
    pass: string;
    sel: string;
    email: string;
    bpeutseconnecter: boolean;
    derniereconnexion: Date;
    cleactivation: string;
    cleresetmdp: string;
    datecreation: Date;
    googlesynchronisation: string;
    resterconnect: string;
    expirationclereset: Date;
    badmin: boolean;
    dateexpiration: moment.Moment;
    bdemo: boolean;
    bdemomodele: boolean;
    activite: string;
    datedesactivation: Date;
    // tslint:disable-next-line:variable-name
    password_bankin: string;
    // tslint:disable-next-line:variable-name
    uuid_bankin: string;
    baccepteconnexionsupport: boolean;
    badminmandataire: boolean;
    bproprietaire: boolean;
    idclient: Client;
    bshownouveautes: boolean;
    nblicencemax: string;
    // idcreateur: Utilisateur; // Utilisateur
    binterne: boolean;
    bshowespaceapporteur: boolean;
    bdonnees: boolean;
    fichierrgpd: string;
    bcomptefictif: boolean;
    baccesparametres: boolean;
    beditionapporteurs: boolean;
    bapporteursparentreprise: boolean;
    bpremiereconnexion: boolean;
    busenoreplyemail: boolean;
    smtpemail: string;
    smtppassword: string;
    smtpserver: string;
    smtpport: string;
    idprofil: Profil;
    utilisateuragences: Utilisateuragence[];

    nbdossiers: number;
    idgroupeagences: Groupeagences;
    nbmandataires: number;
    agence: Agence;
    responsable: Utilisateur;
    recouvrement: number;
    raisonsocialemandataire: string;
    adressemandataire: string;
    codepostalmandataire: string;
    villemandataire: string;
    siretmandataire: string;
    sirenmandataire: string;
    villercsmandataire: string;
    oriasmandataire: string;
    remunerationmandataire: number;
    butilisefacturation: boolean;
    prefixefacturemandataire: string;
    dateentree: moment.Moment;
    datesortie: moment.Moment;

    envoyeremail: boolean;
    utilisateurapikey: Utilisateurapikey[];

    parsedObjectifs: any;

    constructor({idutilisateur = null, idcontact = null, idrole = null, login = null, pass = null, sel = null, email = null, bpeutseconnecter = null,
                derniereconnexion = null, cleactivation = null, cleresetmdp = null, datecreation = null, googlesynchronisation = null,
                resterconnect = null, expirationclereset = null, badmin = null, dateexpiration = null, bdemo = null, bdemomodele = null,
                activite = null, datedesactivation = null, password_bankin = null, uuid_bankin = null, baccepteconnexionsupport = null,
                badminmandataire = null, bproprietaire = null, idclient = null, bshownouveautes = null, nblicencemax = null, idcreateur = null,
                binterne = null, bshowespaceapporteur = null, bdonnees = null, fichierrgpd = null, bcomptefictif = null, baccesparametres = null,
                beditionapporteurs = null, bapporteursparentreprise = null, bpremiereconnexion = null, busenoreplyemail = null, smtpemail = null,
                smtppassword = null, smtpserver = null, smtpport = null, idprofil = null, utilisateuragences = [], nbdossiers = null, idgroupeagences = null,
                nbmandataires = null, agence = null, responsable = null, recouvrement = null, raisonsocialemandataire = null, adressemandataire = null,
                codepostalmandataire = null, villemandataire = null, siretmandataire = null, sirenmandataire = null, villercsmandataire = null, oriasmandataire = null,
                remunerationmandataire = null, butilisefacturation = null, dateentree = null, datesortie = null, envoyeremail = null, utilisateurapikey = null,
                prefixefacturemandataire = null
                } = {}) {
        this.idutilisateur = idutilisateur;
        this.idcontact = (idcontact === null) ? new Contact() : new Contact(idcontact);
        this.idclient = (idclient === null) ? new Client() : new Client(idclient);
        this.idrole = (idrole === null) ? new Role() : new Role(idrole);
        this.login = login;
        this.pass = pass;
        this.sel = sel;
        this.email = email;
        this.bpeutseconnecter = !!bpeutseconnecter;
        this.derniereconnexion = derniereconnexion;
        this.cleactivation = cleactivation;
        this.cleresetmdp = cleresetmdp;
        this.datecreation = datecreation;
        this.googlesynchronisation = googlesynchronisation;
        this.resterconnect = resterconnect;
        this.expirationclereset = expirationclereset;
        this.badmin = badmin;
        this.dateexpiration = dateexpiration;
        this.bdemo = bdemo;
        this.bdemomodele = bdemomodele;
        this.activite = activite;
        this.datedesactivation = datedesactivation;
        this.password_bankin = password_bankin;
        this.uuid_bankin = uuid_bankin;
        this.baccepteconnexionsupport = baccepteconnexionsupport;
        this.badminmandataire = badminmandataire;
        this.bproprietaire = bproprietaire;
        this.bshownouveautes = bshownouveautes;
        this.nblicencemax = nblicencemax;
        // this.idcreateur = new Utilisateur(idcreateur);
        this.binterne = binterne;
        this.bshowespaceapporteur = bshowespaceapporteur;
        this.bdonnees = bdonnees;
        this.fichierrgpd = fichierrgpd;
        this.bcomptefictif = bcomptefictif;
        this.baccesparametres = baccesparametres;
        this.beditionapporteurs = beditionapporteurs;
        this.bapporteursparentreprise = bapporteursparentreprise;
        this.bpremiereconnexion = bpremiereconnexion;
        this.busenoreplyemail = busenoreplyemail;
        this.smtpemail = smtpemail;
        this.smtppassword = smtppassword;
        this.smtpserver = smtpserver;
        this.smtpport = smtpport;
        this.idprofil = (idprofil === null) ? new Profil() : new Profil(idprofil);
        this.utilisateuragences = [];
        for (const utilisateuragence of utilisateuragences) {
            this.utilisateuragences.push(new Utilisateuragence(utilisateuragence));
        }
        this.nbdossiers = nbdossiers;
        this.idgroupeagences = (idgroupeagences === null) ? new Groupeagences() : new Groupeagences(idgroupeagences);
        this.nbmandataires = nbmandataires;
        this.agence = (agence === null) ? new Agence() : new Agence(agence);
        this.responsable = (responsable === null) ?  null : new Utilisateur(responsable);
        this.recouvrement = recouvrement;
        this.raisonsocialemandataire = raisonsocialemandataire;
        this.adressemandataire = adressemandataire;
        this.codepostalmandataire = codepostalmandataire;
        this.villemandataire = villemandataire;
        this.siretmandataire = siretmandataire;
        this.sirenmandataire = sirenmandataire;
        this.villercsmandataire = villercsmandataire;
        this.oriasmandataire = oriasmandataire;
        this.remunerationmandataire = remunerationmandataire;
        this.butilisefacturation = butilisefacturation;
        this.prefixefacturemandataire = prefixefacturemandataire;
        this.dateentree = dateentree !== null ? moment(new Date(dateentree)) : null;
        this.datesortie = datesortie !== null ? moment(new Date(datesortie)) : null;
        this.envoyeremail = envoyeremail;
        this.utilisateurapikey = (utilisateurapikey) ? utilisateurapikey : new Utilisateurapikey();
    }
}
